import i18n from './components/Translation';
import DocUtility from './components/DocUtility';
import 'jquery-datetimepicker';
import 'jquery.tooltips';
import Dropbox from 'dropbox';
import Dropzone from 'dropzone';
import Awesomplete from 'awesomplete';
import SmartFolder from './components/SmartFolder.js';
import getExpiringContacts from './components/ContactManager.js';
import FileDropper from './components/FileDropper.js';
import swal from 'sweetalert2';

export function init() {
  loader.add(ui)
        .add(initGlobals)
        .add(loadExtraLibraries, ['font-size', 'font-family', 'clean-paste', 'watermarkOpt', 'cgchosen'])
        .add(buildCustomElements)
        .add(initDropzone)
        .add(initListeners)
        .add(informPreloadedData)
        .add(getMetaData, ['emailList','signature','distroLists','documents',
          'folders','templates','departments', 'reply-to', 'user-settings'])
        .add(addPreloadedData, 'preloaded-data')
        .add(focusSubjectLine)
        .execute();
}

const STATUS = { DRAFT: 0, READY: 1, SENT: 2 }
Object.freeze(STATUS);

const PRELOADED_FOLDER_DEPTH = {
  DROPBOX: 1,
  GOOGLE_DRIVE: 1,
  CROOGLOO: 1,
  BOX: 1
}
Object.freeze(PRELOADED_FOLDER_DEPTH);

const uuid = require('uuid/v4');

function ui() {
  $('#main').foundation();

  Utility.loadStylesheet('/assets/css/jquery.tooltips.css');
  $('#scheduleSendBtn').tooltip();

  $('.checklist input[type="checkbox"]').on('change', function() {
    $(this).closest('li').toggleClass('box-checked', this.checked)
  });
}

var msgId;
var savedMessage;

function focusSubjectLine() {
  document.getElementById('subject').focus(); 
}


function initListeners() {
  $(document).on('click', '.swal-close-btn', swal.clickCancel);
  document.getElementById('saveSignatureBtn').onclick = saveSignature;
  document.getElementById('saveTemplateBtn').onclick = showTemplateModal;
  document.getElementById('menu_saveAsTemplate').onclick = showTemplateModal;
  document.getElementById('menu_saveTemplate').onclick = saveLoadedTemplate;
  document.getElementById('menu_deleteTemplate').onclick = showDeleteTemplateModal;

  document.getElementById('menu_firstNameVar').onclick = () => insertEmailVar('firstName');
  document.getElementById('menu_lastNameVar').onclick = () => insertEmailVar('lastName');

  document.getElementById('attachFilesBtn').onclick = function() {
    $('#myDropZone2').trigger('click');
  }

  new FileDropper('mainComposeContainer', i18n.t("files.drp-attch"), (files, event) =>
    files.forEach(file => {
      file.upload = { chunked: false }
      myDropZone2.emit('addedfile', file);
    })
  );

  document.getElementById('findFilesBtn').onclick = toggleFileViewer;
  document.querySelector('#jstreeCloseBtn').onclick = toggleFileViewer;
  document.getElementById('js-tree-select').onclick = selectDocument;

  document.getElementById('clearAllBtn').onclick = askToClear;
  document.getElementById('saveDraftBtn').onclick = e => saveDraft(true);
  document.getElementById('testMsgBtn').onclick = sendTestMessage;
  document.getElementById('sendMsgBtn').onclick = () => {
    sendMessage(STATUS.READY, false, undefined);
  };
  document.getElementById('scheduleSendBtn').onclick = () => {
    let timeRegex = /((1[0-2]|0?[0-9]):([0-5][0-9]) ([AaPp][Mm]))/;
    let monthRegex = /^\d{4}\/\d{2}\/\d{2}$/;
    let scheduledDateTime = undefined;
    $(document).on('click', '.swal-confirm-btn', () => swal.clickConfirm());
    swal({     
      title: i18n.t("js.compose.pick"),
      customContainerClass: 'swal-container-scheduleSend',
      html: 
      '<div class="grid-x grid-margin-x schedule-date-time-input">'
        +'<div class="small-8 cell"><input type="text" id="sentDatePicker" /></div>'
        +'<div class="small-4 cell">'
          +'<input type="text" id="sentDateMonth" class="schedule-date-time-input" placeholder="yyyy/mm/dd"/>'
          +'<input type="text" id="sentDateTime" class="schedule-date-time-input" placeholder="hh:mm (AM|PM)"/>'
          +`<button id="scheduleSendSwalSubmitBtn" role="button" type="button" class="button green swal-confirm-btn">${i18n.t("schedule.send")}</button>`
        +'</div>'
        //+'<span class="swal-close-btn"><i class="fa fa-close"></i></span>'
      +'</div>',
      allowOutsideClick: true,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      useRejections: true,
      expectRejections: true,
      //footer: 'Select the date and time and hit Schedule Send!',
      onOpen: function() {
        let submitBtn = document.getElementById("scheduleSendSwalSubmitBtn");
        Utility.loadStylesheet('/assets/css/jquery.datetimepicker.css');
        $('#sentDatePicker').datetimepicker({
          format:'Y/m/d',
          minDate: 0,
          timepicker: false,
          todayButton: false,
          defaultDate: false,
          inline: true,
          onGenerate: function(ct, $input) {
            $('.xdsoft_today_button').css('display', 'none');
          },
          onSelectDate:function(dp, $input){
            console.log(dp, $input.val());
            $('#sentDateMonth').val($input.val());
            $('#sentDateMonth').css('border-color', '');
            let valid = timeRegex.test($('#sentDateTime').val());
            if (valid) {
              setScheduledDateTime();
            } else {
              $('#sentDateTime').css('border-color', 'red');
            }
          }
        });
        $('#sentDateMonth').on("change", () => {
          let inputValue = $('#sentDateMonth').val();
          let valid = monthRegex.test(inputValue);
          if (valid) {
            $('#sentDateMonth').css('border-color', '');
            $('#sentDatePicker').val(inputValue);
            $('#sentDatePicker').datetimepicker({value: inputValue});
            if (timeRegex.test($('#sentDateTime').val())) {
              setScheduledDateTime();
            }
          } else {
            $('#sentDateMonth').css('border-color', 'red');
            swal.disableConfirmButton();
            submitBtn.setAttribute("disabled", true);
          }
        });
        $('#sentDateTime').on("change", () => {
          let valid = timeRegex.test($('#sentDateTime').val());
          if (valid) {
            $('#sentDateTime').css('border-color', '');
            if (monthRegex.test($('#sentDateMonth').val())) {
              setScheduledDateTime();
            }
          } else {
            $('#sentDateTime').css('border-color', 'red');
            swal.disableConfirmButton();
            submitBtn.setAttribute("disabled", true);
          }
        });

        setDate();

        function setScheduledDateTime() {
          let sentDateMonth = $('#sentDateMonth').val();
          let sentDateTime = $('#sentDateTime').val();
          scheduledDateTime = `${sentDateMonth} ${sentDateTime}`;
          validateFullDate();
        }

        function validateFullDate(){
          let invalid = true;
          let now = new Date();
          now.setSeconds(0, 0);
          now.setTime(now.getTime() - (5*60000));
          
          try{
            invalid = now.getTime() > Date.parse(scheduledDateTime);
          }catch(e){}

          if(invalid){
            cgToast(i18n.t("js.compose.date-time.rejection"));
            swal.disableConfirmButton();
            submitBtn.setAttribute("disabled", true);
            $('#sentDateTime').css('border-color', 'red');
            $('#sentDateMonth').css('border-color', 'red');
          }else{
            swal.enableConfirmButton();
            submitBtn.removeAttribute("disabled");
            $('#sentDateTime').css('border-color', '');
            $('#sentDateMonth').css('border-color', '');
          }
        }

        function setDate (date = new Date()) {
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          let dd = date.getDate();
          let hours = date.getHours();
          let minutes = date.getMinutes();
          let ampm = hours > 12? 'pm': 'am';
          hours = hours > 12? hours - 12: hours;
          hours = hours < 10? "0" + hours: hours;
          minutes = minutes < 10? "0" + minutes : minutes;
          month = month < 10? "0" + month : month;
          dd = dd < 10? "0" + dd : dd;
          let sentDateMonth = `${year}/${month}/${dd}`;
          let sentDateTime = `${hours}:${minutes} ${ampm}`;
          $('#sentDateMonth').val(sentDateMonth);
          $('#sentDateTime').val(sentDateTime);
          setScheduledDateTime();
        };
      }
    }).then(function() {
      console.log(scheduledDateTime);
      sendMessage(STATUS.READY, false, scheduledDateTime);
    }).catch(swal.noop);
  };

  document.getElementById('listOrderOpt').onchange = toggleListView;
  document.getElementById('deptOrderOpt').onchange = toggleListView;

  $('#recipientsInput').css('border-color', '#00b96e');
  document.getElementById('recipientsInput').onfocus = function() {
    showRecipientsPanel();
    $(this).blur();
  }
  document.getElementById('recipientsInput').onkeydown = e => e.preventDefault();
  document.getElementById('recipientsInput').onmousedown = function(e) {
    e.preventDefault();
    $(this).trigger('focus');
  }

  document.getElementById('editorSection').onclick = e => {
    try {
      if(e.target.closest('#templateOptionsBtn') === null) {
        $('#distro-template-dropdown').foundation('close');
      }
    } catch(e1) {
      console.error(e1);
    }
    if(typeof e.target.id !== 'string' || e.target.id !== 'recipientsInput') {
      updateRecipientsInput();
      hideRecipientsPanel();
    }
  }

  $('#main').off('keydown');
  $('#main').on('keydown', e => {
    // accepts: [tab] and [,] convert to [enter]
    if([9,188].includes(e.keyCode) && e.target && e.target.tagName == 'INPUT' && $(e.target).hasClass('chosen-search-input')) {
      try {
        if(e.keyCode == 9 && e.target.closest('#distributionTemplates_chosen') != null) {
          console.debug('tab key will act normally for template selection input');
          return;
        }
      } catch(err1) {
        console.error(err1);
      }
      e.preventDefault();
      let e2 = jQuery.Event('keyup');
      e2.keyCode = 13
      $(e.target).trigger(e2);
    }
  });

  $('#extraEmailContainer').find('input.chosen-search-input').keyup(e => {
    // [enter] key
    if(e.keyCode === 13 &&
      $('#extraEmailContainer').find('li.active-result').length === 0) {
        let inputValue = $('#extraEmailContainer')
          .find('input.chosen-search-input').val();
        addExtraEmail(inputValue, false, true);
    }
  });

  setLinkOptionsListener();

  /*let latestListAndDeptInputTime;
  document.getElementById('listAndDeptLISearch').oninput = function(e) {
    try {
      let onInputTime = new Date().getTime();
      latestListAndDeptInputTime = onInputTime;
      setTimeout(() => {
        if(latestListAndDeptInputTime === onInputTime) {
          for(let selectorAndProp of [
              ['li.distr-group-list-item:not(.distr-group-member)', 'distributionListName'],
              ['li.dept-list-item:not(.distr-group-member)', 'departmentName']]) {
            for(let item of document.querySelectorAll(selectorAndProp[0])) {
              try {
                if(this.value.trim() == ''
                    || typeof item.getAttribute(selectorAndProp[1]) == 'string'
                      && item.getAttribute(selectorAndProp[1]).toLowerCase()
                        .includes(this.value.toLowerCase())) {
                  item.classList.remove('hidden-list-item');
                  applyFunctionToItemChildren(item, child => child.classList.remove('hidden-list-item'))
                } else {
                  item.classList.add('hidden-list-item');
                  applyFunctionToItemChildren(item, child => child.classList.add('hidden-list-item'))
                }
              } catch(err1) {
                console.error(err1);
              }
            }
          }
        }
      }, 500);
    } catch(err2) {
      console.error(err2);
    }
    function applyFunctionToItemChildren(parent, fct) {
      for(let child of document.querySelectorAll(`li[parentListId="${parent.id}"]`)) {
        fct(child);
      }
    }
  };*/

  r.onunload = function() {
    try {
      console.log('unloading compose');
      $('.croogloo-toast.body-attached').remove();
      saveDraft(false);
    } catch(e) {
      console.error(e);
    }
    return Promise.resolve();
  }

  window.onbeforeunload = function() {
    if(!areAllFieldsEmpty() && window.isLoggingOut !== true) {
      return i18n.t("js.compose.discard");
    }
  }

  setUpAutoSave();
  getExpiringContacts();
}

function setLinkOptionsListener() {
  document.getElementById('sendAsLinkLabel').onclick = function(e) {
    if(e.target.tagName == 'I') {
      e.preventDefault();
      swal({
        title: i18n.t("links.title"),
        html: generateLinkOptions(),
        allowOutsideClick: true,
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        confirmButtonText: i18n.t("button.confirm"),
        confirmButtonColor: '#13C46A',
        useRejections: true, //important for swal2 v7.1.2
        expectRejections: true,
        footer: i18n.t("links.footer"),
        onOpen: function() {
          try {
            document.querySelector(`input.link-access[link-data="${linkOptions.linkAccessType}"]`).checked = true;
            if(linkOptions.isdeeplink){
              document.getElementById('scriptation-chk').setAttribute('checked', true);
              document.querySelectorAll('input[type="radio"]').forEach(item=>item.disabled=true);
            }
            let isCustomTime = true;
            [].forEach.call(document.querySelectorAll('input.link-duration'),
              function(durationInput) {
                if(durationInput.getAttribute('link-data') == (linkOptions.linkValidityTime+'')) {
                  durationInput.checked = true;
                  isCustomTime = false;
                }
            });
            if(isCustomTime) {
              let nbOfDays = parseInt(linkOptions.linkValidityTime / DAY_MS);
              let nbOfHours = parseInt((linkOptions.linkValidityTime % DAY_MS) / HOUR_MS);
              document.getElementById('link_duration_days').value = nbOfDays + '';
              document.getElementById('link_duration_hours').value = nbOfHours + '';
              document.getElementById('link_expires_custom').checked = true;
              let $customDurationOpt = $('#link_expires_custom');
              $customDurationOpt.addClass('custom-expanded');
              $('#linkCustomDuration').slideToggle(0);
            } else {
              document.getElementById('link_duration_days').value = '1';
              document.getElementById('link_duration_hours').value = '0';
            }
          } catch(e) {
            console.error(e);
            document.getElementById('link_read-only').checked = true;
            document.getElementById('link_expires_never').checked = true;
          }
          $('input[name=link-duration]').off('change');
          $('input[name=link-duration]').on('change', function() {
            let $customDurationOpt = $('#link_expires_custom');
            if($customDurationOpt.is(':checked') &&
                !$customDurationOpt.hasClass('custom-expanded')) {
              $customDurationOpt.addClass('custom-expanded');
              $('#linkCustomDuration').slideToggle(400);
            } else if(!$customDurationOpt.is(':checked') &&
                $customDurationOpt.hasClass('custom-expanded')) {
              $customDurationOpt.removeClass('custom-expanded');
              $('#linkCustomDuration').slideToggle(400);
            }
          });
        },
        preConfirm: function() {
          return new Promise((resolve, reject) => {
            if(document.getElementById('link_expires_custom').checked) {
              let link_duration_days = document.getElementById('link_duration_days');
              let link_duration_hours = document.getElementById('link_duration_hours');
              if(hasInvalidNumber(link_duration_days)) {
                reject(i18n.t("links.reject.inv-number", {unit: "$t(utils.days)"}));
                return;
              }
              if(hasInvalidNumber(link_duration_hours)) {
                reject(i18n.t("links.reject.inv-number", {unit: "$t(utils.hours)"}));
                return;
              }
              let daysDuration = parseInt(link_duration_days.value);
              let hoursDuration = parseInt(link_duration_hours.value);
              if(daysDuration + hoursDuration === 0) {
                reject(i18n.t("links.reject.min"));
                return;
              }
              linkOptions.linkValidityTime = daysDuration * DAY_MS + hoursDuration * HOUR_MS;
              function hasInvalidNumber(numberInput) {
                numberInput.value = numberInput.value.replace(/\s/g, '');
                if(numberInput.value.match(/^\d+$/) == null
                    || isNaN(parseInt(numberInput.value))
                    || parseInt(numberInput.value) < 0
                    || parseInt(numberInput.value) > 1000) {
                  return true;
                } return false;
              }
            } else {
              linkOptions.linkValidityTime = parseInt(document
                .querySelector('input[name="link-duration"]:checked')
                .getAttribute('link-data'));
            }
            linkOptions.linkAccessType = parseInt(document
              .querySelector('input[name="link-access"]:checked')
              .getAttribute('link-data'));

            linkOptions.isdeeplink = document.getElementById('scriptation-chk').checked;

            console.debug(linkOptions);
            resolve();
          });
        }
      }).catch(swal.noop);
    }
  };
  function generateLinkOptions() {
    let container = document.createElement('div');
    container.style.textAlign = 'left';
    container.style.paddingLeft = '1rem';

    let deeplinkcontainer = document.createElement('div');
    deeplinkcontainer.style.textAlign = 'center';
    container.appendChild(deeplinkcontainer);
    let checkbox = addCheckboxOption('scriptation', 'scriptation-chk', i18n.t("js.compose.links.scriptation"), deeplinkcontainer);
    checkbox.addEventListener('change', () => {
      if(checkbox.checked){
        document.getElementById('link_download-print').click();
        document.getElementById('link_expires_never').click();
        container.querySelectorAll('input[type="radio"]').forEach(input=>input.setAttribute('disabled', true));
      }else{
        container.querySelectorAll('input[type="radio"]').forEach(input=>input.removeAttribute('disabled'))
      }
    });

    let accessOptTitle = document.createElement('div');
    accessOptTitle.style.lineHeight = '2rem';
    accessOptTitle.style.fontWeight = '400';
    accessOptTitle.textContent = i18n.t("links.hldrs.title");
    container.appendChild(accessOptTitle);
    addRadioOption('link-access', 'link_read-only', i18n.t("links.hldrs.vw"), container, LinkAccess.READ_ONLY);
    addRadioOption('link-access', 'link_download', i18n.t("links.hldrs.vw-dwld"), container, LinkAccess.DOWNLOAD);
    addRadioOption('link-access', 'link_download-print', i18n.t("links.hldrs.vw-dwld-prnt"), container, LinkAccess.DOWNLOAD_PRINT);

    let linkDurationTitle = document.createElement('div');
    linkDurationTitle.style.lineHeight = '2rem';
    linkDurationTitle.style.fontWeight = '400';
    linkDurationTitle.textContent = i18n.t("links.exp.title");
    container.appendChild(linkDurationTitle);
    addRadioOption('link-duration', 'link_expires_never', i18n.t("links.exp.never"), container, LinkOptions.NEVER_EXPIRING);
    addRadioOption('link-duration', 'link_expires_24h', i18n.t("links.exp.day"), container, DAY_MS);
    addRadioOption('link-duration', 'link_expires_custom', i18n.t("links.exp.custom"), container, 'custom');

    let customDuration = document.createElement('div');
    customDuration.id = 'linkCustomDuration';
    customDuration.style.display = 'none';

    addNumberInput('link_duration_days', i18n.t("js.modal.expire.ddays"), customDuration);
    addNumberInput('link_duration_hours', i18n.t("js.modal.expire.hhours"), customDuration);

    container.appendChild(customDuration);

    return container;
  }
  function addCheckboxOption(name, id, title, container){
    let ro = document.createElement('input');
    ro.type = 'checkbox';
    ro.id = id;
    ro.name = name;
    ro.className = name;
    let roLabel = document.createElement('label');
    roLabel.textContent = title;
    roLabel.setAttribute('for', id);
    container.appendChild(ro);
    container.appendChild(roLabel);
    return ro;
  }
  function addRadioOption(name, id, title, container, linkData) {
    let ro = document.createElement('input');
    ro.type = 'radio';
    ro.id = id;
    ro.name = name;
    ro.className = name;
    ro.setAttribute('link-data', linkData);
    let roLabel = document.createElement('label');
    roLabel.textContent = title;
    roLabel.setAttribute('for', id);
    container.appendChild(ro);
    container.appendChild(roLabel);
  }
  function addNumberInput(id, labelTextContent, container) {
    let nbInput = document.createElement('input');
    nbInput.id = id;
    nbInput.type = 'number';
    nbInput.setAttribute('min', 0);
    nbInput.style.display = 'inline-block';
    nbInput.style.width = '20%';
    container.appendChild(nbInput);
    let nbLabel = document.createElement('label');
    nbLabel.textContent = labelTextContent;
    nbLabel.style.marginLeft = '0.5rem';
    nbLabel.style.marginRight = '0.5rem';
    container.appendChild(nbLabel);
  }
}

function setUpAutoSave() {
  let pageName = croogloocurrentpage.pageName;
  let autoSave = setInterval(function() {
    if(croogloocurrentpage.pageName !== pageName) {
      clearInterval(autoSave);
      return;
    }
    if(new Date().getTime() - lastSaveTime > DRAFT_SAVING_INTERVAL*2/3) {
      saveDraft(false);
    }
  }, DRAFT_SAVING_INTERVAL);
}

function showRecipientsPanel() {
  let $panel = $('#distrListPanel');
  if($panel.hasClass('hidden-recipients-pane')) {
    $panel.css('width', '0%');
    $panel.removeClass('hidden-recipients-pane');
    $panel.addClass('visible-recipients-pane');
    setTimeout(function() {
      $panel.css('overflow-y', 'auto');
    }, 1050); // timeout important to prevent width bug
  }
  $('#recipientsInput').css('border-color', '#00b96e');
  let $editor = $('#editorSection');
  if($editor.hasClass('full-msg-width')) {
    $panel.css('width', '100%');
    $editor.removeClass('full-msg-width');
    $editor.addClass('partial-msg-width');
  }
}

function hideRecipientsPanel() {
  let $panel = $('#distrListPanel');
  if(!$panel.hasClass('hidden-recipients-pane')) {
    $panel.css('width', '30%');
    $panel.removeClass('visible-recipients-pane');
    $panel.addClass('hidden-recipients-pane');
    setTimeout(function() {
      $panel.css('overflow-y', 'hidden');
    }, 1050); // timeout important to prevent width bug
  }
  $('#recipientsInput').css('border-color', '');
  let $editor = $('#editorSection');
  if(!$editor.hasClass('full-msg-width')) {
    $editor.css('width', '70%');
    $editor.removeClass('partial-msg-width');
    $editor.addClass('full-msg-width');
  }
}

function updateRecipientsInput() {
  let extraEmailsSet = getSelectedExtraEmails().sort();
  extraEmailsSet = extraEmailsSet.filter((email, idx) => {
    return extraEmailsSet.indexOf(email) == idx;
  });
  $('#recipientsInput').val(formatSelectedGroups('li.distr-group-list-item').sort()
    .concat(formatSelectedGroups('li.dept-list-item').sort())
    .concat(extraEmailsSet).join(', '));
}

function toggleListView() {
  $('#distributionGroupList').css('display', this.id == 'deptOrderOpt' ?'none' :'block');
  $('#departmentList').css('display', this.id == 'deptOrderOpt' ?'block' :'none');
}

function addExtraEmail(email, skipValidation = false, cacheEmail = false, primaryEmail) {
  email = email.trim();
  if(!email) { return; }

  if(!Utility.isValidEmailAddress(email)) {
    if(!skipValidation) {
      cgToast(i18n.t("mail.invalid.email-addr", {email}));
    } else {
      console.error('Invalid email address: "'+email+'"');
    }
    return;
  }

  let isExistingEmail = false;
  if(!primaryEmail) {
    primaryEmail = email;
  }
  $('#extraEmailSelect').find('option[primaryEmail="'+primaryEmail+'"]').each(function() {
    if(this.value === email) {
      this.selected = true;
      isExistingEmail = true;
    }
  });

  if(!isExistingEmail) {
    let select = document.getElementById('extraEmailSelect');
    let option = document.createElement('option');
    option.value = email;
    option.textContent = email;
    option.setAttribute('primaryEmail', email);
    option.selected = true;
    select.appendChild(option);
    if(cacheEmail) {
      apicall('distributionapi','cacheExtraEmail', {email: email}).then(resp => {
        if(!resp || !resp.responseCode || resp.responseCode !== '0' ) {
          console.error({
            message: 'Extra email could not be cached',
            resp: resp
          });
        } else {
          console.log('extra email ' + email + ' was successfully cached');
        }
      });
    }
  }
  $('#extraEmailSelect').trigger('chosen:updated');
}

function loadExtraLibraries() {
  require('jstree');
  Utility.loadStylesheet('/assets/jstree/style.min.css');
  Utility.loadStylesheet('/assets/jstree/js-tree-custom.css');
  Utility.loadStylesheet('/assets/croogloo-custom/css/mailbox-menu.css');
  Utility.loadStylesheet('/assets/croogloo-custom/css/watermarkingOptions.css');
  Utility.loadStylesheet('/assets/material-design-icons/css/material-design-icons.min.css');
  Utility.loadStylesheet('https://cdnjs.cloudflare.com/ajax/libs/awesomplete/1.1.5/awesomplete.min.css');
  Utility.loadScript('/assets/trumbowyg/fontsize.js', () => loader.check('font-size'));
  Utility.loadScript('/assets/trumbowyg/fontfamily.js', () => loader.check('font-family'));
  Utility.loadScript('/assets/trumbowyg/cleanpaste.js', () => loader.check('clean-paste'));
  Utility.loadScript('/assets/js/cgchosen.jquery.js', () => loader.check('cgchosen'));
  require('./ui/lib/trumbowyg/plugins/base64/trumbowyg.base64.js'); // base64.js has been customized; do not use base64.min.js
  Utility.loadScript('/assets/croogloo-custom/js/watermarkingOptions.js', () => loader.check('watermarkOpt'));
}

function buildCustomElements() {
  /* WYSIWYG editor email & signature */
  let cg_custom_btns = [
    //['viewHTML'],
    ['undo', 'redo'], // Only supported in Blink browsers
    ['formatting'],
    ['strong', 'em', 'underline'],
    ['foreColor', 'backColor'],
    ['fontsize', 'fontfamily'],
    ['link'],
    ['base64'],
    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
    ['unorderedList', 'orderedList'],
    ['horizontalRule'],
    ['removeformat'],
    ['fullscreen']
  ];

  $.trumbowyg.svgPath = '/assets/css/trumbowyg/icons.svg';

  let defaultColorList = ['ffffff', '000000', 'eeece1', '1f497d', '4f81bd',
    'ff0000', '9bbb59', '8064a2', '4bacc6', 'f79646', 'ffff00', 'f2f2f2', '7f7f7f',
    'ddd9c3', 'c6d9f0', 'dbe5f1', 'f2dcdb', 'ebf1dd', 'e5e0ec', 'dbeef3', 'fdeada',
    'fff2ca', 'd8d8d8', '595959', 'c4bd97', '8db3e2', 'b8cce4', 'e5b9b7', 'd7e3bc',
    'ccc1d9', 'b7dde8', 'fbd5b5', 'ffe694', 'bfbfbf', '3f3f3f', '938953', '548dd4',
    '95b3d7', 'd99694', 'c3d69b', 'b2a2c7', 'b7dde8', 'fac08f', 'f2c314', 'a5a5a5',
    '262626', '494429', '17365d', '366092', '953734', '76923c', '5f497a', '92cddc',
    'e36c09', 'c09100', '7f7f7f', '0c0c0c', '1d1b10', '0f243e', '244061', '632423',
    '4f6128', '3f3151', '31859b', '974806', '7f6000'];

    defaultColorList.splice(5, 1, 'ff0000'); // overwritting first red
    defaultColorList.splice(9, 1, 'ff9900'); // overwritting first orange
    defaultColorList.splice(10, 1, 'ffff00'); // overwritting first yellow
    defaultColorList.splice(6, 1, '00ff00'); // overwritting first green
    defaultColorList.splice(3, 1, '0000ff'); // overwritting first blue
    defaultColorList.splice(7, 1, '9900ff'); // overwritting first purple
    defaultColorList.splice(38, 1, 'ff00ff'); // overwritting first pink

  $('.wysiwyg-email').trumbowyg({
    btns: cg_custom_btns,
    semantic: false,
    plugins: {
      colors: {
        colorList: defaultColorList
      }
    }
  })
  .on('tbwblur', () => {
    console.debug('saving trumbowyg range')
    $('#message').trumbowyg('saveRange');
  });

  document.execCommand("defaultParagraphSeparator", false, "div");

  $('.wysiwyg-signature').trumbowyg({
    btns: cg_custom_btns,
    plugins: {
      colors: {
        colorList: defaultColorList
      }
    }
  });

  $('button.trumbowyg-fontsize-button').prop('title', 'Font Size');
  $('button.trumbowyg-fontfamily-button').prop('title', 'Font Family');

  /* Reveal signature */
  $('.reveal-signature').click(function() {
      $('.email-signature').slideToggle();
      $(this).toggleClass('toggle-open');
  });

  //Initializing Croogloo chosen.js plug in
  $('#extraEmailSelect').chosen({
    no_results_text: i18n.t("js.compose.unrecognized"),
    width: '100%',    
    // max_shown_results: 15
  });

  $('#distributionTemplates').chosen({ width: '100%' }).change((evt, params) => {
    onTemplateSelectUpdated(params);
  });

  $('#distributionGroupNotify').chosen();
}

/**
 * Inserts a variable that will be replaced by Croogloo contact 
 * data in personalized emails. Accepted variables are "firstName" 
 * and "lastName".
 * 
 * IMPORTANT: be very careful when modifying this function.
 * After every change, you should always test inserting variables on an empty line, at the beginning of a line, 
 * in the middle of a line (between two words), and at the end of a line. There can also be different behaviors 
 * between the first line and other ones, so you should try it on different lines. Finally, you should test 
 * adding multiple variables consecutively on a line to see if they are inserted properly without adding new line 
 * breaks.
 * 
 * @param {String} varName Name of the variable to insert.
 */
function insertEmailVar(varName) {
  // Hide the var meny
  $('#email-variable-dropdown').foundation('close');
  const uniqueID = Utility.uniqueID();

  // Prepare the variable as a non-editable span.
  // IMPORTANT: the span tag and personalized-email-class must not be altered 
  // as they will be used in the back-end to parse variables.
  // &nbsp; are also important and thorough testing should be done if you wish to remove them
  const htmlToInsert = `&nbsp;<span id="${uniqueID}" contenteditable="false" spellcheck="false" class="personalized-email-content no-text-select">{${varName}}</span>&nbsp;`;
  
  // Message content before the variable was inserted
  const msgContentBeforeInsert = getContent('message');

  // Restore range saved on blur
  $('#message').trumbowyg('restoreRange');

  // Insert the span at the lastest caret position
  $('#message').trumbowyg('execCmd', {
    cmd: 'insertHTML',
    param: htmlToInsert,
    forceCss: true, // Must be kept to avoid duplicated inserts
  });

  // Message content after the variable was inserted
  let msgContentAfterInsert = getContent('message');

  // Patterns to find the newly integrated variable span.
  const insertedVarRegex = new RegExp(`(</div>)(\&nbsp;)*(<span[^>]*id="${uniqueID}"[^>]*>[^<]*</span>)(\&nbsp;)*`);
  const insertedVarLineStartRegex = new RegExp(`(<br>)*(\&nbsp;)(<span[^>]*id="${uniqueID}"[^>]*>[^<]*</span>)(<div>\&nbsp;)`);

  // console.debug('before: ', msgContentBeforeInsert)
  // console.debug('after: ', msgContentAfterInsert)
  // console.debug('tests: ', insertedVarRegex.test(msgContentAfterInsert), insertedVarLineStartRegex.test(msgContentAfterInsert))

  // Fix trailing BR, if any, to avoid automatic line break when inserting firstName and lastName consecutively (on second line or later)
  try {
    const trailingBrRegex = new RegExp(`(<span[^>]*id="${uniqueID}"[^>]*>[^<]*</span>\&nbsp;)<br>(</div>)`);
    if (trailingBrRegex.test(msgContentAfterInsert)) {
      // console.debug('trailing br detected');
      setContent('message', msgContentAfterInsert.replace(trailingBrRegex, '$1$2'));
      msgContentAfterInsert = getContent('message');
    }
  } catch (e1) {
    console.error(e1);
  }

  // Try to fix any element structure issues
  try {
    if (insertedVarLineStartRegex.test(msgContentAfterInsert)) {
      // The variable was placed before a container div and must be
      // integrated to it to avoid a line break.
      setContent('message', msgContentAfterInsert.replace(insertedVarLineStartRegex, '<div>$3&nbsp;'));
      
      msgContentAfterInsert = getContent('message');
    } else if (insertedVarRegex.test(msgContentAfterInsert)) {
      // The variable was placed outside of a container div and must be
      // integrated to it to avoid a line break.
      setContent('message', msgContentAfterInsert.replace(insertedVarRegex, '$2$3$4$1'));
      
      msgContentAfterInsert = getContent('message');
     
      const divTagRegex = /<div/g;
      const beforeInsertDivMatch = msgContentBeforeInsert.match(divTagRegex);
      const afterInsertDivMatch = msgContentAfterInsert.match(divTagRegex);
      
      if (Array.isArray(afterInsertDivMatch) && !Array.isArray(beforeInsertDivMatch) || 
          Array.isArray(afterInsertDivMatch) && Array.isArray(beforeInsertDivMatch) &&
          afterInsertDivMatch.length > beforeInsertDivMatch.length) {
        // Detected different number of divs (new divs were created for no reason).
        // Must fix variable integration by merging the surrounding divs together. 
        setContent('message', msgContentAfterInsert.replace(new RegExp(`(\&nbsp;)*(<span[^>]*id="${uniqueID}"[^>]*>[^<]*</span>)(\&nbsp;)*(</div><div[^>]*>)`), '$1$2$3'));
      }
  
    } else {
      console.debug('did NOT detect var to insert into previous container');
    }
  } catch (e2) {
    console.error(e2);
  }

  // Blur after having added the variable to avoid setting caret at wrong position
  if ("activeElement" in document) {
    document.activeElement.blur();
  }
}

function onTemplateSelectUpdated(params) {
  console.debug('onTemplateSelectUpdated called');
  if(params.hasOwnProperty('selected')) {
    let templatesSelect = document.getElementById('distributionTemplates');

    let initialSelectValue = $(templatesSelect).val();
    initialSelectValue = initialSelectValue.slice(0, initialSelectValue.length); // lose ref
    if(initialSelectValue.includes(params.selected)) { // won't be included if triggered manually
      initialSelectValue.splice(initialSelectValue.indexOf(params.selected), 1);
    }
    initialSelectValue = initialSelectValue.length == 1
      ?initialSelectValue[0] // should never be more than one
      :'';

    updateUniqueSelectValue(params.selected);
    showSpinner();
    apicall('distributionapi','fetchDistributionTemplateContent', { templateName: params.selected })
      .then((resp) => {
      if(typeof resp == 'object' && resp.hasOwnProperty('status') && resp.status === 204) {
        throw new Error('invalid server response'); // API returned null
      }
      loadDraft(templateToDraft(resp), function () {
        // on success
        document.getElementById('saveTemplateBtn').style.display = 'none';
        document.getElementById('templateOptionsBtn').style.display = 'block';
        $(document.getElementById('message').parentElement.querySelector('div.trumbowyg-editor')).trigger('focus');
      }, function() {
        // on failure
        updateUniqueSelectValue(initialSelectValue);
      });
    }).catch(() => {
      console.error('server error fetching template');
      cgToast(i18n.t("js.compose.template.load.fail", {template:params.selected}), { className: 'error-toast' });
      updateUniqueSelectValue(initialSelectValue);
    }).then(hideSpinner);

    function updateUniqueSelectValue(value) {
      for (let i = 0; i < templatesSelect.children.length; i++) {
        let tempateOption = templatesSelect.children[i];
        tempateOption.selected = value.length != 0
          && value === tempateOption.value;
      }
      $(templatesSelect).trigger('chosen:updated');
    }

  } else {
    document.getElementById('templateOptionsBtn').style.display = 'none';
    document.getElementById('saveTemplateBtn').style.display = 'block';
  }
}

var distrMessagePageName;

var testingMode;

var tobeprocessed;
var selectedTags;

var urlsToAdd;

var allGroupsAndMembers;
var selectedRecipientsId;

var fileList;
var fileMap;
var uploadedFilesIterator;
var fileToCategorizeIterator;
var atLeastOneWatermarkedFile;

var currentGroup;
var uploadedFiles;
var _tempImgName;
var arrayOfValues;
var extraEmailTaggle;

var parentFolderId;
var parentFolderName;

var lastSaveTime;
var hasLastDraftAutoSaved;
var toastHider;

var smartFolders;
var selectedFolders;
var fileCollection, folderCollection;
var dropboxFolderMap;
var boxFolderMap;
var crooglooFolderMap;
var hasFileCollectionChanged;
//watermarking
var currentWmFirstLine;
var currentWmSecondLine;

var wmFirstOtherField;
var wmSecondOtherField;

var linkOptions;

var defaultFileInfoSetting;
var isUsingDefaultInfo;

const MAX_ATTACHMENTS_SIZE = 20; // in Mb
const DRAFT_SAVING_INTERVAL = 2 * 60 * 1000; // save draft every 2 minutes

const LinkAccess = {
  READ_ONLY: 0,
  DOWNLOAD: 1,
  DOWNLOAD_PRINT: 2
};
Object.freeze(LinkAccess);

const LinkOptions = {
  NEVER_EXPIRING: -1,
  DEFAULT_VALIDITY: -1,
  DEFAULT_ACCESS: LinkAccess.DOWNLOAD_PRINT
};
Object.freeze(LinkOptions);

const HOUR_MS = 1000 * 3600;
const DAY_MS = HOUR_MS * 24;

/////////////////////////////////////////////////////////////////////////////
var casts = [];
var allContacts;

function initGlobals() {
  popupShowing = false;
  testingMode = false;
  tobeprocessed = 0;
  selectedTags = new Array();
  selectedTags.push("#distribution");

  urlsToAdd = new Array();

  allGroupsAndMembers = new Object();
  selectedRecipientsId = new Array();

  fileList = new Array();
  fileMap = new Object();
  uploadedFilesIterator = 0;
  fileToCategorizeIterator = 0;
  atLeastOneWatermarkedFile = false;

  currentGroup = new Array();
  msgId = '-';
  lastSaveTime = new Date().getTime();
  hasLastDraftAutoSaved = false;
  savedMessage = Utility.getPageParam('savedMessage');

  uploadedFiles = [];
  _tempImgName = "";
  arrayOfValues = new Array();

  selectedFolders = new Array();
  parentFolderId = "ROOT";
  parentFolderName = "Documents";

  smartFolders = [];
  fileCollection = [];
  folderCollection = [];
  dropboxFolderMap = {};
  boxFolderMap= {};
  crooglooFolderMap = {};
  hasFileCollectionChanged = false;
  toastHider = {};
  toastHider.newFiles = {};

  linkOptions = {
    linkValidityTime: LinkOptions.DEFAULT_VALIDITY,
    linkAccessType: LinkOptions.DEFAULT_ACCESS,
    isdeeplink: false
  };

  allContacts = null;

}

/**
 * informPreloadedData - Inform user that specific pre-loaded data is loading
 * since no loading-screen will be showing in the meantime.
 */
function informPreloadedData() {
  let storedAttachmentIds = Utility.getPageParam('attachmentID');
  if(savedMessage) {
    toastHider.draft = cgToast(i18n.t("js.compose.loading.draft"), { hiding: 'manual' });
  } else if(typeof storedAttachmentIds === 'string' || Array.isArray(storedAttachmentIds)) {
    toastHider.attachment
      = cgToast(i18n.t("js.compose.loading.attachment", {count:(Array.isArray(storedAttachmentIds)?25:1)}), { hiding: 'manual' });
  }
}

function addPreloadedData() {
  let done = function() { loader.check('preloaded-data'); }
  let storedAttachmentIds = Utility.getPageParam('attachmentID');
  let emailContent = Utility.getPageParam('emailBody');
  let emailSubject = Utility.getPageParam('emailSubject');
  if (emailSubject) {
    setSubject(emailSubject);
  }
  if(emailContent) {
    setContent('message', emailContent);
  } else if(savedMessage) {
    r.deleteExtraParam('savedMessage'); // important - prevents reloading draft after clear and refresh
    loadDraft(savedMessage, done, done);
    if(typeof toastHider.draft == 'function') { toastHider.draft(); }
  } else if(typeof storedAttachmentIds === 'string'
      || Array.isArray(storedAttachmentIds)) {
    try {
      addAttachmentsFromDocuments(Array.isArray(storedAttachmentIds)
        ?storedAttachmentIds :[storedAttachmentIds], done);
    } catch(e) {
      console.error(e);
      done();
    } finally {
      if(typeof toastHider.attachment == 'function') { toastHider.attachment(); }
    }
  } else {
    done();
    fetchActiveDraft().then(draft =>  {
      showSpinner();
      loadDraft(draft, hideSpinner, hideSpinner);
    }, function(rejection) {
      console.log(rejection);
    });
  }
}

function fetchActiveDraft() {
  return new Promise((resolve, reject) => {
    apicall('distributionapi','fetchLatestEmail', {}).then(resp => {
      console.debug(resp);
      if(resp && resp.status == STATUS.DRAFT) {
        let uniqueID = Utility.uniqueID();
        let wasDraftSavedToday = new Date().setHours(0,0,0,0) ===
          new Date(parseInt(resp.timeSaved)).setHours(0,0,0,0);
        let dateSaved = new Date(parseInt(resp.timeSaved));
        let hideToast = cgToast( (wasDraftSavedToday? 
          i18n.t("js.compose.draft.saved.today", {time: dateSaved.toString().match(/(\d+:\d+)(:\d+)/)[1]}):
          i18n.t("js.compose.draft.saved.other", {
            time: dateSaved.toString().match(/(\d+:\d+)(:\d+)/)[1], date: dateSaved.toString().match(/([\s\S]+)(\s\d+:\d+)/)[1]}))
          + `&nbsp;&#8211;&nbsp;<span class="restore draft-toast-btn no-text-select">${i18n.t("restore_")}</span>`, {
            hiding: 'manual',
            id: uniqueID,
            className: 'body-attached',
            containerID: 'docBody',
            showCloseButton: true
          });
        let isSettled = false;
        document.querySelector('#'+uniqueID+' > span.restore').onclick = e => {
          if(!isSettled) {
            isSettled = true;
            resolve(resp);
            hideToast();
          }
        }
        setTimeout(function() {
          if(!isSettled) {
            isSettled = true;
            reject(i18n.t("js.compose.draft.reject"));
            hideToast();
          }
        }, 15000);
      } else {
        reject(i18n.t("js.compose.draft.none"));
      }
    });
  });
}

function templateToDraft(template) {
  // note that we don't want to assign a value to the draft's signature property based on the current signature,
  // because it might not be done loading at this point
  let draft = {
    id: msgId,
    restoreRecipients: template.isRecipientsIncluded,
    restoreContent: true // will filter fields individually instead
  };
  if(template.isEmailBodyIncluded) {
    draft.unsignedMessage = template.emailBody;
  }
  if(template.isSMSIncluded) {
    draft.smsmessage = template.smsMessage;
  }
  if(template.isSubjectIncluded) {
    draft.subject = template.subject;
  }
  if(template.isRecipientsIncluded) {
    draft.distributionListId = template.distributionListId;
    draft.departmentId = template.departmentId;
    draft.extraEmailsForSelect = template.extraEmailsForSelect;
    draft.extraListMembersInputVal = template.extraListMembersInputVal;
  }
  draft.uploadedFilesJSON = template.attachmentJSON;
  return draft;
}

function getRawSubject(subject) {
  // Subject might be undefined or null for some templates
  return new RegExp('^no subject$', 'i').exec((subject||'').trim()) ? '':(subject || '');
}

function isFieldOverwriteValidated(draft) {
  return new Promise(resolve => {
    try {
      let fieldsToOverwrite = [];
      let trimmedRawSubject = getRawSubject(draft.subject).trim();
      let trimmedSubjectInputValue = document.getElementById("subject").value.trim();
      // we must check that the saved subject doesn't match the current input value in case
      // the user has chosen to load the template from the subject line's automated suggestions
      if (draft.hasOwnProperty('subject')
          && trimmedSubjectInputValue.length > 0
          && trimmedRawSubject.length > 0
          && !doesInputMatchTemplateSubject(trimmedRawSubject, trimmedSubjectInputValue)) {
        fieldsToOverwrite.push(i18n.t("mail.head.subject"));
      }
      if(draft.hasOwnProperty('unsignedMessage')
          && getContent('message').trim().length > 0
          && draft.unsignedMessage.trim().length > 0) {
        fieldsToOverwrite.push(i18n.t("mail.body.text"));
      }
      if (draft.hasOwnProperty('smsmessage')
          && document.getElementById("smsmessage").value.trim().length > 0
          && getRawSubject(draft.smsmessage).trim().length > 0) {
        fieldsToOverwrite.push(i18n.t("mail.body.SMS"));
      }
      // it's likely that the saved signature is equal to the one automatically
      // retrieved in the initial loading process, so we don't bother alerting the
      // user about overwritting it if the value doesn't change
      let currentTrimmedSignature = getContent('signature').trim();
      if (draft.hasOwnProperty('signature')
          && currentTrimmedSignature.length > 0
          && draft.signature.trim().length > 0
          && currentTrimmedSignature != draft.signature.trim()) {
        fieldsToOverwrite.push(i18n.t("utils.signature"));
      }
      if(fieldsToOverwrite.length) {
        swal({
          title: i18n.t("js.warning"),
          html: `<div style="text-align: left">${i18n.t("js.compose.overwrite")}<div>`
            + '<ol class="swal-list">' + fieldsToOverwrite.map(f => `<li>${f}</li>`).join('') + '</ol>',
          type: 'warning',
          confirmButtonText: i18n.t("js.compose.proceed"),
          showCancelButton: true,
          showCloseButton: true,
          allowOutsideClick: true,
          cancelButtonText: i18n.t("button.cancel")
        }).then(() => {
          console.debug('will proceed with draft or template in spite of fields to overwrite');
          resolve(true);
        }).catch(() => {
          console.debug('will NOT proceed with draft or template because of fields to overwrite');
          resolve(false);
        });
      } else {
        console.debug('no field to overwrite, will proceed with draft or template');
        resolve(true);
      }
    } catch(err) {
      console.error(err);
      resolve(false);
    }
  });
}

async function publishToStudioSwal(self) {

  let selectedCloudProvider = null

  return new Promise(accept => {

    swal({
      type: 'info',    
      title: i18n.t("docs.menu.import.cloud"),       
      showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: true,
      allowOutsideClick: true,
      confirmButtonText: i18n.t("button.confirm"),
      cancelButtonText: i18n.t("js.sides.callsheet.skip"),
      html: 
        '<select style="height: 45px; margin: 0;" id="select_provider">'
          +'<option value=""> Select Studio Destination </option>'
          +'<option value="BOX">Box</option>'
          +'<option value="GDRIVE">Dropbox</option>'
          +'<option value="DBOX">Google Drive</option>'
          +'<option value="SHAREPOINT">Sharepoint</option>'
        +'</select>'
      ,
      onOpen: () =>{
  
        if(JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken !== null && JSON.parse(global.secureLocalStorage.get('crooglooauth')).hasOwnProperty('boxRefreshToken')) {
          document.getElementById('select_provider').value = "BOX"
          $('.swal2-confirm.swal2-styled').css('background-color', 'rgb(19, 196, 106)')
          selectedCloudProvider = "BOX"
        }else {
  
          $('.swal2-confirm.swal2-styled').css('background-color', '#aaa')
        }
        $('.swal2-content').css('padding', '15px 30px')
  
        $('#select_provider').on('change', async (e) => {
          let cloudProvider = e.target.value
          if(cloudProvider === "BOX") {
            
            selectedCloudProvider = "BOX"
  
            if(JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken !== null && JSON.parse(global.secureLocalStorage.get('crooglooauth')).hasOwnProperty('boxRefreshToken')) {
              $('.swal2-confirm.swal2-styled').css('background-color', 'rgb(19, 196, 106)')            
            }
            else {
              var w = 450;
              var h = 600;
              var left = 0;
              var top = 0;
  
              window.open("/box.html", "_blank",
              'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width='
              + w + ',height=' + h + ',top=' + top + ',left=' + left);
            }
          }
          else {
            $('.swal2-confirm.swal2-styled').css('background-color', '#aaa')
            selectedCloudProvider = null
          }
        })
      },

      preConfirm: () =>{      
  
        return new Promise(function (resolve, reject) {       
          if(JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken !== null && JSON.parse(global.secureLocalStorage.get('crooglooauth')).hasOwnProperty('boxRefreshToken')) {
            if(selectedCloudProvider !== null) {
              resolve()
            }
          }
          reject()
        });
      }
    }).then(async (isConfirmed) =>{
        if(isConfirmed && selectedCloudProvider !== null) {
          accept(true)
        }
    })  
    .catch(isCancelled => {
      accept(false)
    })
  })
}

async function loadDraft(draft, successCallback, failureCallback) {
  console.debug('restoring from draft or template');
  if(!draft) { return }

  for(let k in draft) {
    if(draft[k] === '-') {
      draft[k] = '';
    }
  }

  let isRestoringRecipients = typeof draft.restoreRecipients !== 'boolean' || draft.restoreRecipients;
  let isRestoringContent = typeof draft.restoreContent !== 'boolean' || draft.restoreContent;

  /**
   * Making sure the template or draft will not overwrite any field that has already been
   * filled in by the user. Note that we do not need to do that for recipients or attachements,
   * because they will be added on top of what's already there.
   */
  if (isRestoringContent) {
    if(!await isFieldOverwriteValidated(draft)) {
      console.debug('field overwrite NOT validated - preventing draft/template load');
      if(typeof failureCallback === 'function') { failureCallback(); }
      return;
    }
  }

  lastSaveTime = new Date().getTime();
  msgId = draft.id || '-'; // must only be applied once fields to overwrite have been validated

  if(isRestoringRecipients) {
    loadRecipients();
    loadGroupsToNotify();
    loadReplyTo() ;
  } else {
    msgId = '-';
  }

  if(isRestoringContent) {
    loadSubject();
    loadMessage();
    loadSmsMessage();
    loadSignature();
    loadAttachments();
  } else {
    msgId = '-';
    if(typeof successCallback === 'function') { successCallback(); }
  }

  for(let key in draft) {
    delete draft[key]; // the draft values should not be re-used to prevent
    // sending old or non-applicable content, especially for forwarded messages
    // where recipients are changing
  }

  function loadSubject() {
    if(draft.hasOwnProperty('subject')) {
      document.getElementById("subject").value = getRawSubject(draft.subject);
    }
  }
  function loadMessage() {
    if (draft.hasOwnProperty('unsignedMessage')) {
      setContent('message', draft.unsignedMessage);
    }
  }
  function loadSmsMessage() {
    if(draft.hasOwnProperty('smsmessage')) {
      document.getElementById("smsmessage").value = draft.smsmessage;
    }
  }
  function loadSignature() {
    if(draft.hasOwnProperty('signature')) {
      setContent('signature', draft.signature);
    }
  }
  async function loadAttachments() {
    let savedFiles = JSON.parse(draft.uploadedFilesJSON);
    for(let i = 0, file; file = savedFiles[i++];) {
      let attachment = new Object();
      attachment.id = file.id;
      attachment.fileName = file.fileName;
      attachment.url = file.url;
      attachment.isWatermarked = file.isWatermarked;
      attachment.size = parseInt(file.size);
      if(!attachment.size || isNaN(attachment.size)) {
        attachment.size = await getFileSize(attachment);
      }
      addUploadedFile(attachment);
      console.log('adding file to uploaded files 3 (draft)');
    }
    if(savedFiles.length) {
      refreshFileBox();
    }
    if(draft.hasOwnProperty('areAttachmentsLinks')) {
      document.getElementById('sendAsLinkChk').checked = draft.areAttachmentsLinks === true;
      linkOptions.linkValidityTime = draft.linkValidityTime;
      linkOptions.linkAccessType = draft.linkAccessType;
      linkOptions.isdeeplink = draft.isdeeplink;
    }
    if(typeof successCallback === 'function') { successCallback(); }
  }

  function loadRecipients() {

    if(typeof draft.extraEmailsJSON == 'string') {
      JSON.parse(draft.extraEmailsJSON).forEach(extraEmail => {
        console.log(extraEmail) ;
        if(typeof extraEmail == 'object' && extraEmail.selectedEmail) {
          addExtraEmail(extraEmail.selectedEmail, true, false, extraEmail.primaryEmail);
        }
      });
    } else {
      draft.extraEmailsForSelect.split(',').forEach(email => {
        if(email && email.trim()) {
          addExtraEmail(email, true);
        }
      });
    }

    let haveListsChanged = false;
    draft.extraListMembersInputVal.split(',').forEach(emailInputValue => {
      if(emailInputValue.trim().length > 0 && emailInputValue.trim() !== '-') {
        let $inputVal = $('input[value="'+emailInputValue+'"]');
        if(!$inputVal.length) {
          haveListsChanged = true;
        } else {
          if(!$inputVal[0].checked) {
            $inputVal.click();
          }
        }
      }
    });

    draft.distributionListId.split(',').forEach(listId => {
      if(listId.trim().length > 0 && listId.trim() !== '-') {
        // must iterate though all beacuse listId might not be valid for query
        let foundList = false;
        $('.distr-group-list-item span.member-count').each((idx, elem) => {
          if(elem.getAttribute('listId') === listId) {
            foundList = true;
            let $elemInput = $(elem).closest('li').find('input');
            let isIndeterminate = $elemInput[0].classList.contains('indeterminate');
            if(!$elemInput[0].checked || isIndeterminate) {
              // if isIndeterminate, we first deselect all
              $elemInput.click();
            }
            if(isIndeterminate) {
              // must click a second time to select all
              $elemInput.click();
            }
          }
        });
        if(!foundList) {
          haveListsChanged = true;
        }
      }
    });

    (draft.departmentId||'').split(',').forEach(deptId => {
      if(deptId.trim().length > 0 && deptId.trim() !== '-') {
        // must iterate though all beacuse listId might not be valid for query
        let foundList = false;
        $('.dept-list-item span.member-count').each((idx, elem) => {
          if(elem.getAttribute('listId') === deptId) {
            foundList = true;
            let $elemInput = $(elem).closest('li').find('input');
            let isIndeterminate = $elemInput[0].classList.contains('indeterminate');
            if(!$elemInput[0].checked || isIndeterminate) {
              // if isIndeterminate, we first deselect all
              $elemInput.click();
            }
            if(isIndeterminate) {
              // must click a second time to select all
              $elemInput.click();
            }
          }
        });
        if(!foundList) {
          haveListsChanged = true;
        }
      }
    });

    updateRecipientsInput();
    if(haveListsChanged) {
      cgToast(i18n.t("js.compose.distro.changed"), {showCloseButton: true, duration: 10000});
    }
  }
  function loadGroupsToNotify() {
    if(draft.hasOwnProperty('distributionListNotify')) {
      draft.distributionListNotify.split(',').forEach(listId => {
        if(listId.trim().length > 0 && listId.trim() !== '-') {
          // must iterate though all beacuse listId might not be valid for query
          $("#distributionGroupNotify option").each(function () {
            if(this.value === listId) {
              this.selected = true;
            }
          });
        }
      });
      $('#distributionGroupNotify').trigger('chosen:updated');
    }
  }

  function loadReplyTo() {
    if(draft.replyToEmail && document.querySelector(`#replyToContainer select > option[value="${draft.replyToEmail}"]`) != null) {
      document.querySelector('#replyToContainer select').value = draft.replyToEmail;
    }
  }
}

function removeFromArray(array,item) {
	var index = array.indexOf(item);
	if (index > -1) {
	    array.splice(index, 1);
	}
}

function saveSignature() {
  var signature = getContent('signature');

  apicall('distributionapi', 'saveSignatureToDatastore', {}, {
    value: signature
  }).then(function(resp) {
    if(resp && resp.responseCode && resp.responseCode === '0') {
      swal({
        title: i18n.t("response.success"),
        html: i18n.t("js.compose.signature.saved"),
        type: 'success',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("button.confirm"),
        allowOutsideClick: true,
        showCloseButton: true                
      });

      saveSystemActivity({
        action: 'save',
        params: findSystemActivityParams({}),
        message: 'User saved a new signature.'
      });
    } else {
      cgToast(i18n.t("js.compose.signature.fail.msg1"));
      cgToast(i18n.t("js.compose.signature.fail.msg2"));

      saveSystemActivity({
        action: 'save',
        params: findSystemActivityParams({}),
        message: 'User failed to save a new signature.'
      });
    }
  });
}

function toggleTag(obj)
{
	if (obj.dataset.on=="0")
		{
		obj.dataset.on="1";
		obj.style.backgroundColor="#02BF57";
		selectedTags.push(obj.dataset.tag);
		////alert(JSON.stringify(selectedTags));
		}
	else
		{
		obj.dataset.on="0";
		obj.style.backgroundColor="#c1c1c1";
		removeFromArray(selectedTags,obj.dataset.tag);
		}
}

async function addFiles() {

  for (var i=0;i<urlsToAdd.length;i++) {
		var obj = urlsToAdd[i];
    if(!obj.size || isNaN(obj.size)) {
      showSpinner();
      obj.size = await getFileSize(obj, hideSpinner);
    }
    if(obj.type && obj.type == 'dropbox-file') {
      console.log('importing dropbox file: ');
      console.log(obj);
      myDropZone2.emit("addedfile", obj);
    } 
    else if(obj.type && obj.type == 'box-file') {
      processedFilesCount += 1
      console.log('importing box file: ');
      console.log(obj);
      myDropZone2.emit("addedfile", obj);
    }
    else {
      addUploadedFile(obj);
      console.log('adding file to uploaded files 2 (find files)');
      console.log(obj)
    }
  }
	refreshFileBox();


  let docsToPublishToStudio = []
  docsToPublishToStudio = urlsToAdd.filter((item) => !item.isPublishedToStudio && !(obj.hasOwnProperty('type')))

	urlsToAdd = new Array();
}

function selectDocument(){
  if(!urlsToAdd || !urlsToAdd.length){
    cgToast(i18n.t("js.wtmrk.select-file"), 3000)
    return;
  }
  toggleFileViewer();
  addFiles();
}

function getFileSize(attachment, callback) {
  return new Promise((resolve, reject) => {
    apicall('documentsapi','getAttachmentSize',{},attachment).then(resp => {
      if(resp.responseCode === '-1') {
        console.error('Failed to get attachment size for: ');
        console.error(attachment);
      } else {
        console.log('updated missing file size on document: ');
        console.log(attachment);
      }
      if(typeof callback === 'function') { callback(); }
      resolve(parseInt(resp.responseMessage));
    })
  });
}

//-------------------My js functions-----------------------

function doesInputMatchTemplateSubject(subject, inputValue) {
  return subject.trim().replace(/^-$/, '').toLowerCase().startsWith(inputValue.toLowerCase().trim())
}

function getTemplateNames(dropdownId, selectedTemplateVal) {
  var tmp = new Object();
  console.log("**Getting Template Names**");

  apicall('distributionapi','fetchDistributionEmailTemplates',tmp).then(resp => {
    if (resp.items) {
      var select = document.getElementById(dropdownId);
      let items = resp.items;

      // clear out the existing options if there are any
      var numOfOptions = select.options.length;
      if (numOfOptions >= 1)
      {
        var i;
        for (i = select.options.length - 1; i >= 0; i--) {
          select.remove(i);
        }
      }

      items.sort((a, b) => a[0].localeCompare(b[0]));
      for (var i = 0; i <= items.length; i++)
      {
        var item = items[i];
        if (item != null)
        {
          let templateName = item[0];
          var opt = document.createElement("option");
          opt.className = 'groupOptions';
          opt.value = templateName;
          opt.textContent = templateName;
          select.appendChild(opt);
        }
      }
      if(selectedTemplateVal) {
        select.value = selectedTemplateVal;
      }
      try {
        $(select).trigger('chosen:updated');
        let subjectInput = document.getElementById('subject');
        let subjectAwesomplete = new Awesomplete(subjectInput, {
          list: items.map(item => item[0]),
          filter: function (text, inputValue) {
            try {
              let correspondingTemplate = items.find(item => item[0] == text);
              return doesInputMatchTemplateSubject(correspondingTemplate[1], inputValue);
            } catch(err1) {
              console.error('failed to filter templates from subject');
              console.error(err1);
              return false;
            }
          },
          replace: function (suggestion) {
            try {
              let correspondingTemplate = items.find(item => item[0] == suggestion.value);
              if(correspondingTemplate != null) {
                this.input.value = correspondingTemplate[1];
              }
            } catch(err1) {
              console.error('failed to replace subject');
              console.error(err1);
            } finally {
              let currentTemplateSelection = $('#distributionTemplates').val();
              if(currentTemplateSelection.length == 0 || currentTemplateSelection[0] != suggestion.value) {
                onTemplateSelectUpdated({ selected: suggestion.value });
              } else {
                console.log('template already selected');
              }
            }
          }
        });

        document.getElementById('subject').onkeydown = function(event) {
          if(event.key.toLowerCase() == 'tab' || event.code.toLowerCase() == 'tab') {
            if(subjectAwesomplete.ul.childNodes.length > 0 && !subjectAwesomplete.ul.hasAttribute('hidden')) {
              event.preventDefault();
              event.stopImmediatePropagation();
              // subjectAwesomplete.open();
              subjectAwesomplete.goto(0);
              subjectAwesomplete.select();
            }
          }
        }
      } catch(e) {
        console.log(e.message);
      }
    }
    endTemplateLoad();
  }).catch(err => {
    console.error(err);
    endTemplateLoad();
  });
  function endTemplateLoad() {
    let templateSelect = document.getElementById(dropdownId);
    templateSelect.setAttribute('data-placeholder', i18n.t("js.compose.template.select"));
    $(templateSelect).prop('disabled', false).trigger('chosen:updated');
    loader.check('templates');
  }
}

function showTemplateModal() {
  swal({
    title: i18n.t("js.compose.template.title"),
    input: 'text',
    inputPlaceholder: i18n.t("js.compose.template.plchldr"),
    showCancelButton: true,
    showCloseButton: true,
    allowOutsideClick: true,
    confirmButtonText: i18n.t("button.confirm"),
    cancelButtonText: i18n.t("button.cancel"),
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    inputValidator: function (value) {
      return new Promise(function (resolve, reject) {
        if (typeof value == 'string' && value.trim().length > 0) {
          resolve()
        } else {
          reject(i18n.t("js.compose.template.name.rejection"))
        }
      })
    }
  }).then(function(result) {
    showTemplateOptions(result.trim());
  }).catch(swal.noop);
}

function saveLoadedTemplate() {
  let templateNameArr = $('#distributionTemplates').val();
  if(!Array.isArray(templateNameArr) || templateNameArr.length !== 1 || templateNameArr[0].trim().length == 0) {
    cgToast(i18n.t("js.compose.template.save.fail"));
    console.error('invalid template selection', templateNameArr);
    return;
  }
  showTemplateOptions(templateNameArr[0]);
}

function showDeleteTemplateModal() {
  let $distributionTemplateSelect = $('#distributionTemplates');
  let templateNameArr = $distributionTemplateSelect.val();
  if(!Array.isArray(templateNameArr) || templateNameArr.length !== 1 || templateNameArr[0].trim().length == 0) {
    cgToast(i18n.t("js.compose.template.delete.deny"));
    console.error('invalid template selection', templateNameArr);
    return;
  }
  let templateName = templateNameArr[0];
  swal({
    title: i18n.t("js.compose.template.delete.title"),
    html: i18n.t("js.compose.template.delete.text", {templateName}),
    type: 'question',
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    confirmButtonColor: '#BC2121',
    confirmButtonText: i18n.t("utils.delete"),
    showLoaderOnConfirm: true,
    showCloseButton: true,
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    allowOutsideClick: () => !swal.isLoading(),
    preConfirm: function() {
      return new Promise((resolve, reject) => {
        apicall('distributionapi', 'deleteEmailTemplate', {
          templateName: templateName
        }).then(resp => {
          if(resp.responseCode === '0') {
            resolve();
          } else {
            throw new Error('server error');
          }
        }).catch(() => {
          reject(i18n.t("redirect.reason.server"));
        });
      });
    }
  }).then(function() {
    $distributionTemplateSelect.find(`option[value="${templateName}"]`).remove();
    $distributionTemplateSelect.trigger('chosen:updated');
    document.getElementById('templateOptionsBtn').style.display = 'none';
    document.getElementById('saveTemplateBtn').style.display = 'block';
    swal({
      title: i18n.t("response.success"),
      html: i18n.t("js.compose.template.delete.success"),
      type: 'success',
      allowOutsideClick: true,
      showCloseButton: true
    }).catch(swal.noop);
  }).catch(swal.noop);
}

function getContent(textAreaId) {
  let htmlContent = $('#'+textAreaId).trumbowyg('html');
  htmlContent = htmlContent.replace(/(<\/?)(p(?=(\s|>)))/gi, '$1div');
  return htmlContent;
}

function setContent(textAreaId, htmlContent) {
  $('#'+textAreaId).trumbowyg('html', htmlContent);
}

function setSubject(subject) {
  document.getElementById("subject").value = subject;
}

function getPrimaryExtraEmails()	{
  let extraEmails = [];
  $("#extraEmailSelect option").each(function () {
    if(this.selected) {
      extraEmails.push(this.getAttribute('primaryEmail'));
    }
  });
  return extraEmails;
}

function getExtraEmailsAndNames() {
  let result = {};
  $("#extraEmailSelect option").each(function () {
    if (this.selected) {
      result[this.getAttribute('primaryEmail')] = this.getAttribute('name');
    }
  });
  return result;
}

function getSelectedExtraEmails() {
  let selectedExtraEmails = [];
  $("#extraEmailSelect option").each(function() {
    if(this.selected && this.value.match(/^[,\s]*$/)==null) {
      selectedExtraEmails = selectedExtraEmails.concat(this.value.split(/,\s*/g));
    }
  });
  return selectedExtraEmails;
}

function getGroupsToNotify() {
  let notifyGroups = [];
  $("#distributionGroupNotify option").each(function () {
    if(this.selected) {
      notifyGroups.push(this.value);
    }
  });
  return notifyGroups;
}

function getMetaData() {
  initAllDocuments(() => loader.check('documents'));
  fetchAllFolders(() => loader.check('folders'));
  fillDistributionGroupList(() => loader.check('distroLists'));
  fillDepartmentList(() => loader.check('departments'))
  fetchEmailList(() => loader.check('emailList'));
  fetchReplyToOptions(() => loader.check('reply-to'));
  fetchDefaultFileInfoSetting(() => loader.check('user-settings'))

  getTemplateNames("distributionTemplates");

  var tmp2 = new Object();

  apicall('distributionapi','fetchSignatureFromDatastore',tmp2).then((resp) => {
    setContent('signature', resp.responseMessage ? resp.responseMessage:'');
    loader.check('signature');
  });
}

function fetchReplyToOptions(done) {
  let userFirstName = croogloo_auth.crooglooauth.firstName;
  let userLastName = croogloo_auth.crooglooauth.lastName;
  let userTitle = ((userFirstName || '').trim() + ' ' + (userLastName || '').trim()).trim();
  let userEmail = croogloo_auth.crooglooauth.email;
  let replyToUsers = [];
  const REPLY_TO_PROP = 'reply-to email';
  let cachedReplyToEmail = null;
  Promise.all([
    apicall('distributionapi', 'getProductionOfficeUsers', {}),
    apicall('settingsapi', 'fetchCachedData', { propName: REPLY_TO_PROP })
  ]).then(responses => {
    if(responses[0].items.length) {
      replyToUsers.push(...responses[0].items);
    }
    if(responses[1].responseCode === '0' && responses[1].responseMessage.length > 0) {
      cachedReplyToEmail = responses[1].responseMessage;
    }
  }).catch(() => {
    console.error('could not retrieve production office members and/or cached data');
  }).then(function() {
    replyToUsers.sort((u1, u2) => u1.name.localeCompare(u2.name));
    if(typeof replyToUsers.find(u => u.email == userEmail) == 'undefined') {
      replyToUsers.push({
        name: userTitle,
        email: userEmail
      });
    }
    let select = document.querySelector('#replyToContainer select');
    for(let user of replyToUsers) {
      let option = document.createElement('option');
      option.value = user.email;
      option.textContent = `${user.name} (${user.email})`;
      select.appendChild(option);
    }
    if(cachedReplyToEmail != null // cached data must still be a valid option
      && typeof replyToUsers.find(u => u.email == cachedReplyToEmail) != 'undefined') {
      select.value = cachedReplyToEmail;
    }
    if(replyToUsers.length > 1) {
      select.disabled = false;
      select.onclick = e => e.stopPropagation();  // prevents option display glitch
                                                  // from hiding recipient panel
      select.onchange = function() {
        apicall('settingsapi', 'updateCachedData', {
          propName: REPLY_TO_PROP,
          propValue: this.value
        }, undefined, false).then(resp => {
          if(resp.responseCode !== '0') {
            throw new Error('server error');
          }
        }).catch(() => {
          console.error('failed to cache reply-to email');
        });
      }
    }
    document.getElementById('replyToContainer').style.display = '';
    done();
  });
}

/**
 * This will fetch all of the emails that can be selected in the extra emails list at the top of the distro list panel
 */
function fetchEmailList(done) {
  apicall('distributionapi', 'fetchEmailList', {}).then(function (resp) {
    let select = document.getElementById('extraEmailSelect');

    // Sort array of responses by cast number (if exists) and then alphabetically
    // to ensure array is sorted before being displayed.

    // This is what an individual item in the array looks like
    // ["ericdanc@mac.com", "#2 Eric Danc <ericdanc@mac.com>","ericdace@mac.com","2", "BELL", "CAST", "Cast List"]
    // We will parse the cast number out of array index 1 and then compare their name alphabetically.
    let items = resp.items;

    /**
     * Takes an element from the list and returns true if it starts with a pound sign and a number, and false otherwise
     * @param {String} string
     * @returns boolean
     */
    function isNumberedEntry(string) {
      return string.match(/^#\d+/) !== null;
    }

    /**
     * takes an element from the list and extracts the number following the pound sign if it exists, and returns Infinity otherwise.
     * @param {String} string 
     * @returns boolean
     */
    function sortKey(string) {
      const match = string.match(/^#(\d+)/);
      return match !== null ? parseInt(match[1]) : Infinity;
    }

    /**
     * takes an element from the list and removes the pound sign and number at the beginning of the string if it exists
     * @param {String} string 
     * @returns 
     */
    function nameKey(string) {
      return string.replace(/^#\d+ /, '');
    }

    /**
     * The comparison function first compares the elements by whether or not 
     * they are numbered entries using the isNumberedEntry() function, and if they are not the same, 
     * returns -1 or 1 accordingly. If they are both numbered entries or both non-numbered entries, 
     * the function proceeds to compare them by their keys (the numbers following the pound sign) 
     * using the sortKey() function, and if they are equal, it compares the elements by their names 
     * using the nameKey() function.
     */
    const sortedExtraEmailsArray = items.sort(function (a, b) {
      const aRawNameString = a[1];
      const bRawNameString = b[1];
      const aIsNumbered = isNumberedEntry(aRawNameString);
      const bIsNumbered = isNumberedEntry(bRawNameString);
      if (aIsNumbered && !bIsNumbered) {
        return -1;
      } else if (!aIsNumbered && bIsNumbered) {
        return 1;
      } else {
        const aKey = sortKey(aRawNameString);
        const bKey = sortKey(bRawNameString);
        if (aKey < bKey) {
          return -1;
        } else if (aKey > bKey) {
          return 1;
        } else {
          const aName = nameKey(aRawNameString);
          const bName = nameKey(bRawNameString);
          if (aName < bName) {
            return -1;
          } else if (aName > bName) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    });
    for (let i = 0, item; item = sortedExtraEmailsArray[i++];) {
      let option = document.createElement('option');
      option.value = item[0];
      option.textContent = item[1];
      option.setAttribute('primaryEmail', item[2]);
      option.setAttribute('name', item[1].substring(0, item[1].indexOf('<')).trim());
      option.setAttribute('cast_number', item[3]);
      option.setAttribute('job_title', item[4]);
      option.setAttribute('department', item[5]);
      option.setAttribute('distro_lists', item[6]);
      select.appendChild(option);
    }
    document.getElementById('extraEmailSelect').setAttribute('data-placeholder', i18n.t("js.compose.email-select.plchldr"));
    $('#extraEmailSelect').prop('disabled', false).trigger('chosen:updated');

    addStoredEmails();
    done();
  });
}

function fetchDefaultFileInfoSetting(done) {
  apicall('productionapi', 'fetchUserSetting', {
    settingName: 'requireAttachmentFileInfo'
  }).then(resp => {
    if(resp.responseCode === '0') {
      defaultFileInfoSetting = resp.responseMessage;
    } else {
      throw new Error('invalid server response');
    }
  }).catch(() => {
    console.error('invalid server response to attachment file info setting');
    defaultFileInfoSetting = 'ask';
  }).then(done);
}

// NOTE This function does not currently support folders with multiple parents
function fetchAllFolders(done) {
  apicall('documentsapi', 'fetchAllFolders', {}).then(resp => {
    if(typeof resp.items !== 'object' || resp.items.length && typeof resp.items[0].properties !== 'object') {
      console.error('failed to fetch folders');
      done();
      return;
    }

    let allFolderIds = [];
    resp.items.forEach(folder => {
      try {
        folder.properties.id = folder.properties.id.replace(/%20/g, ' ');
        folder.properties.parentFolder = folder.properties.parentFolder.replace(/%20/g, ' ');
        folder.properties.parents.value = folder.properties.parents.value.replace(/%20/g, ' ');
      } catch(e) {
        console.debug(e);
      }
      allFolderIds.push(folder.properties.id);
    });

    // TODO move to docUtility to avoid duplicated code on Upload.js
    let validCollection;
    do { // we assume there's no circular dependencies
      validCollection = true;
      for(let i = 0; i < resp.items.length; i++) {
        let testedFolderId = null;
        try {
          testedFolderId = resp.items[i].key.name;
          let folderProps = resp.items[i].properties;
          let parentId = (typeof folderProps.parents == 'object' && folderProps.parents.value)
            ?folderProps.parents.value.split(',')[0] :folderProps.parentFolder;
          if((!parentId || !allFolderIds.includes(parentId)) && folderProps.id !== 'ROOT' || !folderProps.fileName) {
            throw 'Invalid folder: ' + folderProps.id;
          }
        } catch(e) {
          // removing the folders from the list of entities that will be used
          // to generate the JS tree
          resp.items.splice(i, 1);
          if(testedFolderId !== null) {
            // must remove the folder from the allFolderIds list so that its children
            // folders are not considered as valid, creating a bug in the JS tree
            // generation process
            let testedFolderIdIndex = allFolderIds.findIndex(fId => fId === testedFolderId);
            if(testedFolderIdIndex !== -1) {
              allFolderIds.splice(testedFolderIdIndex, 1);
            }
          }
          validCollection = false;
          console.error(e);
          break;
        }
      }
    } while(!validCollection);

    resp.items.forEach(folder => {
      try {
        let folderProps = folder.properties;
        let parentId = (typeof folderProps.parents == 'object' && folderProps.parents.value)
          ?folderProps.parents.value.split(',')[0] :folderProps.parentFolder;

        if(parentId && folderProps.id !== 'ROOT') {
          folderCollection.push({
            id: folderProps.id,
            parent: parentId,
            text: folderProps.fileName,
          });
          if(folderProps.isSmartFolder) {
            smartFolders.push(folderProps);
          }
        }
      } catch(e) {
        console.error(e);
      }
    });
    done();
  });
}

function initAllDocuments(done) {
  initFileTree();
  new TaskList({ passReference: true })
    .add(list => {
      initCrooglooDocs(() => list.check('croogloo'));
      initDropbox(() => list.check('dropbox'));
      initBox(() => list.check('box'))
      initGoogleDrive(() => list.check('google-drive'));
    }, ['croogloo','dropbox','google-drive', 'box'])
    .add(list => {
      hideDocLoader();
      loadDocTree(() => list.check('load tree'));
    }, 'load tree')
    .add(list => {
      allowDropboxLogin(() => list.check('db-login'));
      allowBoxLogin(() => list.check('box-login'))
    }, ['db-login', 'box-login'])
    .oncomplete(done)
    .execute();

  function hideDocLoader() {
    document.getElementById('systemFileLoader').style.display = 'none';
  }
}

function initFileTree() {
  fileCollection = [];
  dropboxFolderMap = {};
  boxFolderMap = {};
  crooglooFolderMap = {};
  fileCollection.push({"id": "ROOT", "parent": "#", "text": "Documents", 'state' : { 'opened' : false }, li_attr: { treeIndex: 0 }});
  fileCollection.push({"id": "DROPBOX_ROOT", "parent": "#", "text": "Dropbox", 'state' : { 'opened' : false }, "icon": "icon-dropbox", li_attr: { treeIndex: 0 }});
  folderCollection.push({"id": "ROOT", "parent": "#", "text": "Documents", 'state' : { 'opened' : true }, li_attr: { treeIndex: 0 }});
  fileCollection.push({"id": "BOX_ROOT", "parent": "#", "text": "Box", 'state' : { 'opened' : false }, "icon": "icon-box", li_attr: { treeIndex: 0 }});

}

function initDropbox(done) {
  if(croogloo_auth.crooglooauth.dropboxToken && croogloo_auth.crooglooauth.dropboxToken !== '403') {
    fetchAllDropboxFiles('', 'DROPBOX_ROOT', PRELOADED_FOLDER_DEPTH.DROPBOX).then(done);
  } else {
    console.log("initDropbox : COULD NOT INIT!");
    done();
  }
}

function initBox(done) {  
  if(croogloo_auth.crooglooauth.boxToken && croogloo_auth.crooglooauth.boxToken !== '403') {
    fetchAllBoxFiles('', 'BOX_ROOT', PRELOADED_FOLDER_DEPTH.BOX).then(done);
  } else {
    console.log("initBox : COULD NOT INIT!");
    done();
  }
}

function fetchAllBoxFiles(path, parentFolder, recursions) {
  return new Promise(async function(resolve) {
    recursions--;
      let entries = await fetchBoxFiles(path); 
    let folders = new Array();
    let completedFolders = 0;
    if(entries.length) {
      hasFileCollectionChanged = true;
    }
    for(let i = 0, entry; entry = entries[i++];) {

      if(entry.type !== 'folder') {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${croogloo_auth.crooglooauth.boxToken}`);
          myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        await fetch(`https://api.box.com/2.0/files/${entry.id}`, requestOptions)
                         .then(response => response.text())
                         .then(result => {
                            result = JSON.parse(result)
                            entry.size = result.size
                         })
      }

      let node = {
        "id": entry.id,
        "parent": parentFolder,
        "text": entry.name,
        "icon": entry.type !== "folder" ?"jstree-file" :"jstree-folder",
        "li_attr": {
          "size": entry.size + "",
          "type": "box-" + (entry.type !== "folder" ?'file' :'folder'),
          "isFilled": recursions === 0 ?"0" :"1",
          "path": entry.id,
          "subcategory": null,
          "treeIndex": i
        }
      };
      fileCollection.push(node);
      boxFolderMap[node.id] = node;
      if(entry.type === 'folder') {
        folders.push({path: entry.path_lower, id: entry.id});
      }
    }
    if(recursions > 0 && folders.length > 0) {
      folders.forEach(folder => {
        fetchAllBoxFiles(folder.path, folder.id, recursions).then(() => {
          completedFolders++;
          checkDbCompletion();
        });
      });
    } else {
      checkDbCompletion();
    }
    function checkDbCompletion() {
      if(completedFolders >= folders.length || recursions <= 0) {
        resolve();
      }
    }
  });
}

function fetchAllDropboxFiles(path, parentFolder, recursions) {
  return new Promise(async function(resolve) {
    recursions--;
    let entries = await fetchDropboxFiles(path);
    let folders = new Array();
    let completedFolders = 0;
    if(entries.length) {
      hasFileCollectionChanged = true;
    }
    for(let i = 0, entry; entry = entries[i++];) {
      let node = {
        "id": entry.id.split(':', 2)[1],
        "parent": parentFolder,
        "text": entry.name,
        "icon": entry['.tag'] !== "folder" ?"jstree-file" :"jstree-folder",
        "li_attr": {
          "size": entry.size + "",
          "type": "dropbox-" + (entry['.tag'] !== "folder" ?'file' :'folder'),
          "isFilled": recursions === 0 ?"0" :"1",
          "path": entry.path_lower,
          "subcategory": null,
          "treeIndex": i
        }
      };
      fileCollection.push(node);
      dropboxFolderMap[node.id] = node;
      if(entry['.tag'] === 'folder') {
        folders.push({path: entry.path_lower, id: entry.id.split(':', 2)[1]});
      }
    }
    if(recursions > 0 && folders.length > 0) {
      folders.forEach(folder => {
        fetchAllDropboxFiles(folder.path, folder.id, recursions).then(() => {
          completedFolders++;
          checkDbCompletion();
        });
      });
    } else {
      checkDbCompletion();
    }
    function checkDbCompletion() {
      if(completedFolders >= folders.length || recursions <= 0) {
        resolve();
      }
    }
  });
}


function fetchBoxFiles(path) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${croogloo_auth.crooglooauth.boxToken}`);
  myHeaders.append("Content-Type", "application/json");
  // let path = decodeURI(window.location.hash.replace('#', '').split('/')).split(',')
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return new Promise((resolve) => {
    fetch(`https://api.box.com/2.0/folders/${path == '' ? '0' : path}/items`, requestOptions)
    .then(response => response.text())
    .then(result => {
      result = JSON.parse(result)
      resolve(result.entries)
      
    },(err)=>{
      if (err.status >= 400 && err.status < 500) {
        cgToast(i18n.t("js.trdparty.err.denied", {thirdparty: "Box"}));
        croogloo_auth.boxunlink().then(()=>{
          r.loadPage('documents')
          self.boxlink();
        })
      } else if(err.status > 500){
        cgToast(i18n.t("js.trdparty.err.connection", {thirdparty: "Box"}));
      }else {
        cgToast(i18n.t("js.trdparty.err.load", {thirdparty: "Box"}));
      }
    }).catch((err)=>{
      console.error(err);
      cgToast(i18n.t("js.trdparty.err.load", {thirdparty: "Box"}));
    });
  })
}

function allowBoxLogin(done) {
  $('#BOX_ROOT_anchor')[0].onclick = async function(e) {
    if(!croogloo_auth.crooglooauth.boxToken) {
      e.preventDefault();
      e.stopImmediatePropagation();
      var w = 450;
      var h = 600;
      var left = 0;
      var top = 0;
      croogloocurrentpage.onBoxLogin = function() {
        initBox(function() {
          refreshDocTree();
          croogloocurrentpage.onBoxLogin = null;
        });
      }
        window.open("/box.html", "_blank",
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width='
      + w + ',height=' + h + ',top=' + top + ',left=' + left);
    }
    else {
      await croogloo_auth.box(croogloo_auth.crooglooauth.boxRefreshToken, true)
    }
  }
  done();
}


function fetchDropboxFiles(path) {
  return new Promise(resolve => {
    new Dropbox({accessToken: croogloo_auth.crooglooauth.dropboxToken}).filesListFolder({path: path}).then(resp => {
      resolve(resp.entries)
    }, (err) => {
      if (err.status >= 400 && err.status < 500) {
        cgToast(i18n.t("js.trdparty.err.denied", {thirdparty: "Dropbox"}));
        croogloo_auth.dropboxunlink();
      } else if(err.status > 500){
        cgToast(i18n.t("js.trdparty.err.connection", {thirdparty: "Dropbox"}));
      } else {
        cgToast(i18n.t("js.trdparty.err.load", {thirdparty: "Dropbox"}));
      }
      resolve([]);
    }).catch((err)=>{
      if(err.status === 401) croogloo_auth.dropboxunlink();
      console.log(err);
      cgToast(i18n.t("js.trdparty.err.load", {thirdparty: "Dropbox"}));
      resolve([]);
    });
  });
}

function allowDropboxLogin(done) {
  $('#DROPBOX_ROOT_anchor')[0].onclick = function(e) {
    if(!croogloo_auth.crooglooauth.dropboxToken) {
      e.preventDefault();
      e.stopImmediatePropagation();
      var w = 450;
      var h = 600;
      var left = 0;
      var top = 0;
      croogloocurrentpage.onDropboxLogin = function() {
        initDropbox(function() {
          refreshDocTree();
          croogloocurrentpage.onDropboxLogin = null;
        });
      }
      var newwindow = window.open("/dropbox.html", "dpauth",
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no,width='
      + w + ',height=' + h + ',top=' + top + ',left=' + left);
    }
  }
  done();
}

function refreshDocTree() {
  // we make sure there's been a change to prevent jstree bug where tree
  // automatically closes after refreshing
  if(hasFileCollectionChanged) {
    $('#fileTree').jstree(true).settings.core.data = fileCollection;
    $('#fileTree').jstree(true).refresh();
  }
}

function initGoogleDrive(done) {
  done();
}

function fillDepartmentList(done) {
  let departments = new Array();
  let persons = new Array();
  new TaskList({passReference: true})
    .add(function(list) {
      fetchDepartments(departments, () => list.check('departments'));
      fetchPersons(persons, () => list.check('persons'));
    }, ['departments', 'persons'])
    .add(function(list) {
      generateDepartmentList(departments, persons, () => list.check('department-table'));
    }, 'department-table')
    .oncomplete(done)
    .execute();
}

function addStoredEmails() {
  //recipients selected from another page such as cast and crew
  let personEmails = sessionStorage.getItem('email');
  if (typeof personEmails === 'string' && personEmails.trim()) {
    sessionStorage.removeItem('email');
    let emailList = personEmails.split(',');
    emailList.forEach(function(email){
      addExtraEmail(email, true);
    });
  }
}

/* ----------------------------------------------- */

function fillDistributionGroupList(done) {

	createLoadingDots();

  let selectionInfo = document.getElementById('selectionInfo');

  apicall('distributionapi','fetchDistributionGroupAndMembers',{})
    .then(function(resp2) {

      //responce = list of distributions with mebers inside each distribution
    if(resp2 && resp2.items) {

      console.log('generated list from current entities');      
      generateAllContactsList().then(list => {
        resp2.items.push(list)
        generateDistributionLists(resp2.items);
        done();
      });

    } else if(resp2) {
      $('#to-field-tree-container').css('height','36px');
      $('#loadingDots').hide();
      selectionInfo.textContent = i18n.t("js.compose.no-member");
      selectionInfo.style.left = '10px';
      selectedRecipientsId = [];
      done();
    } else {
      console.log('failed fetching distribution lists');
      done();
    }
  });
}

//Generates "ALL CONTACTS"
function generateAllContactsList() {
  return new Promise(async resolve => {
    while(!Array.isArray(allContacts)) {
      // Waiting for all contacts to be loaded
      await new Promise(res => setTimeout(res, 50));
    }
    const list = {
      distributionListId: '__all',
      distributionListName: i18n.t("js.compose.all-contacts"),
      distributionListMembers: allContacts.map(person => {
        return {
          email: person.properties.email || '',
          firstName: person.properties.firstName || '',
          lastName: person.properties.lastName || '',
          jobTitle: person.properties.jobTitle || '',
          id: `__all_${person.key.name}`,
          personId: person.key.name,          
        }
      })
    };
    resolve(list);
  });
}

function fetchDepartments(departments, done) {
	apicall('personapi', 'fetchDepartments', {}).then(function(resp){
		if(resp && resp.items){
      Array.prototype.push.apply(departments, resp.items);
		}
    done();
	}).catch(err => {
    console.error('failed to fetch departments');
    done();
  });
}

function fetchPersons(persons, done) {
  apicall('distributionapi','fetchBasicContactInfo',{}).then(async (resp) => {
		if(resp && resp.items) {
      Array.prototype.push.apply(persons, resp.items);
      allContacts = persons;
      casts = persons.filter(person => person.properties.subcategory == 'cast');
		}
    done();
	});
}

function changeSelectionInfoText(newText) {
	document.getElementById('selectionInfo').textContent = newText;
}

function generateDistributionLists(distributionLists) {

  console.log('generating distribution lists');

  //alphanumeric sort
  distributionLists.sort((a,b) => {
    try {
      let nbRegex = /^#?(\d+)/;
      let reg = /(ALL CONTACTS)/gi;   
      let aNbMatch = a.distributionListName.match(nbRegex);
      let bNbMatch = b.distributionListName.match(nbRegex);
      //Floating "All Contacts" to the top, makes sence to have it first for the user to see.            
      if(b.distributionListName.match(reg) != null){
        return 1;            

      }
      if(a.distributionListName.match(reg) != null){
        return -1;

      }
      if(aNbMatch == null && bNbMatch == null) {
        return a.distributionListName.localeCompare(b.distributionListName);
      } else {
        return aNbMatch == null
          ?1
          :(bNbMatch == null
            ?-1
            :(parseInt(aNbMatch[1]) - parseInt(bNbMatch[1])));
      }
    } catch(e) {
      console.error(e);
      return 0;
    }
  });

	let groupList = document.getElementById('distributionGroupList');
  let notifySelect = document.getElementById('distributionGroupNotify')

	for(let i = 0, list; list = distributionLists[i++];) {
		let validListId = generateDistrGroupItem(groupList, list, list.distributionListId,
      list.distributionListName, list.distributionListMembers.length, 'listItemBlueprint');
    generateGroupMemberList(groupList, validListId, list.distributionListMembers, 'listItemBlueprint');
    addListToNotifySelect(notifySelect, list);
	}

  $(notifySelect).trigger('chosen:updated');
  $('#loadingDots').hide();
}

function generateDepartmentList(departments, persons, done) {
  console.log('generating department table');


  let departmentMap = new Object();
  departments.forEach(dept => {
    if(typeof dept.key.name === 'string' && dept.key.name.trim() !== '') {
      departmentMap[dept.key.name] = dept.properties;
      departmentMap[dept.key.name].members = new Array();
    }
  });
  persons.forEach(person => {
    try {
      departmentMap[person.properties.departmentId].members.push({
        id: person.properties.departmentId + '_' + person.key.name,
        firstName: person.properties.firstName || '',
        lastName: person.properties.lastName || '',
        jobTitle: person.properties.jobTitle || '',
        email: person.properties.email
      });
    } catch(e) {
      console.error(e);
    }
  });

  let deptList = document.getElementById('departmentList');
  for(let i = 0, dept; dept = departments[i++];) {
    if(typeof departmentMap[dept.key.name] === 'object') {
      if(departmentMap[dept.key.name].members.length) {
        let validListId = generateDistrGroupItem(deptList, dept.properties, dept.key.name,
          dept.properties.departmentName.toUpperCase(),
          departmentMap[dept.key.name].members.length, 'deptListItemBlueprint');
        generateGroupMemberList(deptList, validListId, departmentMap[dept.key.name].members, 'deptListItemBlueprint');
      }
    } else {
      console.error('error adding department');
      console.error(dept);
    }
  }
  done();
}

function addListToNotifySelect(notifySelect, list) {
  let option = document.createElement('option');
  option.value = list.distributionListId;
  option.textContent = list.distributionListName;
  notifySelect.appendChild(option);
}

//generating a distributionGroupItem from the HTML blueprint
function generateDistrGroupItem(container, distrList, listId, listName,
  nbOfMembers, blueprintId) {

  if(listId !== listId.trim()) {
    console.error('list "' + listId + '" is not trimmed, which may prevent distribution to its members.');
  }

  let item = copyBlueprint(blueprintId);

  for(let attr in distrList) {
    if(attr !== 'distributionListMembers') {
      item.setAttribute(attr, distrList[attr]);
    }
  }

  item.id = Utility.getValidId(listId);

  for(let i = 0, child; child = item.children[i++];) {

    //setting a unique ID to all children
    let childId = item.id + '_' + child.tagName;
    while(document.getElementById(childId)) {
      childId = childId + '0';
    }
    child.id = childId;

    //handling labels and inputs' specific functionalities
    if(child.tagName === 'LABEL') {
      let memberCountSpan = document.createElement('span');
      memberCountSpan.id = item.id + '_countSpan';
      memberCountSpan.className = 'member-count';
      memberCountSpan.setAttribute('memberCount', 0);
      memberCountSpan.setAttribute('nbOfMembers', nbOfMembers);
      memberCountSpan.setAttribute('listId', listId);
      memberCountSpan.innerHTML = '(0/' + nbOfMembers + ')';
      memberCountSpan.title = 'click to edit';
      let caret = document.createElement('i');
      caret.className = 'fa fa-caret-right';
      caret.style.marginLeft = '3px';
      memberCountSpan.appendChild(caret);

      child.innerHTML = listName + '&nbsp;'
        + memberCountSpan.outerHTML
        + child.innerHTML;
      child.setAttribute('for', item.id + '_' + 'INPUT');
    } else if(child.tagName === 'INPUT') {
      child.value = childId;
      child.onchange = function(e) {
        let isInternalCall = this.hasAttribute('isInternalCall') &&
          this.getAttribute('isInternalCall') === 'true';

        if(!isInternalCall) {
          // $('li.distr-group-member[parentListId="'+item.id+'"]').each((idx, member) => {
          //   if($(item).hasClass(item.getAttribute('selectedClass'))
          //     && $(member).hasClass('selected') ||
          //     !$(item).hasClass(item.getAttribute('selectedClass'))
          //       && $(member).hasClass('unselected')) {
          //
          //     $('#'+member.id+' > input').attr('isInternalCall','true');
          //     $('#'+member.id+' > input').click();
          //   }
          // });
          toggleListMembers(item.id, !$(item).hasClass(item.getAttribute('selectedClass')));
          adjustIndeterminate(this, document.getElementById(item.id+'_countSpan'));
        }

        this.removeAttribute('isInternalCall');

        if($(item).hasClass(item.getAttribute('selectedClass'))) {
          $(item).removeClass(item.getAttribute('selectedClass'));
        } else {
          $(item).addClass(item.getAttribute('selectedClass'));
        }
      };
    }
  }

  let memberToggleClasses = ['member-count', 'fa-caret-right', 'fa-caret-down'];
  $(item).click(async function(e) {
    let isMemberToggleElem = false;
    for(let className of memberToggleClasses) {
      if(e.target.classList.contains(className)) {
        isMemberToggleElem = true;
        break;
      }
    }
    if(isMemberToggleElem) {
      e.preventDefault();
      let $listMembers = $('li.distr-group-member[parentListId="'+item.id+'"]');
      if($listMembers.length > 100) {
        $(item).css('cursor', 'wait');
        $(item).children().css('cursor', 'wait');
        await new Promise(resolve => setTimeout(resolve, 25));
      }
      $listMembers.slideToggle($listMembers.length > 30 ?0 :400);
      $(item).css('cursor', '');
      $(item).children().css('cursor', '');
      let $caret = $(e.target.tagName == 'I' ?e.target :$(e.target).find("i"));
      $caret.toggleClass('fa-caret-right');
      $caret.toggleClass('fa-caret-down');
    }
  });

  container.append(item);
  return item.id;
}

function toggleListMembers(parentListId, isChecked) {
  let $members = $('li.distr-group-member[parentListId="'+parentListId+'"]');
  $members.removeClass(isChecked ?'unselected' :'selected');
  $members.addClass(!isChecked ?'unselected' :'selected');
  $members.find('input').prop('checked', isChecked);
  let memberCountSpan = document.querySelector('#'+parentListId+'_countSpan');
  memberCountSpan.setAttribute('memberCount', isChecked ? memberCountSpan.getAttribute('nbOfMembers') :'0');
  updateMemberCount(memberCountSpan);
}

function generateGroupMemberList(container, parentListId, memberList, blueprintId) {
  memberList.sort((p1, p2) => {
    try {
      const numberP1 = p1.firstName.replace(/\D/g,'');
      const numberP2 = p2.firstName.replace(/\D/g,'');
      if(Number(numberP1) && Number(numberP2)) {
        return Number(numberP1) - Number(numberP2);
      } else {
        return p1.firstName.localeCompare(p2.firstName);
      }
    } catch (e1) {
      console.error(e1);
      return 0;
    }
  });
  for(let m = 0, member; member = memberList[m++];) {
    if(member.id !== member.id.trim()) {
      console.warn('member "' + member.id + '" is not trimmed, which may cause problems in future implementations.');
    }
    let item = copyBlueprint(blueprintId);
    member.id = Utility.getValidId(member.id);
    item.id = member.id;
    item.setAttribute('email', member.email || '');
    item.setAttribute('parentListId', parentListId);

    for(let i = 0, child; child = item.children[i++];) {

      //setting a unique ID to all children
      let childId = item.id + '_' + child.tagName;
      while(document.getElementById(childId)) {
        childId = childId + '0';
      }
      child.id = childId;

      //handling labels and inputs' specific functionalities
      if(child.tagName === 'LABEL') {

        let memberName = '';
        memberName += member.firstName ?member.firstName :'';
        memberName += member.lastName ?(' ' + member.lastName) :'';

        let memberNameSpan = document.createElement('span');
        memberNameSpan.className = 'list-member-name';
        memberNameSpan.textContent = memberName;

        child.title = member.email || 'no email';

        if(!memberName.trim()) { continue; }

        let jobTitlePrefix = '';
        if(typeof member.jobTitle == 'string'
            && member.jobTitle.trim().length > 0
            && !member.jobTitle.toLowerCase().includes('unknown')) {
          jobTitlePrefix = member.jobTitle.trim() + '&nbsp;&#8212;&nbsp;';
        }

        child.innerHTML = jobTitlePrefix + memberNameSpan.outerHTML + child.innerHTML;
        child.setAttribute('for', item.id + '_' + 'INPUT');

      } else if(child.tagName === 'INPUT') {

        child.value = childId;
        child.onchange = function() {
          let isInternalCall = this.hasAttribute('isInternalCall') && this.getAttribute('isInternalCall') === 'true';
          if(isInternalCall) {
            console.error('internal call detected for list member');
            return;
          }
          let memberCountSpan = document.getElementById(parentListId+'_countSpan');
          if($(item).hasClass('selected')) {
            $(item).addClass('unselected');
            $(item).removeClass('selected');
            memberCountSpan.setAttribute('memberCount', parseInt(memberCountSpan.getAttribute('memberCount'))-1);
            if(parseInt(memberCountSpan.getAttribute('memberCount')) ===  0
              && $('#'+parentListId).hasClass(item.getAttribute('selectedClass'))
              && !isInternalCall) {

              $('#'+parentListId+' > input').attr('isInternalCall', 'true');
              $('#'+parentListId+' > input').click();
            }
          } else {
            $(item).addClass('selected');
            $(item).removeClass('unselected');
            memberCountSpan.setAttribute('memberCount',
              parseInt(memberCountSpan.getAttribute('memberCount'))+1);
            if(parseInt(memberCountSpan.getAttribute('memberCount')) > 0
              && !$('#'+parentListId).hasClass(item.getAttribute('selectedClass'))
              && !isInternalCall) {

              $('#'+parentListId+' > input').attr('isInternalCall', 'true');
              $('#'+parentListId+' > input').click();
            }
          }
          if(!isInternalCall) {
            adjustIndeterminate($('#'+parentListId+' > input')[0], memberCountSpan);
          }
          updateMemberCount(memberCountSpan);
          this.removeAttribute('isInternalCall');
        }
      }
    }
    $(item).addClass('distr-group-member unselected');
    item.style.display = 'none';
    container.append(item);
  }
}

function adjustIndeterminate(parentCheckbox, memberCountSpan) {
  let memberCount = parseInt(memberCountSpan.getAttribute('memberCount'));
  let nbOfMembers = parseInt(memberCountSpan.getAttribute('nbOfMembers'));
  let checkborder = $(parentCheckbox).closest('li').find('div.checkborder')[0];

  if(memberCount === 0 || memberCount === nbOfMembers) {
    if($(checkborder).hasClass('indeterminate')) {
      $(checkborder).removeClass('indeterminate');
    }
    if($(parentCheckbox).hasClass('indeterminate')) {
      $(parentCheckbox).removeClass('indeterminate');
    }
  } else {
    if(!$(checkborder).hasClass('indeterminate')) {
      $(checkborder).addClass('indeterminate');
    }
    if(!$(parentCheckbox).hasClass('indeterminate')) {
      $(parentCheckbox).addClass('indeterminate');
    }
  }
}

function updateMemberCount(memberCountSpan) {
  memberCountSpan.innerHTML = memberCountSpan.innerHTML.replace(/\(\d+\//,
    '(' + memberCountSpan.getAttribute('memberCount') + '/');
}

function createLoadingDots() {
	let img = new Image(30,25);
	img.src = IMG_DIRECTORY + 'loading-dots.gif';
	img.style.paddingLeft = '8px';
	$('#innerLoadingDots').append(img);
}

  function addAttachmentsFromDocuments(attachmentList, done) {
    let nbOfAddedAttachments = 0;
    for(let attachmentId of attachmentList) {
      addAttachment(attachmentId, () => {
        if(++nbOfAddedAttachments == attachmentList.length) {
          r.deleteExtraParam('attachmentID');
          done();
        }
      });
    }
  }

  async function addAttachment(fileId, done) {
    let attachment = fileMap[fileId];

    if(typeof attachment === 'undefined') {
      console.debug('file is in an unopened folder')
      attachment = await getFileInfo(fileId);
    }

    if(typeof attachment === 'object' && (!attachment.size || isNaN(attachment.size))) {
      attachment.size = await getFileSize(attachment)
    }
    if(isValidFile(attachment)) {
      addUploadedFile(attachment);
      refreshFileBox(fileId);
      console.log('adding file to uploaded files 4 (documents)');
      console.log(attachment);
    } else {
      console.error('Invalid file submitted from documents page.');
      console.error(attachment);
    }

    done && done();
  }

  function getFileInfo(fileId) {
    return new Promise(resolve => {
      apicall('documentsapi','fetchDocumentById', {fileId: fileId}).then((resp) => {
        try {
          let obj = resp.properties;
          let fileSize = obj.size || obj.fileSize || '0';
          if(isNaN(fileSize)) { fileSize = '0' }
          if(obj.id && obj.fileName && obj.fileURL && obj.isWatermarked) {
            resolve({
              id: obj.id,
              fileName: obj.fileName,
              url: obj.fileURL,
              isWatermarked: obj.isWatermarked || '0',
              size: parseInt(fileSize)
            });
          } else {
            resolve(undefined);
          }
        } catch(e) {
          console.error(e);
          resolve(undefined);
        }
      }, err => resolve(undefined)).catch(err => resolve(undefined));
    });
  }

/* ----------------------------------------------- */
    function checkedGroups() {
		var selected = [];
    	$('input:checked').each(function() {
    	    selected.push($(this).val());
    	});
    	if (selected[0] == "on") {selected.shift();}
    	return selected;
    }
/*  ---------------------------------------------- */

function allFilesExist(validatedFiles) {
  return new Promise(async function(resolve) {
    let doAllFilesExist = true;
    if(Array.isArray(validatedFiles) && validatedFiles.length) {
      let onValidationComplete = cgToast(i18n.t("js.compose.attachments.validation"), { hiding: 'manual' });
      let invalidFiles = await validateFilesExistence(validatedFiles);
      onValidationComplete();
      doAllFilesExist = invalidFiles.length === 0;
      if(!doAllFilesExist) {
        console.debug('invalid files', invalidFiles);
        swal({
          title: i18n.t("js.compose.attachments.missing.title", {count:invalidFiles.length}),
          html: i18n.t("js.compose.attachments.missing.text", {count:invalidFiles.length}) 
            + `<b>${validatedFiles.filter(f => invalidFiles.includes(f.id)).map(f => f.fileName).join(', ')}</b>. `
            + i18n.t("js.compose.attachments.missing.upload", {count:invalidFiles.length}),
          type: 'warning'
        }).catch(swal.noop);
        hideSpinner();
        return;
      }
    }
    resolve(doAllFilesExist);
  });
}

function validateFilesExistence(files) {
  return new Promise(resolve => {
    let filesIdToValidate = files.map(f => f.id).join(',');
    if(filesIdToValidate) {
      apicall('documentsapi', 'validateFilesExistence', {}, { value: filesIdToValidate })
      .then(resp => {
        if(resp.hasOwnProperty('responseCode')) {
          if(typeof resp.responseMessage == 'string' && resp.responseMessage.trim().length > 0) {
            resolve(resp.responseMessage.split(','));
          } else {
            resolve([]);
          }
        } else {
          throw new Error('server error');
        }
      }).catch(() => {
        console.error('server error');
        resolve([]);
      });
    } else {
      resolve([]);
    }
  });
}

async function sendTestMessage() {
  if(!await attachmentsSizeOk(STATUS.READY)) { return; }
  swal({
    title: i18n.t("js.compose.test.title"),
    html: i18n.t("js.compose.test.text"),
    type: 'question',
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    confirmButtonColor: '#13C46A',
    cancelButtonColor: '#d33',
    confirmButtonText: i18n.t("button.confirm"),
    showCloseButton: true,
    allowOutsideClick: true
  }).then(async function () {

    showSpinner();

    var obj = new Object();

    obj.distributionListId = '-'; //ignored in backend
    obj.departmentId = '-'; //ignored in backend
    obj.distributionListNotify = '-'; //ignored in backend
    obj.extraemail = '-'; //ignored in backend

    obj.subject = document.getElementById("subject").value;
    if (obj.subject.trim().length==0) {obj.subject=i18n.t("js.compose.no-subject");}

    obj.watermark = atLeastOneWMFile();

    let messageContent = getContent('message');
    let signatureContent = getContent('signature');
    obj.message = messageContent + "<br/><br/>" + signatureContent;
    obj.unsignedMessage = messageContent;
    obj.signature = signatureContent;
    obj.replyToEmail = document.querySelector('#replyToContainer select').value;

    if (obj.message.trim().length==0) {obj.message="-";}
    obj.smsmessage=document.getElementById("smsmessage").value;
    if (obj.smsmessage.trim().length==0) {obj.smsmessage="-";}

    let validatedFiles;
    try {
      validatedFiles = validateFilesForBackend();
      obj.uploadedFilesJSON = JSON.stringify(validatedFiles);
    } catch(file) {
      console.error(file);
      swal({
        title: i18n.t("response.error"),
        text: i18n.t("js.compose.attachment.issue", {filename:(
          (typeof file.fileName === 'string' && file.fileName.trim().length > 0) ? file.fileName :"$t(js.afile)"
        )}),
        type: 'error',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK")
      });
      saveSystemActivity({
        action: 'send',
        params: findSystemActivityParams(file),
        message: 'User could not send email due to invalid file.'
      });
      hideSpinner();
      return;
    }

    obj.areAttachmentsLinks = document.getElementById('sendAsLinkChk').checked;
    obj.linkValidityTime = linkOptions.linkValidityTime;
    obj.linkAccessType = linkOptions.linkAccessType;
    obj.isdeeplink = linkOptions.isdeeplink;

    if(!await allFilesExist(validatedFiles)) { return }

    console.log('sending obj:');
    console.log(obj);

    saveSystemActivity({
      action: 'send',
      params: findSystemActivityParams(obj),
      message: 'User sent a test email.'
    });

    apicall('distributionapi', 'sendTestMessage', obj).then(function(resp){

      hideSpinner();

      if(resp && resp.responseCode && resp.responseCode === '0') {        
        swal({
          title: i18n.t("response.success"),
          html: i18n.t("js.compose.test.success"),
          type: 'success',
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("OK"),          
          allowOutsideClick: true
        });
      }
      else if(resp && resp.responseCode && resp.responseCode === '-1') {

        swal({
          text: i18n.t("js.compose.test.sent.no-phone"),
          type: 'warning',
          confirmButtonColor: '#13C46A',
          confirmButtonText: i18n.t("js.compose.phone.add"),
          cancelButtonText: i18n.t("close"),
          showCancelButton: true,
          showCloseButton: true
        }).then(function() {
          // saveDraft(false);
          if(resp.entityId) {
            // TODO don't set sidesStandAlone to false by default unless this project
            // is dropped for good
            r.loadPage('contact', {
              personId: resp.entityId,
              personEntity: resp.entity.properties,
              isSidesStandAlone: false,
              reloadOnClose: false,
              focusOnLoad: 'distribution_mobile'
            });
          } else {
            r.loadPage('crew');
          }
        }).catch(swal.noop);

      } else {
        showTestMsgFailureAlert();
      }

    }).catch(showTestMsgFailureAlert);

    function showTestMsgFailureAlert() {
      swal({
        text: i18n.t("js.compose.test.failed"),
        type: 'error',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("button.gotit")
      }).catch(swal.noop);
    }

  }).catch(swal.noop);

}

function confirmMessage(status) {
  return new Promise((resolve, reject) => {
    if(status === STATUS.READY) {
      let skipGroupWarning = findAllAffectedDistGroups().length === 0;
      let swalOpened = ($('#docBody').hasClass('swal2-shown') ? false : true);
      swal({
        title: i18n.t("js.utils.confirm"),
        html: (skipGroupWarning ?"":i18n.t("js.compose.confirm.text", {groups:findAllAffectedDistGroupNames()})),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#13C46A',
        cancelButtonColor: '#d33',
        cancelButtonText: i18n.t("button.cancel"),
        confirmButtonText: i18n.t("js.compose.send-it"),
        showCloseButton: true,
        animation: swalOpened,
        onClose: () =>{
          $('.swal2-popup').removeClass('swal2-noanimation');
          $('.swal2-popup').addClass('swal2-hide');
        }
      }).then(resolve, reject);
    } else {
      if(status === STATUS.DRAFT) {
        resolve();
      } else {
        console.error('cannot save message, invalid status')
        reject();
      }
    }
  });
}

function emptyFieldsOk(status) {
  return new Promise((resolve, reject) => {
    if(status === STATUS.DRAFT) {
      resolve(true);
      return;
    }
    let hasSubjectLine = document.getElementById('subject').value.trim() !== '';
    let hasAttachments = uploadedFiles.length !== 0;
    if(hasSubjectLine && hasAttachments) {
      resolve(true);
      return;
    }
    //If a swal is already opened, then no animation for the warning
    let swalOpened = ($('#docBody').hasClass('swal2-shown') ? false : true);
    swal({
      html: '<div class="empty-field-text">'
       + (!hasSubjectLine ? i18n.t("js.compose.no.subject"):'')
       + (!hasAttachments ? i18n.t("js.compose.no.attachment"):'')
       + '</div>',
      type: 'warning',
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("js.compose.proceed"),
      cancelButtonText: i18n.t("button.cancel"),
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: swalOpened,//Allow outside click if the user hasnt previosuly chosen a schedule send
      animation: swalOpened,
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
      }      
    }).then(() => resolve(true), () => resolve(false));
  });
}

function attachmentsSizeOk(status) {
  return new Promise((resolve, reject) => {
    if(status === STATUS.DRAFT || findAttachmentsSize() <= MAX_ATTACHMENTS_SIZE) {
      resolve(true);
      return;
    }
    let swalOpened = ($('#docBody').hasClass('swal2-shown') ? false : true);
    swal({
      title: i18n.t("js.compose.attachments.fat.title"),
      text: i18n.t("js.compose.attachments.fat.text", {MAX_ATTACHMENTS_SIZE}),
      type: 'warning',
      confirmButtonColor: '#13C46A',
      showConfirmButton: true,
      confirmButtonText: i18n.t("OK"),
      showCancelButton: false,
      allowOutsideClick: false,
      animation: swalOpened,
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
      }      
    });
    resolve(false);
  });
}

/**
 * Determines whether or not all inserted email variables, if any, can 
 * be used on all selected recipients. Variables cannot be used on recipients 
 * that only have an email address and are not mapped to Croogloo contacts.
 * These recipients would see empty Strings instead of personalized content.
 */
function emailVariablesOk() {
  let unknownEmails = getUnknownRecipientsEmails() || [];
  let areEmailVariables = /<span[^>]*personalized-email-content[^>]*>\s*\{(firstName|lastName)\}\s*<\/span>/.test(getContent('message'));

  return new Promise(resolve => {
    if (areEmailVariables && unknownEmails.length) {
      let swalOpened = ($('#docBody').hasClass('swal2-shown') ? false : true);
      swal({
        title: i18n.t("js.warning"),
        html: i18n.t("js.compose.variables.unknowns", {count:unknownEmails.length, emails:unknownEmails.join(', ')}),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("js.compose.send-anyway"),
        showCloseButton: true,
        animation: swalOpened,
        onClose: () =>{
          $('.swal2-popup').removeClass('swal2-noanimation');
          $('.swal2-popup').addClass('swal2-hide');
        }      
      }).then(() => resolve(true), () => resolve(false));
    } else {
      resolve(true);
    }
  });
}


function isRecipientsOk(status) {
  return new Promise((resolve, reject) => {
    if(status === STATUS.DRAFT) {
      resolve(true);
      return;
    }
    let allSelectedEmailsAndNames = getExtraEmailsAndNames();
    let invalidEmailsFormatted = [];
    let invalidNamesFormatted = [];
    getAllSelectedRecipients(allSelectedEmailsAndNames);

    for (let key in allSelectedEmailsAndNames) {
      let formattedString = allSelectedEmailsAndNames[key] + " (" + key + ")"
      if (!Utility.isValidEmailAddress(key)) {
        invalidEmailsFormatted.push(formattedString);
      }
      if (allSelectedEmailsAndNames[key] && !Utility.isValidName(allSelectedEmailsAndNames[key])) {
        // invalidNamesFormatted.push(formattedString); // NOTE: invalid names should be handled in the back-end
      }
    }

    if (invalidEmailsFormatted.length > 0 || invalidNamesFormatted.length > 0) {

      let swalOpened = ($('#docBody').hasClass('swal2-shown') ? false : true);     
      swal({      
        title: i18n.t("js.compose.some-not-gettin"),
        html: buildInvalidEmailAndNameHTML(invalidEmailsFormatted, invalidNamesFormatted),
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("js.compose.proceed.anyway"),
        showCancelButton: true,
        cancelButtonText: i18n.t("button.cancel"),
        showLoaderOnConfirm: true,
        useRejections: true, //important for swal2 v7.1.2
        expectRejections: true,
        showCloseButton: true,        
        type: 'warning',
        animation: swalOpened,
        onClose: () =>{
          $('.swal2-popup').removeClass('swal2-noanimation');
          $('.swal2-popup').addClass('swal2-hide');
        }        
      }).then(function() {
        console.warn("Invalid names and Emails detected but proceeded anyways");
        resolve(true);
      }, function(dismiss) {
        resolve(false);
      }).catch(swal.noop);
    } else {
      resolve(true);
    }
  });
}

function excludeUnavailableContacts(sendDate) {
  return new Promise(async function(resolve, reject) {
    let formattedDate = sendDate.getFullYear()+"-"
      +String(sendDate.getMonth()+1).padStart(2, '0')+"-"
      +String(sendDate.getDate()).padStart(2, '0');
    console.debug(formattedDate)
    let recipientsEmail = getAllRecipientsPrimaryEmail()
    let excludedContacts = await new Promise(resolve => {
      let tmp = new Object();
      tmp.personIds = allContacts.filter(contact => recipientsEmail.includes(contact.properties.email)).map(contact=>contact.key.name);
      tmp.sendDate = formattedDate;
      
      showSpinner();
      apicall('personapi', 'fetchExclusionsByInactivity', tmp).then((resp) => {
        if (resp && resp.items) {
          console.debug(resp.items);
          let inactive = [];
          inactive.push(...resp.items.filter(item => typeof item.properties.email == 'string'));
          console.debug(inactive);
          resolve(inactive);
        } else {
          resolve([]);
        }
      }, (err) => {
        console.error(err);
        resolve([]);
      }).catch(err => {
        console.error(err);
        resolve([]);
      });
    });
    hideSpinner();

    if(excludedContacts === null || !Array.isArray(excludedContacts) || !excludedContacts.length) {
      resolve(false);
      return;
    }

    swal({
      title: i18n.t("js.compose.inactive.title"),
      type: 'question',
      html: i18n.t("js.compose.inactive.text", {
        count: excludedContacts.length,
        titles: excludedContacts.map(item => getContactTitle(item)).join('</b>, <b>'),
        sendDate
      })
      +'<span class="swal-close-btn"><i class="fa fa-close"></i></span>',
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("yes"),
      cancelButtonText: i18n.t("no"),
      showCancelButton: true,
      useRejections: true, //important for swal2 v7.1.2
      expectRejections: true,
    }).then(function() {
      resolve([]);
    }, function(dismiss) {
      resolve(excludedContacts.map(item => item.properties.email))
    }).catch(err => {
      //COMEHERE : toast warning and exclude by default instead?
      resolve([]);
    });

    function getContactTitle(item) {
      let firstName = item.properties.firstName;
      let lastName = item.properties.lastName;
      let title = '';
      for(let namePart of [firstName, lastName]) {
        if(typeof namePart == 'string' && namePart.trim().length) {
          title += namePart + ' ';
        }
      }
      return title.trim() || item.properties.email;
    }
  });
}

var includeAgent = false;
function shouldIncludeAgent(status, departmentId) {
  return new Promise(async function(resolve, reject) {
    if(status === STATUS.DRAFT) {
      resolve(false);
      return;
    }
    // reset the flag
    includeAgent = false;
    let isCastDepartmentIncluded = departmentId.split(',').includes('DEPARTMENT_CAST');
    let allRecipientsPrimaryEmails = getAllRecipientsPrimaryEmail();
    let recipientCastEmails = casts.map(cast => cast.properties.email)
      .filter(email => allRecipientsPrimaryEmails.includes(email));
    console.debug('recipientCastEmails: ', recipientCastEmails);
    if (!isCastDepartmentIncluded && !recipientCastEmails.length) {
      resolve(false);
    } else {
      let castIds = [];
      if (isCastDepartmentIncluded) {
        castIds.push(...allContacts.filter(contact => contact.properties.departmentId == 'DEPARTMENT_CAST')
          .map(contact => contact.key.name));
      }
      if (recipientCastEmails.length) {
        for(let castId of casts
            .filter(contact => recipientCastEmails.includes(contact.properties.email))
            .map(contact => contact.key.name)) {
          if(!castIds.includes(castId)) {
            castIds.push(castId);
          }
        }
      }

      let potentialAgentsToInclude = await new Promise(resolve => {
        let potentialAgents = [];
        let tmp = new Object();
        tmp.personIds = castIds.join(",");
        tmp.personSubcategory = 'cast';
        tmp.targetPersonSubcategory = 'agent';
        showSpinner();
        apicall('personapi', 'fetchContactsFromAssociation', tmp).then((resp) => {
          if (resp && resp.items) {
            console.debug(resp.items);
            potentialAgents.push(...resp.items
              .filter(item => typeof item.properties.email == 'string'
                && !allRecipientsPrimaryEmails.includes(item.properties.email)));
            resolve(potentialAgents);
          } else {
            resolve(null);
          }
        }, (err) => {
          console.error(err);
          resolve(null);
        }).catch(err => {
          console.error(err);
          resolve(null);
        });
      });
      hideSpinner();

      if(potentialAgentsToInclude === null || !Array.isArray(potentialAgentsToInclude)) {
        croogloo_auth.sendRedAlert('Failed to find contact relationships before sending message.');
        resolve(false);
        return;
      } else if(!potentialAgentsToInclude.length) {
        resolve(false);
        return;
      }
      let swalOpened = ($('#docBody').hasClass('swal2-shown') ? false : true);
      swal({
        title: i18n.t("js.compose.agents.title"),
        type: 'question',
        html: i18n.t("js.compose.agents.text", {
          count: potentialAgentsToInclude.length,
          titles: potentialAgentsToInclude.map(item => getContactTitle(item)).join('</b>, <b>')
        }),       
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("yes"),
        cancelButtonText: i18n.t("no"),
        showCancelButton: true,
        useRejections: true, //important for swal2 v7.1.2
        showCloseButton: true,
        expectRejections: true,
        animation: swalOpened,
      }).then(function() {
        includeAgent = true;
        let agentsToIncludes = potentialAgentsToInclude
          .map(potentialAgent => {
            return {
              primaryEmail: potentialAgent.properties.email,
              selectedEmail: null
            }
          });
        console.debug('agents to include: ', agentsToIncludes);
        resolve(JSON.stringify(agentsToIncludes));
      }, function(dismiss) {
        resolve(false);
      }).catch(err => {
        resolve(false);
      });

      function getContactTitle(item) {
        let firstName = item.properties.firstName;
        let lastName = item.properties.lastName;
        let title = '';
        for(let namePart of [firstName, lastName]) {
          if(typeof namePart == 'string' && namePart.trim().length) {
            title += namePart + ' ';
          }
        }
        return title.trim() || item.properties.email;
      }
    }
  });
}

/**
 * This method opens up the swal that double checks the content of the email (subject and body) then opens the confirmMessage swal 
 * then if confirmed, it will call the backend API to start the process of sending the email via queues in the backend: distributionapi.sendToDistributionList
 * 
 * {@link confirmMessage}
 * @param {String} status 
 * @param {boolean} autoDraft 
 * @param {Date|undefined} sentDate 
 * @returns undefined
 */
async function sendMessage(status, autoDraft = false, sentDate = undefined) {
  let swalOpened = ($('#docBody').hasClass('swal2-shown') ? false : true);
  if(findAllAffectedDistGroups().length === 0
      && getPrimaryExtraEmails().length === 0 && status === STATUS.READY) {
    swal({
      title: i18n.t("js.compose.no.recipient"),
      type: 'warning',
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("button.gotit"),
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: swalOpened,
      animation: swalOpened,
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
      }      
    });
    return;
  }

  if(!autoDraft) {
    // must not be used for auto-drafts
    if(!await isRecipientsOk(status)) { return; }
    if(!await emptyFieldsOk(status)) { return; }
    if(!await attachmentsSizeOk(status)) { return; }
    if(!await emailVariablesOk()) { return; }
  }

  let departmentId = fetchCompleteGroups('li.dept-list-item') || '-';
  let extraemail = getPartialDistroLists(getPrimaryExtraEmails());
  extraemail = extraemail.trim().length==0 ?'-' :extraemail;

  let includedAgentsJSON = autoDraft
    ? JSON.stringify([])
    : (await shouldIncludeAgent(status, departmentId) || JSON.stringify([]));

  let excludedContactsEmails = (STATUS.DRAFT === status) ? ([]) 
    : (await excludeUnavailableContacts(sentDate? new Date(sentDate) : new Date()) || []);

  myDropZone2.processQueue();

  confirmMessage(status).then(async function () {

    if(!autoDraft) {
      showSpinner();
    }

    var obj = new Object();
    obj.tags = selectedTags.join(" ");
    obj.includeAgent = includeAgent;
    obj.timeSaved = sentDate? new Date(sentDate).valueOf() : new Date().valueOf();

    obj.distributionListNotify = '-';

    obj.distributionListId = fetchCompleteGroups('li.distr-group-list-item') || '-';
    obj.departmentId = departmentId;

    obj.subject = document.getElementById("subject").value;
    if (obj.subject.trim().length==0) {obj.subject=i18n.t("js.compose.no-subject");}

    obj.watermark = atLeastOneWMFile();

    let messageContent = getContent('message');
    let signatureContent = getContent('signature');
    obj.message = messageContent + "<br/><br/>" + signatureContent;
    obj.unsignedMessage = messageContent;
    obj.signature = signatureContent;

    if (obj.message.trim().length==0) {obj.message="-";}

    var tmp = new Object();

    obj.extraEmailsJSON = getExtraEmailsJSON();
    obj.includedAgentsJSON = includedAgentsJSON;
    obj.excludedContactsEmails = excludedContactsEmails.join(',');//COMEHERE_
    obj.replyToEmail = document.querySelector('#replyToContainer select').value;

    obj.extraemail = extraemail;
    obj.extraEmailsForSelect = getPrimaryExtraEmails().join(',');
    obj.extraEmailsForSelect = obj.extraEmailsForSelect.trim().length == 0
      ?'-' :obj.extraEmailsForSelect;

    obj.extraListMembersInputVal = getPartialDistroListInputs();
    obj.extraListMembersInputVal = obj.extraListMembersInputVal.trim().length == 0
      ?'-' :obj.extraListMembersInputVal;

    obj.smsmessage=document.getElementById("smsmessage").value;
    if (obj.smsmessage.trim().length==0) {obj.smsmessage="-";}

    let validatedFiles;
    try {
      validatedFiles = validateFilesForBackend();
      obj.uploadedFilesJSON = JSON.stringify(validatedFiles);
    } catch(file) {
      console.error(file);
      swal({
        title: i18n.t("response.error"),
        text: i18n.t("js.compose.attachment.issue", {filename: (
          (typeof file.fileName === 'string' && file.fileName.trim().length > 0)?file.fileName :"$t(js.afile)"
          )}),
        type: 'error',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK")
      });
      saveSystemActivity({
        action: 'send',
        params: findSystemActivityParams(file),
        message: 'User could not send email due to invalid file.'
      });
      hideSpinner();
      return;
    }

    if(!autoDraft && status !== STATUS.DRAFT) {
      if(!await allFilesExist(validatedFiles)) { return }
    }

    obj.areAttachmentsLinks = document.getElementById('sendAsLinkChk').checked;
    obj.linkValidityTime = linkOptions.linkValidityTime;
    obj.linkAccessType = linkOptions.linkAccessType;
    obj.isdeeplink = linkOptions.isdeeplink;

    obj.CGSESSIONID = getCGSessionID();

    obj.emailType = 0;
    obj.status = status;
    obj.id = msgId;

    let hideToast = autoDraft ?null
      :cgToast(i18n.t(status === STATUS.DRAFT ? "js.compose.saving.draft":"js.compose.sending.message"), {
        hiding: 'manual'
      });

    saveSystemActivity({
      action: 'send',
      params: findSystemActivityParams({}), // params are logged at api call level
      message: 'User ' + (autoDraft?'automatically ':'') + (status === STATUS.DRAFT ?'saved a draft.':'sent a message.')
    });

    console.log('sending:');
    console.log(obj);

    lastSaveTime = new Date().getTime();

    let requestContext = (typeof window.croogloocurrentpage == 'object'
      && typeof croogloocurrentpage.securityPageName == 'string')
        ?croogloocurrentpage.securityPageName :null;

    apicall('distributionapi', 'sendToDistributionList', {}, obj, false).then(function (resp) {

      // msgId will also be resetted in the "clear()" function for status = READY
      msgId = (obj.status === STATUS.READY || !resp.entityId) ?'-' :resp.entityId;
      console.debug('msg id is now: ' + msgId);

      if(!autoDraft) {
        // Request context check. This is normally done by api.js, but it's done
        // manually here to enable the callback for auto drafts.
        if(requestContext !== null && requestContext !== croogloocurrentpage.securityPageName) {
          console.debug('request context changed, ignoring callback');
          return;
        }

        hideToast();
        hideSpinner();

        if(status === STATUS.READY) {
          let unknownEmails = getUnknownRecipientsEmails() || [];
          let addContactBtnId = Utility.uniqueID();
          console.debug('unknown emails: ');
          console.debug(unknownEmails);
          swal({
            title: i18n.t("response.success"),
            html: i18n.t("js.compose.sending.shortly")
              + (unknownEmails.length ?
                i18n.t("js.compose.sending.with-unknown", {
                  count: unknownEmails.length,
                  emails: unknownEmails.join(', '),
                  addContactBtnId: addContactBtnId
                }):''),
            type: 'success',
            confirmButtonColor: '#13C46A',
            showCloseButton: true,
            confirmButtonText: i18n.t(unknownEmails.length ? "close":"OK"),
            allowOutsideClick: true,
            onOpen: function() {
              if(unknownEmails.length) {
                document.getElementById(addContactBtnId).onclick = function() {
                  r.loadPage('contact', { showCachedContacts: true });
                  swal.clickConfirm();
                }
              }
            }
          }).catch(swal.noop);
          clear();
          refreshFileBox();
        } else {
          cgToast(i18n.t("js.compose.draft.saved.at")+' <span style="color:lightblue">'+ Date().match(/(\d+:\d+)(:\d+)/)[1] + '</span>');
        }
      } else {
        cgToast(i18n.t("js.compose.draft.saved.auto")+' <span style="color:lightblue">'+ Date().match(/(\d+:\d+)(:\d+)/)[1] + '</span>', {
          duration: 5000,
          containerID: 'docBody',
          className: 'body-attached'
        });
      }
    }, rej => {
      throw rej;
    }).catch(err => {
      alertFailure();
      console.error(err);
    });

    function alertFailure() {
      hideToast();
      hideSpinner();
      swal({
        title: i18n.t("redirect.reason.server"),
        html: i18n.t("js.compose.failure.alert"),
        type: 'error',
        confirmButtonColor: '#13C46A',
        confirmButtonText: i18n.t("OK")
      }).catch(swal.noop);
    }
  }, function() {
    if(status === STATUS.READY) {
      console.log('message sending was cancelled')
    } else if(status === STATUS.DRAFT) {
      console.log('draft was not saved');
    }
  });
}

function buildInvalidEmailAndNameHTML(invalidEmailsFormatted, invalidNamesFormatted) {
  $('#invalidEmailAndNamesContent').remove();
  let container = document.createElement('div');
  container.id = "invalidEmailAndNamesContent";
  if (invalidEmailsFormatted.length > 0) {
    let title = document.createElement('div');
    title.innerHTML = `<b>${i18n.t("js.compose.invalid.email")}</b>`;
    title.style = "padding-top: 15px; padding-bottom: 15px; color:red;";
    container.appendChild(title);
    for (let i in invalidEmailsFormatted) {
      let entry = document.createElement('div');
      entry.innerHTML = invalidEmailsFormatted[i];
      container.appendChild(entry);
    }
  }
  if (invalidNamesFormatted.length > 0) {
    let title = document.createElement('div');
    title.innerHTML = `<b>${i18n.t("js.compose.invalid.name")}</b>`;
    title.style = "padding-top: 15px; padding-bottom: 15px; color:red;";
    container.appendChild(title);
    for (let i in invalidNamesFormatted) {
      let entry = document.createElement('div');
      entry.innerHTML = invalidNamesFormatted[i];
      container.appendChild(entry);
    }
  }
  return container.outerHTML;
}

// Back-up solution for sendToDistributionList. Fully functional as of
// 06/12/2018 (MM/DD/YYYY), but not currently in use
function queueMessage(msg) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      url: croogloo_auth.crooglooauth.urlEndpoint + 'MessageQueueingService',
      type: "POST",
      beforeSend: function(req) {
        req.setRequestHeader('Authorization', croogloo_auth.token);
      },
      dataType: "json",
      data: {
        token: croogloo_auth.usertoken,
        tenantId: croogloo_auth.tenantId,
        message: JSON.stringify(msg)
      },
      async: true,
      success: function(resp) {
        console.debug('queueMessage resp:');
        console.debug(resp);
        if(resp === '-1') {
          reject(resp);
        } else {
          resolve(resp);
        }
      },
      error: function(jqXHR, textStatus, err) {
        console.error('jqXHR/textStatus/err');
        console.error(jqXHR);
        console.error(textStatus);
        console.error(err);
        reject(err);
      }
    });
  });
}

function validateFilesForBackend() {
  if(!uploadedFiles) { return []; }
  let validatedFiles = new Array();
  uploadedFiles.forEach(file => {
    if(isValidFile(file)) {
      validatedFiles.push(file);
      if(typeof file.size !== 'number') {
        console.error('Missing file size. It\'s not mandatory yet but should always be present.');
      }
    } else {
      file.error = 'Cannot send file. Missing or invalid properties.';
      throw file;
    }
  });
  if(uploadedFiles.length > 0 && uploadedFiles.length === validatedFiles) {
    console.log('ALL ATTACHMENTS PROCESSED SUCCESSFULLY');
  }
  return validatedFiles;
}

function isValidFile(file) {
  if(typeof file === 'object' && file.id && file.url && file.fileName && /0|1/.exec(file.isWatermarked)) {
    return true;
  }
  return false;
}

function atLeastOneWMFile() {
  let foundWmFile = false;
  for(let i = 0; i < uploadedFiles.length; i++) {
    if(uploadedFiles[i].isWatermarked == '1') {
      foundWmFile = true;
      break;
    }
  }
  return foundWmFile + '';
}

function findAllAffectedDistGroupNames() {
	let allAffectedDistGroupNames = [];
  ['li.distr-group-list-item', 'li.dept-list-item'].forEach(itemClass => {
    $(itemClass).find('span.member-count').each((idx, elem) => {
      if(parseInt(elem.getAttribute('memberCount')) > 0) {
        let parentLi = $(elem).closest('li')[0];
        if(parentLi.getAttribute('distributionListName')) {
          allAffectedDistGroupNames.push(parentLi.getAttribute('distributionListName'));
        } else if(parentLi.getAttribute('departmentName')) {
          allAffectedDistGroupNames.push(parentLi.getAttribute('departmentName'));
        } else {
          console.error('parent LI has neither distributionListName nor departmentName attribute');
          console.error(parentLi);
        }
      }
    });
  });
	return allAffectedDistGroupNames.join(', ');
}

function findAllAffectedDistGroups() {
  let affectedGroups = [];

  ['li.distr-group-list-item', 'li.dept-list-item'].forEach(itemClass => {
    $(itemClass).find('span.member-count').each((idx, elem) => {
      if(parseInt(elem.getAttribute('memberCount')) > 0) {
        affectedGroups.push(elem.getAttribute('listId'));
      }
    });
  });
  return affectedGroups;
}

function fetchCompleteGroups(listItemClass) {
  let completeGroups = [];
  $(listItemClass).find('span.member-count').each((idx, elem) => {
    if(elem.getAttribute('memberCount') == elem.getAttribute('nbOfMembers')) {
      console.log('adding completed list: ' + elem.getAttribute('listId'));
      completeGroups.push(elem.getAttribute('listId'));
    }
  });
  return completeGroups.join(',');
}

function formatSelectedGroups(listItemClass) {
  let selectedGroups = [];
  $(listItemClass).find('span.member-count').each((idx, elem) => {
    if(parseInt(elem.getAttribute('memberCount')) > 0) { // == elem.getAttribute('nbOfMembers')
      selectedGroups.push($(elem).closest('label').text().trim());
    }
  });
  return selectedGroups;
}

function getPartialDistroListInputs(listItemClass) {
  let extraValuesArr = new Array();

  ['li.distr-group-list-item', 'li.dept-list-item'].forEach(itemClass => {
    $(itemClass).find('span.member-count').each((idx, elem) => {
      let memberCount = parseInt(elem.getAttribute('memberCount'));
      let nbOfMembers = parseInt(elem.getAttribute('nbOfMembers'))
      if(memberCount > 0 && memberCount < nbOfMembers) {
        let parentListId = $(elem).closest(itemClass)[0].id;
        $('li.distr-group-member[parentListId="'+parentListId+'"]').each((idx, member) => {
          if($(member).hasClass('selected')) {
            extraValuesArr.push($(member).find('input')[0].getAttribute('value'));
          }
        });
      }
    });
  });

  return extraValuesArr.join(',');
}

function getExtraEmailsJSON() {
  let extraEmails = [];
  $("#extraEmailSelect option").each(function () {
    if(this.selected) {
      extraEmails.push({
        primaryEmail: this.getAttribute('primaryEmail'),
        selectedEmail: this.value
      });
    }
  });
  fetchPartialListEmails().forEach(email => {
    extraEmails.push({
      primaryEmail: email,
      selectedEmail: null
    });
  });
  return JSON.stringify(extraEmails);
}

function getUnknownRecipientsEmails() {
  let unknownEmails = new Array();
  $("#extraEmailSelect option:selected").each(function () {
    try {
      if(this.value === this.textContent && this.value === this.getAttribute('primaryEmail') &&
          document.querySelectorAll('#extraEmailSelect option[value="' + this.value + '"]').length === 1) {
        // to make sure a contact with this email was not added since the draft was last saved
        unknownEmails.push(this.value);
      }
    } catch(e) {
      console.error(e);
    }
  });
  return unknownEmails;
}

function getPartialDistroLists(extraValues) {
  //handled in backend as well, could be removed
  extraValues = extraValues.join(',').replace(/\s|<|>/g, ',').split(',');

  let extraValuesArr = new Array();
	for(let i = 0 ; i < extraValues.length ; i++) {
		let email = extraValues[i];
		if(!email) {
      continue;
    }
		extraValuesArr.push(email);
	}

  return extraValuesArr.concat(fetchPartialListEmails()).join(',');
}

function fetchPartialListEmails() {
  let emailArr = [];
  ['li.distr-group-list-item', 'li.dept-list-item'].forEach(itemClass => {
    $(itemClass).find('span.member-count').each((idx, elem) => {
      let memberCount = parseInt(elem.getAttribute('memberCount'));
      let nbOfMembers = parseInt(elem.getAttribute('nbOfMembers'))
      if(memberCount > 0 && memberCount < nbOfMembers) {
        let parentListId = $(elem).closest(itemClass)[0].id;
        $('li.distr-group-member[parentListId="'+parentListId+'"]').each((idx, member) => {
          if($(member).hasClass('selected')) {
            emailArr.push(member.getAttribute('email'));
          }
        });
      }
    });
  });
  return emailArr;
}

function getAllSelectedRecipients(emailAndNames) {
  [
    'li.distr-group-list-item.distr-group-member.selected',
    'li.dept-list-item.distr-group-member.selected'
  ].forEach(itemSelector => {
    for(let item of document.querySelectorAll(itemSelector)) {
      try {
        let selectedEmail = item.getAttribute('email');
        if(selectedEmail) {
          emailAndNames[selectedEmail] = item.querySelector('span.list-member-name').textContent;
        } else {
          console.warn('no email attribute for ' + item.id);
        }
      } catch(e) {
        console.error(e);
      }
    }
  });
}

function getAllRecipientsPrimaryEmail() {
  let allSelectedEmails = getExtraEmailsAndNames();
  getAllSelectedRecipients(allSelectedEmails);
  return Object.keys(allSelectedEmails);
}

function compareDistrList(a,b) {
	a = a.split('~',2)[1].split('~')[0];
	b = b.split('~',2)[1].split('~')[0];

	return a < b ?-1 :a > b ?1 :0;
}

function resetAllGroupsAndMembers() {
	for(let listId in allGroupsAndMembers) {
		allGroupsAndMembers[listId].emails = [];
	}
}

function areAllFieldsEmpty() {
  return !getContent('message') &&
    !document.getElementById("subject").value &&
    !document.getElementById("smsmessage").value &&
    !findAllAffectedDistGroups().length &&
    !getPrimaryExtraEmails().length &&
    (!uploadedFiles || !uploadedFiles.length) &&
    !getGroupsToNotify().length;
}

function saveDraft(isUserAction)
{
  if(areAllFieldsEmpty()) {
    if(isUserAction) {
      cgToast(i18n.t("js.compose.nothing-save"));
    }
  } else {
    if(!isUserAction) {
      if(msgId !== '-' && !hasLastDraftAutoSaved &&
          new Date().getTime() - lastSaveTime < 90000) {
        console.log('prevented auto save - last message was saved, sent or restored less than a minute and a half ago')
        return;
      }
      if(!hasLastDraftAutoSaved) {
        console.debug('resetting msg id');
        msgId = '-'; // so automatic saves don't overwrite existing drafts
        hasLastDraftAutoSaved = true;
      }
    } else {
      hasLastDraftAutoSaved = false
    }
    console.debug('called saveDraft');
    sendMessage(STATUS.DRAFT, !isUserAction);
  }
}

function askToClear() {
  if(areAllFieldsEmpty()) {
    clear();
    return;
  }
  swal({
		title: i18n.t("js.compose.message.clear.title"),
		text: i18n.t("js.compose.message.clear.text"),
		type: 'question',
		showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
		confirmButtonColor: '#13C46A',
		confirmButtonText: i18n.t("button.confirm"),
    showCloseButton: true,
    allowOutsideClick: true
	}).then(function() {
		clear();
	}).catch(swal.noop);
}

function clear() {
  msgId = '-';

  setContent('message', '');
  document.getElementById("subject").value = "";

  $("#extraEmailSelect option").each(function () {
    this.selected = false;
  });
  $("#extraEmailSelect").trigger('chosen:updated');

  $("#distributionTemplates option").each(function () {
    this.selected = false;
  });
  $("#distributionTemplates").trigger('chosen:updated');
  document.getElementById('templateOptionsBtn').style.display = 'none';
  document.getElementById('saveTemplateBtn').style.display = 'block';

  $("#distributionGroupNotify option").each(function () {
    this.selected = false;
  });
  $("#distributionGroupNotify").trigger('chosen:updated');

  ['li.distr-group-list-item', 'li.dept-list-item'].forEach(itemClass => {
    $(itemClass).each(function(idx, item) {
      if($(item).hasClass(item.getAttribute('selectedClass'))) {
        $('#'+item.id+' > input').click();
      }
    });
  });

	setContent('message', '');
	atLeastOneWatermarkedFile = false;
	addedFileList = [];
	uploadInformationValue = [];
  uploadedFiles = new Array();

	document.getElementById("smsmessage").value = "";

	cachedDistributionGroupNotify="";
	selectedRecipientsId = [];

  updateRecipientsInput();

	myDropZone2.removeAllFiles(true);
  removeAllFiles();
  document.getElementById('sendAsLinkChk').checked = false;
  linkOptions = {
    linkValidityTime: LinkOptions.DEFAULT_VALIDITY,
    linkAccessType: LinkOptions.DEFAULT_ACCESS,
    isdeeplink: false
  };
}

function removeAllFiles() {
  uploadedFiles = [];
  refreshFileBox();
}

function removeFile(fileId) {
  let fileIndex;
  while((fileIndex = uploadedFiles.findIndex(f => f.id == fileId)) >= 0) {
    uploadedFiles.splice(fileIndex, 1);
  }
	refreshFileBox();
}

function refreshFileBox(targetFileId = null) {
  let attachmentBlueprintID = 'attachmentBlueprint';

  let attachments = document.getElementsByClassName('single-file attachment-box');
  let filesToDelete = [];
  for(let i = 0, fileElem; fileElem = attachments[i++];) {
    if(fileElem.id === attachmentBlueprintID || (targetFileId !== null && fileElem.id !== targetFileId)) {
      continue;
    }
    let keepElement = false;
    for(let i = 0, fileObj; fileObj = uploadedFiles[i++];) {
        if(fileObj.id == fileElem.getAttribute('fileId')) {
          // in case the file was overwritten by a file with the same id and a
          // different fileName
          $(fileElem).find('div.file-name')[0].innerHTML = fileObj.fileName;
          keepElement = true;
          break;
        }
    }
    if(!keepElement) {
      filesToDelete.push(fileElem.id);
    }
  }

  for(let i = 0; i < filesToDelete.length; i++) {
    document.getElementById(filesToDelete[i]).parentElement.removeChild(document.getElementById(filesToDelete[i]));
  }

  let fileBox = document.getElementById('attachContainer');
	$(uploadedFiles).each(function(idx, file) {
    if(targetFileId !== null && file.id !== targetFileId) {
      // important to prevent early processing of unsaved documents
      console.debug('skipping file ' + file.id + ', because the target is ' + targetFileId);
      return true;
    }
    let fileId = getValidFileName(file.id).replace(/\s/g, '_') + '_attachment';
    if(document.getElementById(fileId)) {
      return true; //file already added, continue iterating
    }
    let attachment = copyBlueprint(attachmentBlueprintID);
    for(let k in file) {
      attachment.setAttribute(k, file[k]);
    }
    attachment.setAttribute('fileId', file.id);
        attachment.id = fileId;
    $(attachment).off('click');
    $(attachment).on('click', function(e) {
      e.preventDefault();
      if(!e.target.className.includes('delete-file') && !e.target.className.includes('icon-exit')) {
        
        if(!file.id.toLowerCase().endsWith('pdf')){
          DocUtility.openFile(file.id, file.fileName, file.isWatermarked === '1');

        }else{
          showSpinner()
          DocUtility.getFileURL(file.id, file.isWatermarked === '1', null).then(fetchedURL => {
            let url = fetchedURL; 
            buildFileViewer(url);  
            hideSpinner(); 
          });
          
        }
      }
    });
    let fileNameDiv = $(attachment).find('div.file-name')[0];
    fileNameDiv.innerHTML = file.fileName;

    if(file.isWatermarked === '1') {
      let wmDetailsPlaceholder = document.createElement('div');
      wmDetailsPlaceholder.textContent = i18n.t("js.compose.wtmrk.fetching");
      wmDetailsPlaceholder.className = 'attach-details';
      attachment.appendChild(wmDetailsPlaceholder);
      apicall('documentsapi', 'fetchWatermarkSettings', {fileId: file.id}).then(fileInfo => {

        console.debug(fileInfo);
        let watermarkInfo = document.createElement('div');
        watermarkInfo.className = 'attach-details';

        let wmSpan = document.createElement('span');
        wmSpan.textContent = i18n.t("js.compose.wtmrk.watermarked");
        watermarkInfo.appendChild(wmSpan);
        watermarkInfo.innerHTML += ' - ' + formatLine(fileInfo.firstLine) + ' | ' + formatLine(fileInfo.secondLine);

        if(fileInfo.isEncrypted === '1') {
          watermarkInfo.innerHTML += '&nbsp;&nbsp;&#8213&nbsp;&nbsp;';
          let encryptedSpan = document.createElement('span');
          encryptedSpan.textContent = i18n.t("js.compose.wtmrk.encrypted");
          watermarkInfo.appendChild(encryptedSpan);
          watermarkInfo.innerHTML += i18n.t("js.compose.wtmrk.aes");
        }

        attachment.removeChild(wmDetailsPlaceholder);
        attachment.appendChild(watermarkInfo);
        function formatLine(line) {
          return line.replace(/^Other~/i, i18n.t("watermark.line1.label")+': ')
            .replace(/^FullName/, i18n.t("utils.name"))
            .replace(/^(IP)(Address)/, '$1 $2');
        }
      }).catch(err => {
        console.error(err);
        wmDetailsPlaceholder.innerHTML = `<span style="color:lightcoral">${i18n.t("js.compose.wtmrk.failed.fetch")}</span>`;
      });
    }

    attachment.onclick = (e) => {
      if($(e.target).hasClass('icon-exit') || e.target.children.length > 0 && $(e.target.children[0]).hasClass('icon-exit')) {

        let elementToRemove = e.target;
        while(elementToRemove.parentElement && !$(elementToRemove).hasClass('attachment-box')) {
            elementToRemove = elementToRemove.parentElement;
        }

        if($(elementToRemove).hasClass('attachment-box')) {
          console.log('removing: ' + elementToRemove.getAttribute('fileId'));
          console.log(elementToRemove);
          removeFile(elementToRemove.getAttribute('fileId'));
          let acceptedFiles = myDropZone2.getAcceptedFiles();
          let foundDropzoneFile = false;
          for(let i = 0; i < acceptedFiles.length; i++) {
            if(typeof acceptedFiles[i].newname  === 'string' &&
                getValidFileName(acceptedFiles[i].newname) === elementToRemove.getAttribute('fileName')
                || !acceptedFiles[i].newname &&
                getValidFileName(acceptedFiles[i].name) === elementToRemove.getAttribute('fileName')) {

              myDropZone2.removeFile(acceptedFiles[i]);
              foundDropzoneFile = true;
              console.log(acceptedFiles[i].name + ' was successfully removed from dropzone');
              break;
            }
          }
          if(!foundDropzoneFile) {
            console.log('did not find matching dropzone file');
          }
        }
      }
    };

    fileBox.appendChild(attachment);

    if(fileNameDiv.clientWidth < fileNameDiv.scrollWidth) {
      fileNameDiv.title = file.fileName;
    }
  });

  [].slice.call(fileBox.children).sort((f1, f2) => {
    const f1SubCat = '' + f1.getAttribute('subcategory');
    const f2SubCat = '' + f2.getAttribute('subcategory');

    if(f1SubCat === f2SubCat) return 0;
    let ss1 = ('prepschedule' === f1SubCat || 'callsheet' === f1SubCat) ? 1 : 2;
    let ss2 = ('prepschedule' === f2SubCat || 'callsheet' === f2SubCat) ? 1 : 2;
    return ss1 - ss2;
  }).forEach(fileBoxItem => {
    fileBox.appendChild(fileBoxItem);
  });

  uploadedFiles.sort((f1,f2)=>{
    const f1SubCat = '' + f1.subcategory;
    const f2SubCat = '' + f2.subcategory;

    if(f1SubCat === f2SubCat) return 0;
    let ss1 = ('prepschedule' === f1SubCat || 'callsheet' === f1SubCat) ? 1 : 2;
    let ss2 = ('prepschedule' === f2SubCat || 'callsheet' === f2SubCat) ? 1 : 2;
    return ss1 - ss2;
  });

  let attachmentsSize = findAttachmentsSize();
  let isSendAsLinkChecked = document.getElementById('sendAsLinkChk').checked;
  let attachmentSizeSpan = document.querySelector('#attachmentSizeSpan');
  attachmentSizeSpan.firstElementChild.innerHTML = 
    attachmentSizeSpan.firstElementChild.innerHTML.replace(/(\d+(\.\d+)?)(\/\d+)/, attachmentsSize + '$3');
  document.getElementById('sendAsLinkChk').checked = isSendAsLinkChecked;
  if(attachmentsSize > MAX_ATTACHMENTS_SIZE && !$(attachmentSizeSpan).hasClass('size-overflow')) {
    $(attachmentSizeSpan).addClass('size-overflow');
  } else if(attachmentsSize <= MAX_ATTACHMENTS_SIZE && $(attachmentSizeSpan).hasClass('size-overflow')) {
    $(attachmentSizeSpan).removeClass('size-overflow');
  }
  attachmentSizeSpan.style.display = uploadedFiles.length > 0 ?'block' :'none';
  setLinkOptionsListener();
  console.log('just refreshed file box. uploaded files is now');
  console.log(uploadedFiles);
}

function buildFileViewer(fileLink) {

  let overlay = document.getElementById('overlay');
  let viewBody = document.getElementById('viewerBody');
  let viewContainer = document.getElementById('fileContainer');
  let btn = document.getElementById('closeBtn')

  let fileIframe = document.createElement('iframe');
  fileIframe.setAttribute('src', fileLink);
  fileIframe.innerHTML = "Iframe not supported"; 

  viewContainer.classList.add('active');
  overlay.classList.add('active');

  viewBody.appendChild(fileIframe);

  btn.addEventListener('click', () =>{

    viewContainer.classList.remove('active');
    overlay.classList.remove('active');
    viewBody.removeChild(fileIframe);
  });

  overlay.addEventListener('click', ()=>{
    viewContainer.classList.remove('active');
    overlay.classList.remove('active');
    viewBody.removeChild(fileIframe);
  })
}

function findAttachmentsSize() {
  let totalSize = 0;
  uploadedFiles.forEach(file => {
    totalSize += parseInt(file.size) || 0;
  });
  return Math.round(totalSize/100000)/10 || 0; // returns 0 instead of 0.0
}

//timers
var minutes = 1000 * 60, hours = minutes * 60, days = hours * 24,
		years = days * 365, d = new Date(), t = d.getTime(), m = Math
				.round(t / minutes);

var relatedEntitiesLoaded = false;

var cachedDistributionGroupNotify;
var uploadInformationValue = [];
var addedFileList = [];
var popupShowing;
var myDropZone2;
var isFirstUpload, isSharedOptions;
var shouldPublishToStudio;

function initDropzone() {
  
	Dropzone.autoDiscover = false;

	var _tempImgName = "";
	var imageIsSet = false;

  Dropzone.options.myDropZone2 = {
    autoProcessQueue: false,
		parallelUploads : 10,
    uploadMultiple: false,
    // Override the default behaviour of dropzone to show no preview
    // https://docs.dropzone.dev/configuration/theming#completely-change-the-way-dropzone-is-displayed
    previewTemplate: document.getElementById('dropzone-hide-preview-template').innerHTML,
    init: function () {
      myDropZone2 = this;
      console.log('running init dropzone');
			var imageSet = document.getElementById("pic");
			if ($(imageSet).attr('src') != null
        && $(imageSet).attr('src') != '') {
				imageIsSet = true;
			}

			this.on("success", async function(file, response) {
        saveFileEntity(file, shouldPublishToStudio)
			});

      this.on("removedfile", function(file) {
        if(file.unlisted == true) {
          console.log('removing file that was not added to uploadInfoValue or addedFileList');
          return;
        }
        console.log("removing file from dropzone: " + file.name);
        for(var i = 0; i < uploadInformationValue.length; i++) {
          if(uploadInformationValue[i].name === getValidFileName(file.name) || uploadInformationValue[i].name === getValidFileName(file.newname)){
            addedFileList.splice(i, 1);
            uploadInformationValue.splice(i,1);
          }
        }
      });

			this.on("processing", function(file) {
        console.log("processing a file")
        CGProcessFile(file);
			});

      this.on("error", function(file, errorMsg) {
        console.error('file upload failed');
      });

      this.on("addedfile", async function(file) {
        
        shouldPublishToStudio = false

        console.log("added a file " + file.name);
        let newName = false;
        if ((newName = isFileAttached(file, this)) !== false) {
          typeof newName === 'string' ?
            cgToast(i18n.t("js.compose.attachment.already.diff"), { fileName: file.name, newName: newName }) :
            cgToast(i18n.t("js.compose.attachment.already.reg", { fileName: file.name, newName: newName }),
          8000);
          file.unlisted = true;
          this.removeFile(file);
          return;
        } else if(parseInt(file.size) > MAX_ATTACHMENTS_SIZE * 1000000) {
          console.log('file size too big, cannot add attachment');
          cgToast(i18n.t("js.compose.attachment.fat", {fileName:file.name, maxSize:MAX_ATTACHMENTS_SIZE}), {
            hiding: 'manual',
            showCloseButton: true
          });
          file.unlisted = true;
          this.removeFile(file);
          return;
        }
        addedFileList.push(file);

        if(defaultFileInfoSetting == 'ask') {
          isUsingDefaultInfo = await askIfUsingDefaultFileInfo();
        } else {
          isUsingDefaultInfo = defaultFileInfoSetting === 'no';
        }

        if(!popupShowing) {
          popupShowing = true;
          isFirstUpload = true;
          if(isUsingDefaultInfo) {
            try {
              DocUtility.getStoredCardViews('ROOT', cardViews => {
                if(typeof cardViews.find(cv => cv.id === 'DISTRIBUTED_ROOT') === 'undefined') {
                  // The Distributed folder will be generated in the DOCUMENT entity saving process
                  console.debug('Distributed folder not found - clearing ROOT card views');
                  DocUtility.removeCardViews('ROOT');
                }
              });
            } catch(e1) {
              console.error(e1);
            }
            isSharedOptions = true;
            parentFolderId = 'DISTRIBUTED_ROOT';
            renderFileType({
              'shared': [],
              'episode': '0'
            });
          } else {
            isSharedOptions = false;
            renderFileType();
          }
        }   

        // await publishToStudioSwal().then(result => {
        //   shouldPublishToStudio = result
        // })
        console.log("publish to studio: ", shouldPublishToStudio)

      });
		}
	}

	$("#myDropZone2").dropzone();
}

function askIfUsingDefaultFileInfo() {
  return new Promise(resolve => {
    let shouldFileInfoSettingPersist = false;
    let shouldSkipCategorization = false;
    swal({
      title: i18n.t("js.compose.file.info.title"),
      html: i18n.t("js.compose.file.info.msg")
        +'<br><div style="margin-top: 0.75rem">'
        + '<input id="defaultFileInfoSettingCheck" type="checkbox"/>'
        +  `<label for="defaultFileInfoSettingCheck">${i18n.t("js.utils.remember")}</label>`
        + '</div>',
      confirmButtonText: i18n.t("yes"),
      showCancelButton: true,
      cancelButtonText: i18n.t("no"),
      type: 'question',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      onOpen: function() {
        let defaultFileInfoSettingCheck = document.getElementById('defaultFileInfoSettingCheck');
        defaultFileInfoSettingCheck.onchange = function() {
          shouldFileInfoSettingPersist = defaultFileInfoSettingCheck.checked;
        };
      }
    }).then(function() {
      shouldSkipCategorization = true;
    }).catch(() => {
      shouldSkipCategorization = false;
    }).then(function() {
      try {
        defaultFileInfoSetting = shouldFileInfoSettingPersist
          ?(shouldSkipCategorization ?'no' :'yes')
          :'ask';
        apicall('distributionapi', 'updateDistributionSettings', {}, {
          requireAttachmentFileInfo: defaultFileInfoSetting
        }).then(resp => {
          if(resp.responseCode !== '0') {
            throw new Error('server error');
          }
        }).catch(() => {
          console.error('server error updating user settings');
        });
      } catch(e1) {
        console.error(e1);
      } finally {
        resolve(shouldSkipCategorization);
      }
    });
  });
}

function CGProcessFile(file) {
  console.log("processing a file");
  console.log(file.name + ' added at: ' + new Date().getTime());
  toastHider.newFiles[file.name] = typeof toastHider.newFiles[file.name] === 'object'
    ?toastHider.newFiles[file.name] :[];
  let randomID = Utility.uniqueID();
  toastHider.newFiles[file.name].push(
    cgToast(i18n.t("js.utils.save.file", {filename: file.name}), {
      hiding: 'manual',
      id: randomID,
      containerID: 'docBody',
      className: 'body-attached'
    })
  );
  document.getElementById(randomID).setAttribute('filename', getValidFileName(file.name));
  var now = new Date();
  var datetime = now.toISOString();
  datetime = datetime.replace(/[^\w]/g,"").replace(/[A-Za-z]/g,"");

  var fixedFileName = getValidFileName(file);
    var newFileId = file.id;
  var bucketName = "8810931_"+ croogloo_auth.tenantId.toLowerCase();
  console.log("urls to add: ", urlsToAdd)

  // unused
    myDropZone2.options.url = null;
    
  _tempImgName = newFileId;
  if(file.type === 'dropbox-file' || file.type === 'box-file') {
    file.unlisted = true;
    myDropZone2.removeFile(file);
    file.uploadURL += "&destfile=" + newFileId;
    
    authenticatedcall(file.uploadURL)
    .then(function (resp) {
      file.src = file.type === 'dropbox-file' ? 'dropbox' : 'box';
      myDropZone2.emit('success', file);
  
    })
    .catch(err => {
      console.error(err);
      let hideSaveToast = toastHider.newFiles[file.name].shift();
      hideSaveToast();
      removeFile(newFileId);
      myDropZone2.removeFile(file);
      cgToast(i18n.t("js.compose.file.import.failure"), 3000);

    });

  }
  else {
    file.unlisted = true;
    myDropZone2.removeFile(file);
    uploadFile(file, function() {
      file.src = 'local_system';
      myDropZone2.emit('success', file);
    });
  }
}

/**
 * Determines whether or not a file by the name of the file passed as first
 * parameter has already been attached or is being uploaded.
 * For maximum safety and reliability, this method compares the original files'
 * name, rather than the new one they may have been given during the upload
 * process.
 * @param  {object}  file     The file to be uploaded.
 * @param  {object}  dropzone The dropzone object.
 * @return {Boolean}          Whether or not a file by that name has already
 *                            been attached or is being uploaded.
 */
function isFileAttached(file, dropzone) {
  let newFileName = getValidFileName(file.name);
  let acceptedFiles = dropzone.getAcceptedFiles();
  for(let i = 0; i < acceptedFiles.length; i++) {
    if(getValidFileName(acceptedFiles[i].name) === newFileName) {
      return acceptedFiles[i].newname ?acceptedFiles[i].newname :true;
    }
  }
  for(let i = 0; i < uploadedFiles.length; i++) {
    if(uploadedFiles[i].fileName === newFileName) {
      return true;
    }
  }
  return false;
}

async function saveFileEntity(file, shouldPublishToStudio) {
  let hideSaveToast = toastHider.newFiles[file.name].shift();
	console.debug("success on file ");
  let fixedFileName = getValidFileName(file);
  var newFileId = file.id;

  console.debug('success for file '+ newFileId);
	var bucketName = "8810931_" + croogloo_auth.tenantId.toLowerCase();
	var uploadedFileURL = `https://storage.googleapis.com/${bucketName}/${newFileId}`;

	console.log("$$$$$UPLOADEDFILEURL IS 2: " + uploadedFileURL);

	var newFile = new Object();
	var newFileAttributes = [];
	var secList = [];
	var category = '';
	var wmk = 0;
	var episode = '';
	var color = '';
  var watermark, wmFirstLine, wmSecondLine, wmWithEncryption;

	var currFile = "";
	for(var i = 0; i < uploadInformationValue.length; i++){

		if(uploadInformationValue[i].name === fixedFileName) {
      currFile = uploadInformationValue[i];
      uploadInformationValue.splice(i, 1);
      break;
    }
	}

	newFile._ID = newFileId;
	newFile._NAME = fixedFileName;
  console.log('fileid is: ' + newFile._ID);
  newFile.entityType = "DOCUMENT";
	newFile.pageName = "data";
	newFile.tenantId = croogloo_auth.tenantId;

	newFileAttributes.push({id : "id", value : newFileId});
	newFileAttributes.push({id : "category", value : "DOCUMENT"});

  newFileAttributes.push({id: 'description', value: currFile.description});
  newFileAttributes.push({id : "bucket", value : bucketName});
	newFileAttributes.push({id : "fileName", value : fixedFileName});
	newFileAttributes.push({id : "fileURL", value : uploadedFileURL});
	newFileAttributes.push({id : "image", value : uploadedFileURL});
	newFileAttributes.push({id : "approvedDate", value : ""});
	newFileAttributes.push({id : "approvedBy", value : ""});
	newFileAttributes.push({id : "showInHub", value : "0"});
	newFileAttributes.push({id : "showInReports", value : "0"});
	newFileAttributes.push({id : "title", value : fixedFileName});
	newFileAttributes.push({id : "isSubmitted", value : "0"});
	newFileAttributes.push({id : "timeCreated", value : new Date().toISOString()});
	newFileAttributes.push({id : "size", value : file.size});
	newFileAttributes.push({id : "priority", value : "0"});
	newFileAttributes.push({id : "subtitle", value : fixedFileName});
	newFileAttributes.push({id : "submittedDate", value : ""});
	newFileAttributes.push({id : "submittedTo", value : ""});
	newFileAttributes.push({id : "submittedBy", value : ""});
  newFileAttributes.push({id : "parentFolder", value : parentFolderId.split(',', 2)[0]});
  newFileAttributes.push({id : "parents", value : parentFolderId});
  DocUtility.removeCardViews(parentFolderId);
  newFileAttributes.push({id : "children", value : ""});
  newFileAttributes.push({id: "lastModifBaseTime", value : new Date().getTime()});

  newFileAttributes.push({id: "source", value : file.src});

  newFileAttributes.push({id: "limitToType", value:'securityList'});

  // make sure the user's security list, as well as the ADMIN list have access
  // to the uploaded file
  let secListsWithAccess = ['ADMIN'];
  if (typeof croogloo_auth.crooglooauth.securityListId == 'string'
      && croogloo_auth.crooglooauth.securityListId.trim().length > 0
      && !secListsWithAccess.includes(croogloo_auth.crooglooauth.securityListId.trim().toUpperCase())) {
    secListsWithAccess.push(croogloo_auth.crooglooauth.securityListId.trim().toUpperCase());
  }
	newFileAttributes.push({id: "limitTo", value : secListsWithAccess.join(',')});

	category = currFile.category ? currFile.category   : "other";
	newFileAttributes.push({id: "subcategory", value: category});

	watermark = currFile.watermark ?1 : 0;
	newFileAttributes.push({id:"isWatermarked", value:watermark});

  wmFirstLine = currFile.wmFirstLine ?currFile.wmFirstLine :"";
	if(wmFirstLine === 'Other') {
		wmFirstLine = 'Other~' + wmFirstOtherField;
	}
	newFileAttributes.push({id:"firstWatermarkLine", value: wmFirstLine});

	wmSecondLine = currFile.wmSecondLine ?currFile.wmSecondLine :"";
	if(wmSecondLine === 'Other') {
		wmSecondLine = 'Other~' + wmSecondOtherField;
	}
	newFileAttributes.push({id:"secondWatermarkLine", value:wmSecondLine});

  wmWithEncryption = currFile.wmWithEncryption === '1' ?'1' :'0';
  newFileAttributes.push({id: "watermarkWithEncryption", value: wmWithEncryption});

	episode = currFile.episode ? currFile.episode : "";
	newFileAttributes.push({id:"episode", value:episode});

	color = currFile.color ? currFile.color : "";
	newFileAttributes.push({id:"color", value:color});

	newFile.attributes = newFileAttributes;

  let payload = {'tags': 'test', 'replaceEntity': false}

  // TODO: re-enable for Publish to Studio feature
  // if(shouldPublishToStudio) {
  //   payload.cloudProviderName = "BOX"
  //   payload.cloudAuthToken = croogloo_auth.crooglooauth.boxToken

  //   let boxRefreshToken = JSON.parse(global.secureLocalStorage.get('crooglooauth')).boxRefreshToken
  //   await croogloo_auth.box(boxRefreshToken, true)
  // }

  apicall('distributionapi','saveAttachment', payload, newFile, false).then(function(resp) {

    if (resp.responseCode === '0') {
      
      console.log("FILE IS ADDED IN " + parentFolderId);
      console.log("uploaded Files: ",uploadedFiles);

      if(!currFile.isParsed) {
        hideSaveToast();
      } else {
        DocUtility.parseScript(newFileId, currFile.name, currFile.name, currFile.episode,
          currFile.color, true, hideSaveToast);
      }

      // Must only refresh the file box for the added file, to prevent
      // processing files uploaded in bulk that have not yet been saved.
      // Should that happen, it won't be possible to retrieve watermark
      // settings for these unsaved attachments, even after they're saved.
      refreshFileBox(newFileId);

    } else {
      throw new Error('invalid server response');
    }

  }).catch(() => {
    console.error('server error');
    hideSaveToast();
    handleUploadFailure(file);
  });
}

function uploadFile(file, callback) {
  var formData = new FormData();
  formData.append("fileName", file.id);
  formData.append("file", file);

  authenticatedcall("/UploadServletV2", 'POST', formData, 'multipart/form-data').then(()=>{
    if(callback) {
      callback();
    }
    
  }, err => {
    console.log('error response: ');
    console.log(err);
    try {
      if(typeof toastHider.newFiles[file.name] == 'function') {
        let hideSaveToast = toastHider.newFiles[file.name].shift();
        hideSaveToast();
      }
    } catch(err2) {
      console.error(err2);
    }
    file.unlisted = false;
    handleUploadFailure(file);
  });
}

function handleUploadFailure(file) {
  try {
    promptUploadFailure(getValidFileName(file));
    removeFile(file.id);
    myDropZone2.removeFile(file);
    croogloo_auth.sendRedAlert('Upload failure for ' + file.id);
  } catch(e) {
    console.error(e);
  }

  function promptUploadFailure(fileName) {
    cgToast( `<span style="color:#F3C7C5">${i18n.t("js.utils.upload.failed")}</span>`
      + `&nbsp;for&nbsp;<span style="text-decoration: underline">${fileName}</span>&nbsp;. `
      + i18n.t("js.utils.try.again"), {
        hiding: 'manual',
        showCloseButton: true
      }
    );
  }
}

async function renderFileType(fileOptions = null) {
  console.log('rendering file type');
  if(!isUsingDefaultInfo) {
    let hasAttachedRedundantFile = await tryAttachingRedundantFile();
    if(hasAttachedRedundantFile) {
      console.debug('attached redundant file, moving on to next entry');
      showNext(false, fileOptions == null ?null :Object.assign({}, fileOptions));
      return;
    }
  }
	var fileToCategorize = addedFileList.shift();
	var currName = getValidFileName(fileToCategorize);
  let fileId = DocUtility.generateUniqueId(currName);

	var bucketName = "8810931_" + croogloo_auth.tenantId.toLowerCase();
	var uploadedFileURL = "https://storage.googleapis.com/" + bucketName + "/" + fileId;

	var scriptInfo;
	var doneCreatingSidesTask = false;
	var userValidatedSidesInfo = false;
	var choseToProcessLater = false;
	var waitToReRender = false;

	var cate = "";
	var object = new Object();
  var object = fileOptions === null ? new Object():Object.assign({}, fileOptions);
  object.name = currName;

	var htmlContent = '<p><br><u><b>'+ currName +'</b></u><br><br>'+
			'</p><form class="uploadForm">'+
			'<label style="font-size:18px;">'+i18n.t("category")+'</label><select name="categorySelector" class="browser-default" id="subCategorySelect">'+
			'<option disabled selected value>'+i18n.t("js.crew.category.select")+'</option>'+
			'<option value="breakdown">'+i18n.t("breakdown")+'</option>'+
			'<option value="callsheet">'+i18n.t("dashboard.opts.call-sheet")+'</option>'+
			'<option value="chrono">'+i18n.t("chrono")+'</option>'+
			'<option value="clearance">'+i18n.t("clearance")+'</option>'+
			'<option value="dood">'+i18n.t("dood")+'</option>'+
			'<option value="dpr">'+i18n.t("dpr")+'</option>'+
			'<option value="maps">'+i18n.t("maps")+'</option>'+
			'<option value="oneliner">'+i18n.t("oneliner")+'</option>'+
			'<option value="prepschedule">'+i18n.t("prepschedule")+'</option>'+
      '<option value="photo">'+i18n.t("photo")+'</option>'+
			'<option value="production">'+i18n.t("production")+'</option>'+
			'<option value="revision">'+i18n.t("revision")+'</option>'+
			'<option value="script">'+i18n.t("script")+'</option>'+
			'<option value="script timing">'+i18n.t("script-timing")+'</option>'+
			'<option value="shooting">'+i18n.t("shooting")+'</option>'+
			'<option value="side">'+i18n.t("sides")+'</option>'+
			'<option value="techsurvey">'+i18n.t("tech-survey")+'</option>'+
			'<option value="other">'+i18n.t("other")+'</option></select><br>'+
			'<label style="font-size:18px;">'+i18n.t("episode")
      +'</label><input id="episodeEntry" placeholder="'+i18n.t("js.compose.episode.number")+'" type="text" name="episode"><br>'+
      '</form>';

	(fileOptions === null ?(swal({
    title: i18n.t("js.compose.file.info.title"),
    type: 'question',
    html: htmlContent,
    confirmButtonText: i18n.t("button.next"),
    confirmButtonColor: '#13C46A',
    showCloseButton: true,
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true,
    onOpen: function(swal) {
      $('#episodeEntry').off();
      $('#episodeEntry').on('keypress', function(event) {
        return validKey(event.key);
      });
    },
	  preConfirm: function () {
      return new Promise(function (resolve, reject) {
        object.name = currName;
        object.shared = [];

        $('#sharedInput').children().each(function() {
          if($(this).is(':checked'))
          object.shared.push($(this).attr('id'));
        });

        if($('select[name=categorySelector]').val()) {
          cate = $('select[name=categorySelector]').val();
          object.category = cate;
        }

        object.episode = $('#episodeEntry').val().trim() || '0';

        resolve([object]);
      })
    }
	})) :Promise.resolve([Object.assign({}, fileOptions)])).then(async function (result) {

    uploadInformationValue.push(object);

    var newObj = new Object();
    newObj.id = fileId;
    newObj.fileName = getValidFileName(object.name);
    newObj.url = uploadedFileURL;
    newObj.size = fileToCategorize.size;
    newObj.subcategory = cate || 'other';


    if(fileOptions === null) {
      if(["script", "revision"].includes(cate) && !newObj.fileName.endsWith(".zip")) {
        if(!await getScriptInfo(object, newObj.fileName)) {
          throw {
            newObj: newObj,
            message: 'popup closed'
          };
        }
        
      }

      if(["photo"].includes(cate)) {
        if(!await DocUtility.getDocumentDescription(object)) {
          throw 'popup closed';
        }
      }

      if(newObj.fileName.endsWith(".pdf") || newObj.fileName.endsWith(".zip") && cate !== 'photo') {
        if(!await getWatermarkOptions(object)) {
          throw {
            newObj: newObj,
            message: 'popup closed'
          };
        }
      }

      if(!await getUploadFolders(object, fileToCategorize)) {
        throw {
          newObj: newObj,
          message: 'popup closed'
        };
      }
    } else if(!(newObj.fileName.endsWith(".pdf") || newObj.fileName.endsWith(".zip") && cate !== 'photo')) {
      object.wmWithEncryption = '0';
      object.watermark = 0;
    }

    console.log("adding uploaded file")
    newObj.isWatermarked = (object.watermark||0) + '';
    addUploadedFile(newObj);
    console.log('adding file to uploaded files 1 (outside file)');
    console.log(fileToCategorize);

    showNext(false, Object.assign({}, object));
    
    // TODO: re-enable for Publish to Studio feature
    // await publishToStudioSwal(newObj).then(result => {
    //   shouldPublishToStudio = result
    // })
    fileToCategorize.id = newObj.id;
    CGProcessFile(fileToCategorize);


  }).catch(e => {
    if(typeof e == 'object' && e.hasOwnProperty('newObj')) {
      console.log('removing file');
      removeFile(e.newObj.id);
    } else {
      console.log('upload cancelled', e);
    }
    showUploadCancelAlert(fileToCategorize, fileOptions);
  });
}

function showUploadCancelAlert(file, fileOptions) {
  swal({
    title: i18n.t("js.compose.upload.cancelled.title"),
    text: i18n.t("js.compose.upload.cancelled.text"),
    type: 'error',
    confirmButtonColor: '#13C46A',
    confirmButtonText: i18n.t("OK"),
    useRejections: true, //important for swal2 v7.1.2
    expectRejections: true
  }).then(function(){
    showNext(true, Object.assign({}, fileOptions));
    myDropZone2.removeFile(file);
  }).catch(swal.noop);
}

function getScriptInfo(object, filename) {
  return new Promise(resolve => {
    // '<option selected value="-"> Paper Chase </option>'+ instead Script Version
    var scriptInfoHtml = "<form><div class='colorSelector'>"+i18n.t("js.file.script.color.text")+"<div><br>"+
    '<div><select name="colorMult" class="browser-default" id="colorMult">'+
    '<option selected value="-">'+i18n.t("js.file.script.version")+'</option>'+
    '<option value="draft">Draft</option>'+
    '<option value="single">Single</option>'+
    '<option value="double">Double</option>'+
    '<option value="triple">Triple</option>'+
    '<option value="quadruple">Quadruple</option>'+
    '<option value="quintuple">Quintuple</option></select>'+
    '<select name="colorVal" class="browser-default" id="colorVal">'+
    '<option selected value="-">'+i18n.t("js.file.script.color.default")+'</option>'+
    '<option value="white">White</option>'+
    '<option value="blue">Blue</option>'+
    '<option value="pink">Pink</option>'+
    '<option value="yellow">Yellow</option>'+
    '<option value="green">Green</option>'+
    '<option value="goldenrod">Goldenrod</option>'+
    '<option value="buff">Buff</option>'+
    '<option value="salmon">Salmon</option>'+
    '<option value="cherry">Cherry</option>'+
    '<option value="tan">Tan</option>'+
    '<option value="lavender">Lavender</option>'+
    '</select></div>'+
    '<br><label style="font-size:18px;">'+i18n.t("js.file.script.parse")
    +'&emsp;</label><input type="checkbox" id="parseCheckbox" value="1" checked></form>';

    swal({
      title: i18n.t("js.file.script.version"),
      html: scriptInfoHtml,
      showCloseButton: true,
      confirmButtonText: i18n.t("button.next"),
      confirmButtonColor: '#13C46A',
      animation: false,
      useRejections: true, //important for swal2 v7.1.2
      expectRejections: true,
      preConfirm: function(){
        return new Promise(function(done, reject) {
          if($('#parseCheckbox').is(':checked') && $('select[name=colorVal]').val() == "-"){
            reject(i18n.t("js.file.script.reject.color"))
          } else if($('#parseCheckbox').is(':checked') && (/\.pdf$/i).exec(filename) === null) {
            reject(i18n.t("js.file.script.reject.pdf-only"));
          } else {
            done({
              isParseChecked: $('#parseCheckbox').is(':checked'),
              paperChase: $('select[name=colorMult]').val(),
              color: $('select[name=colorVal]').val()
            });
          }
        });
      }
    }).then(function(scriptOptions){
      var mult = scriptOptions.paperChase;
      var col = scriptOptions.color;
      mult = mult !== "-" ?mult :"";
      col = col !== "-" ?col :"";

      if(mult == 'draft') {
        object.color = 'draft';
      }
      else if(mult && col){
        if(mult == 'single') {
          object.color = col;
        }
        else {
          object.color = mult+col[0].toUpperCase()+col.substring(1);
        }
      }
      else if(col){
        object.color = col;
      }
      if($('#parseCheckbox').is(':checked') && !object.episode){
        object.episode = 0;
      }

      if(scriptOptions.isParseChecked){
        object.isParsed = true;
      }
      
      resolve(true)

    }, dismiss => resolve(false));
  });
}

function getWatermarkOptions(object) {

  // users should not see the watermarking options if they don't have access to it
  if(!croogloo_auth.hasAccess('watermark')) {
    object.wmFirstLine = null;
    object.wmSecondLine = null;
    object.wmWithEncryption = '0';
    object.watermark = 0;
    return Promise.resolve(true);
  }

  return new Promise(resolve => {
    swal({
      title: i18n.t("docviewer.menu.apply-wtmrk"),
      html: generateWatermarkingOption(),
      type: 'info',
      input: 'text',
      inputClass: 'hidden-input', //used to prevent focus error on reject
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("button.confirm"),
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: i18n.t("js.sides.callsheet.skip"),
      background: '#fff url(' + IMG_DIRECTORY + 'watermarkLogo.png)',
      animation: false,
      showLoaderOnConfirm: false,
      useRejections: true, //important for swal2 v7.1.2
      expectRejections: true,
      onOpen: function() {
        document.getElementById('wmFullName').click();
        document.getElementById('wmBlank').click();
      },
      preConfirm: function(text) {
        return new Promise(function(done, reject) {
          if(!currentWmFirstLine || !currentWmSecondLine) {
            reject(i18n.t("js.file.wtmrk.reject.selection"));
          } else if(currentWmFirstLine === 'Other'
              && (!wmFirstOtherField || !wmFirstOtherField.trim()) ||
              currentWmSecondLine === 'Other'
              && (!wmSecondOtherField || !wmSecondOtherField.trim())) {
            reject(i18n.t("js.file.wtmrk.reject.other"));
          }
          else {
            done(document.getElementById('encryptChk') != null &&
              document.getElementById('encryptChk').checked);
          }
        });
      }
    }).then(function(addEncryption) {
      object.wmFirstLine = currentWmFirstLine;
      object.wmSecondLine = currentWmSecondLine;
      object.wmWithEncryption = addEncryption === true ?'1' :'0';
      object.watermark = 1;
      resolve(true);
    }, dismiss => resolve(dismiss === 'cancel')); // cancel = skip button
  });
}

function generateWatermarkingOption() {

	currentWmFirstLine = '';
	currentWmSecondLine = '';
	wmFirstOtherField = '';
	wmSecondOtherField = '';

	let wmSettingsSwalContainer = document.createElement('div');
	wmSettingsSwalContainer.id = 'wmSettingsSwalContainer';

	//////////////////////////////////////////////////////////////////////

	//first line options

	let firstLineDiv = new WatermarkOptionDiv('lineOne', i18n.t("watermark.line1.title"));

	firstLineDiv.addLine([
		{id: 'wmFullName', label: i18n.t("watermark.line1.rec-name"), type: 'checkbox'}
	]);

	firstLineDiv.addLine([
		{id: 'firstOtherCheckbox', label: i18n.t("other"), type: 'checkbox'},
		{id: 'firstOtherInput', label: null, type: 'text'}
	]);

	firstLineDiv.build(wmSettingsSwalContainer);

	//second line options

	let secondLineDiv = new WatermarkOptionDiv('lineTwo', i18n.t("watermark.line2.title"));

	secondLineDiv.addLine([
		{id: 'wmTitle', label: i18n.t("watermark.line2.opts-title"), type: 'checkbox'},
		{id: 'wmDepartment', label: i18n.t("watermark.line2.opts-dept"), type: 'checkbox'}
	]);

	secondLineDiv.addLine([
		{id: 'wmDate', label: i18n.t("watermark.line2.opts-date"), type: 'checkbox'},
		{id: 'wmIPAddress', label: i18n.t("watermark.line2.opts-ip"), type: 'checkbox'}
	]);

	secondLineDiv.addLine([
		{id: 'wmBlank', label: i18n.t("watermark.line2.opts-blank"), type: 'checkbox'}
	]);

	secondLineDiv.addLine([
		{id: 'secondOtherCheckbox', label: i18n.t("watermark.line2.opts-other"), type: 'checkbox'},
		{id: 'secondOtherInput', label: null, type: 'text'}
	]);

	secondLineDiv.build(wmSettingsSwalContainer);

  WatermarkOptionDiv.addEncryptionOption(wmSettingsSwalContainer);

	//////////////////////////////////////////////////////////////////////

	//the elements' event listeners from watermarkingOptions.js do not work
	//on swal
	$(document).on('click', '.wm-checkbox-lineOne', function() {
		if(this.checked) {
			currentWmFirstLine = this.id
				.match(/Other|Title|Department|FirstName|LastName|FullName|Blank|IPAddress|Date/)[0];
		} else {
			currentWmFirstLine = '';
		}

		let ref = this;

		$('.wm-checkbox-lineOne').each(function() {
			if(ref.id !== this.id) {
				this.checked = false;
			}
		});
	});

	$(document).on('click', '.wm-checkbox-lineTwo', function() {

		if(this.checked) {

			currentWmSecondLine = this.id
				.match(/Other|Title|Department|FirstName|LastName|FullName|Blank|IPAddress|Date/)[0];

		} else {
			currentWmSecondLine = '';
		}

		let ref = this;

		$('.wm-checkbox-lineTwo').each(function() {
			if(ref.id !== this.id) {
				this.checked = false;
			}
		});

	});


	$(document).on('focus', '#firstOtherInput', function() {

		if(!$('#firstOtherCheckbox').is(':checked')) {
			$('#firstOtherCheckbox').click();
		}

	});

	$(document).on('blur', '#firstOtherInput', function() {
		wmFirstOtherField = this.value;
	});

	$(document).on('focus', '#secondOtherInput', function() {

		if(!$('#secondOtherCheckbox').is(':checked')) {
			$('#secondOtherCheckbox').click();
		}

	});

	$(document).on('blur', '#secondOtherInput', function() {
		wmSecondOtherField = this.value;
	});


	return wmSettingsSwalContainer.outerHTML;
}

function getUploadFolders(object, file) {

  if(!croogloo_auth.hasAccess('documents')) {
    let selectedFolderIds = SmartFolder.getMatchingFolderIds(file, object, smartFolders);
    for(let folderId of selectedFolderIds) {
      for(let folder of folderCollection) {
        if(folderId === folder.id) {
          selectedFolders.push({
            id: folder.id,
            name : folder.text,
            depth: 0 // NA
          });
          break;
        }
      }
    }
    changeParentFolder();
    return Promise.resolve(true);
  }

  return new Promise(resolve => {
    swal({
      title: i18n.t("js.file.destination"),
      html: '<div id="folderTreeContainer"></div>',
      input: 'text',
      inputClass: 'hidden-input', //used to prevent focus error on reject
      confirmButtonColor: '#13C46A',
      confirmButtonText: i18n.t("button.confirm"),
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      showCloseButton: true,
      animation: true,
      showLoaderOnConfirm: false,
      useRejections: true, //important for swal2 v7.1.2
      expectRejections: true,
      allowOutsideClick: () => !swal.isLoading(),
      onOpen: function() {
        generateFolderTree(document.getElementById('folderTreeContainer'), () => {
          preSelectFolders(file, object);
        });
      },
      preConfirm: function(text) {
        return new Promise(function(done, reject) {
          if(!selectedFolders.length) {
            reject(i18n.t("js.file.wtmrk.reject.selection"));
            return;
          }
          changeParentFolder();
          done();
        });
      }
    }).then(() => {
      $('#folderTreeContainer').remove();
      resolve(true);
    }, () => {
      $('#folderTreeContainer').remove();
      resolve(false);
    });
  });
}

function preSelectFolders(file, fileProps) {
  let selectedFolderIds = SmartFolder.getMatchingFolderIds(file, fileProps, smartFolders);
  for(let i = 0; i < selectedFolderIds.length; i++) {
    try {
      $('#folderTree').jstree('select_node', selectedFolderIds[i]);
    } catch(e) {
      console.error(e);
    }
  }
}

function generateFolderTree(container, onload) {
  console.debug('folder collection:');
  console.debug(folderCollection);
  selectedFolders = [];
  let div = document.createElement('div');
  div.id = 'folderTree';
  container.appendChild(div);
  $(div).jstree({
    'core': {
      'data': folderCollection,
      'multiple': true
    },
    sort: function(a, b) {
      try {
        return a.replace(/%20/g,' ').localeCompare(b.replace(/%20/g, ' '));
      } catch(e) {
        return 0;
      }
    },
    checkbox: {
      three_state : false, // prevent checking children
      whole_node : true,
      tie_selection : true
    },
    "plugins": ["checkbox", "sort"]
  }).on('changed.jstree', function (e, data) {
    var i, j;
    selectedFolders = [];
    for(i = 0, j = data.selected.length; i < j; i++) {
      let node = data.instance.get_node(data.selected[i]);
      selectedFolders.push({
        id: node.id,
        name : node.text,
        depth: node.parents.length
      });
    }
    selectedFolders.sort((f1, f2) => {
      return (f1.depth - f2.depth) || f1.name.localeCompare(f2.name);
    });
  }).on('loaded.jstree', onload || function(){});
}

function addUploadedFile(file) {
  // preventing file duplication
  for(let i = 0; i < uploadedFiles.length; i++) {
    if(uploadedFiles[i].id == file.id) {
      uploadedFiles[i] = file;
      return;
    }
  }
  uploadedFiles.push(file);
}

function tryAttachingRedundantFile() {
  return new Promise(async function(resolve) {
    var file = addedFileList[0];
    var filename = getValidFileName(file);
    var folder = parentFolderId;

    // If we're using default info, we don't bother the user with duplicated file names,
    // as our system can manage them without a problem. We normally tell users about
    // it for their own convenience.
    if(isUsingDefaultInfo) {
      file.newname = filename;
      done();
      return;
    }

    toastHider.upload = null;
    while(Array.isArray(loader.upcoming) && loader.upcoming.length) {
      console.debug('waiting for docs and folders to load');
      if(toastHider.upload === null) {
        toastHider.upload = cgToast(i18n.t("js.utils.upload.preparing"), { hiding: 'manual' });
      }
      await new Promise(r => setTimeout(r, 300));
    }
    if(typeof toastHider.upload == 'function') { toastHider.upload(); }

    checkFileRedundancy(filename, () => {
      // no redundant file or ignoring it
      resolve(false);
    }, redundantFileId => {
      // using redundant file from Croogloo documents instead
      addedFileList.shift();
      myDropZone2.removeFile(file);
      console.debug('redundantFileId: ', redundantFileId);
      addAttachment(redundantFileId);
      resolve(true);
    });
  });
}

function checkFileRedundancy(fileName, onKeepUploading, onUseRedundantFile) {
  showSpinner();
  let urlParams = { fileName: fileName };
  apicall('documentsapi', 'fetchDocumentsByName', urlParams).then(function(resp){
    if (resp && resp.items && resp.items.length) {
      let parentFilePath = resp.items[0].properties.parentPath;
      let redundantFileId = resp.items[0].key.name;
      let parentFolderName;
      try {
        parentFolderName = parentFilePath[parentFilePath.length-1][1] || 'Documents';
      } catch(e1) {
        console.error(e1);
        parentFolderName = 'Documents';
      }
      swal({
        title: i18n.t("js.file.already.exist.title"),
        html: i18n.t("js.file.already.exist.text", {fileName, parentFolderName}),
        showCancelButton: true,
        useRejections: true, //important for swal2 v7.1.2
        expectRejections: true,
        allowOutsideClick: false,
        confirmButtonText: i18n.t("yes"),
        cancelButtonText: i18n.t("no"),
        type: 'info',
        onOpen: function() {
          // NOTE: should only be reactivated if made to open folder in a new tab
          // NOTE: otherwise it's only disruptive to the workflow
          // document.getElementById('duplicateFileFolderLink').onclick = e => {
          //   try {
          //     let fileIdPath = parentFilePath.map(p => p[0]);
          //     let fileNamePath = parentFilePath.map(p => p[1]);
          //     // shifting to ignore the ROOT folder
          //     fileIdPath.shift();
          //     fileNamePath.shift();
          //     var pathUrl = (['documents'].concat(fileIdPath)).join('/');
          //     let docPageParams = {multiSelectPanel: [], path: fileNamePath};
          //     r.loadPage(pathUrl, docPageParams);
          //   } catch(e) {
          //     console.error(e);
          //     r.loadPage('documents');
          //   } finally {
          //     swal.close();
          //   }
          // };
        }
      }).then(() => {
        onUseRedundantFile(redundantFileId);
      }, () => {
        onKeepUploading();
      }).catch(() => onKeepUploading());
    } else {
      onKeepUploading();
    }
  }).catch(() => onKeepUploading()).then(hideSpinner);
}

function showNext(isCancelled = false, fileOptions = null) {
	if(addedFileList.length != 0) {
    if(!isCancelled && isFirstUpload && fileOptions && !isUsingDefaultInfo) {
      isFirstUpload = false;
      swal({
        title: i18n.t("js.utils.apply.all"),
        text: i18n.t("js.file.apply.to.all"),
        type: 'question',
        showCancelButton: true,
        confirmButtonText: i18n.t("yes"),
        cancelButtonText: i18n.t("no")
      }).then(() => {
        isSharedOptions = true;
        renderFileType(fileOptions);
      }).catch(() => renderFileType());
    } else {
      renderFileType(isSharedOptions ?fileOptions :null);
    }
  } else {
    popupShowing = false;
  }
}

function validKey(key){
	var isValid = !isNaN(key);
	(!isValid) ?cgToast(i18n.t("js.utils.reject.number.only"), 3000) :"";
	return isValid;
}

	/********* FOLDER SELECTION *********/

function initCrooglooDocs(done) {

  var to = false;
  $('.search-input').keyup(function () {
    if(to) { clearTimeout(to); }
    to = setTimeout(function () {
      var v = $('.search-input').val();
      $('#fileTree').jstree(true).search(v);
    }, 250);
  });

  fetchCrooglooFiles('ROOT', 'ROOT', PRELOADED_FOLDER_DEPTH.CROOGLOO).then(() => {
    console.debug('fetching croogloo files done');
    done();
  });
}

function fetchCrooglooFiles(parentFolder, jstreeParent, recursions) {
  return new Promise(async function(resolve) {
    recursions--;
    let resp = await fetchFilesInFolder(parentFolder);
    let folders = new Array();
    let completedFolders = 0;
    if(resp.items.length) {
      hasFileCollectionChanged = true;
    }
    resp.items.sort(compareFiles);
    for(var i = 0; i < resp.items.length;  i++){
      var obj = resp.items[i].properties;
      obj.id = resp.items[i].key.name || obj.id;
      try {
        obj.id = obj.id.replace(/%20/g, ' ');
        obj.parentFolder = obj.parentFolder.replace(/%20/g, ' ');
        obj.parents.value = obj.parents.value.replace(/%20/g, ' ');
      } catch(e) {
        console.debug(e);
      }
      if(obj.id && obj.fileName) {
        let nodeId = 'n' + uuid(); // making sure element random ID starts with letter
        var icons = (obj.subType !== "Folder")
          ?"jstree-file" :(obj.fileName.endsWith('.zip') ?"icon-zip" :"jstree-folder");
        let fileSize = obj.size || obj.fileSize || '0';
        if(isNaN(fileSize)) { fileSize = '0' }
        
        let node = {
          "id": nodeId,
          "parent": jstreeParent,
          "text": obj.fileName,
          "icon": icons,
          "li_attr":{
            "id": obj.id,
            "url":obj.fileURL || '',
            "type":obj.subType || 'file',
            "isWatermarked":obj.isWatermarked || '0',
            "size": fileSize,
            "subcategory": obj.subcategory,
            "isFilled": recursions === 0 ?"0" :"1",
            "treeIndex": i,
            "isPublishedToStudio": false,
            "cloudProviderName": ''
            // TODO: re-enable for Publish to Studio feature
            // "isPublishedToStudio": obj.isPublishedToStudio || false,
            // "cloudProviderName": obj.cloudProviderName || ''
          }
        };
        fileCollection.push(node);
        crooglooFolderMap[node.id] = node;
        fileMap[obj.id] = {
          id: obj.id,
          fileName: obj.fileName,
          url: obj.fileURL,
          isWatermarked: obj.isWatermarked || '0',
          size: parseInt(fileSize),
          isPublishedToStudio: false,
          cloudProvider: ''
          // TODO: re-enable for Publish to Studio feature
          // isPublishedToStudio: obj.isPublishedToStudio || false,
          // cloudProvider: obj.cloudProvider || ''
        }
        if(obj.subType === "Folder") {
          folders.push({
            folderId: obj.id,
            nodeId: nodeId
          });
        }
      }
    }
    if(recursions > 0 && folders.length > 0) {
      folders.forEach(folderIdMap => {
        fetchCrooglooFiles(folderIdMap.folderId, folderIdMap.nodeId, recursions).then(() => {
          completedFolders++;
          checkCGCompletion();
        });
      });
    } else {
      checkCGCompletion();
    }
    function checkCGCompletion() {
      if(completedFolders >= folders.length || recursions <= 0) {
        resolve();
      }
    }
  });
}

function compareFiles(f1, f2) {
  try {
    let a = f1.properties, b = f2.properties;
    if(a.subType !== b.subType
      && (typeof a.subType == 'string' || typeof b.subType == 'string')) {
      return (typeof a.subType == 'string' && a.subType.toLowerCase() == 'folder')
        ?-1 :1;
    }
    let aTimeCreated = !isNaN(a.lastModifBaseTime)
      ?parseInt(a.lastModifBaseTime)
      :(typeof a.timeCreated == 'string' ?new Date(a.timeCreated).getTime() :0);
    let bTimeCreated = !isNaN(b.lastModifBaseTime)
      ?parseInt(b.lastModifBaseTime)
      :(typeof b.timeCreated == 'string' ?new Date(b.timeCreated).getTime() :0);
    return bTimeCreated - aTimeCreated;
  } catch(e) {
    console.error(e);
    return 0;
  }
}

function fetchFilesInFolder(folderId) {
  return apicall('documentsapi', 'fetchItemsInFolder', { folderId: folderId });
}

function loadDocTree(done) {
  $('#fileTree').jstree({
    'core' : {
      'data' : fileCollection,
      'multiple': true
    },
    "search": {
      "case_insensitive": true,
      "show_only_matches" : true
    },
    "plugins": ["search", "checkbox"]
  })
  .on('changed.jstree', function (e, data) {

    console.log("jstree: ",$(':jstree'))

    urlsToAdd = [];
    atLeastOneWatermarkedFile = false;
    console.log("Selected nodes: ", data)
    let selectedNodes = data.selected.map(n => data.instance.get_node(n))
      .filter(n => n.parent !== '#').sort((n1, n2) => compareNodeIndex(n1, n2, data));
    for(let node of selectedNodes) {
      if(node.li_attr.type === 'file') {
        urlsToAdd.push(getFileFromNode(node));
        if(urlsToAdd[urlsToAdd.length-1].isWatermarked == true) { // do not use ===
          atLeastOneWatermarkedFile = true;
        }
      }
      else if(node.li_attr.type === 'dropbox-file') {
        let uploadURL = "/DropboxImport?token=" + croogloo_auth.usertoken
          + "&tenantId=" + croogloo_auth.tenantId + "&dropbox_accesstoken="
          + croogloo_auth.crooglooauth.dropboxToken + "&filepath="
          + node.li_attr.path;
        let file = {
          name: node.text,
          uploadURL: uploadURL,
          size: parseInt(node.li_attr.size),
          type: 'dropbox-file'
        }
        urlsToAdd.push(file);
      }
      else if(node.li_attr.type === 'box-file') {

        let uploadURL = "/BoxImport?token=" + croogloo_auth.usertoken
          + "&tenantId=" + croogloo_auth.tenantId + "&box_accesstoken="
          + croogloo_auth.crooglooauth.boxToken + "&box_fileid="
          + node.li_attr.path;

        let file = {
          name: node.text,
          uploadURL: uploadURL,
          size: parseInt(node.li_attr.size),
          type: 'box-file'
        }
        urlsToAdd.push(file);
      }
    }
  })
  .on('open_node.jstree', function(e, data) { // TODO clear redundant code
    let node = data.node;
    if(node.id == 'DROPBOX_ROOT' || node.li_attr.type &&
        node.li_attr.type == 'dropbox-folder') {
      let foldersToFill = [];
      node.children.forEach(childId => {
        let child = data.instance.get_node(childId);
        if(child.li_attr.type == 'dropbox-folder' &&
            child.li_attr.isFilled === '0') {
          foldersToFill.push(child);
        }
      });
      if(foldersToFill.length > 0) {
        showSpinner();
        hasFileCollectionChanged = false;
        let filledFolders = 0;
        foldersToFill.forEach(folderNode => {
          fetchAllDropboxFiles(folderNode.li_attr.path, folderNode.id, 1)
          .then(() => {
            dropboxFolderMap[folderNode.id].li_attr.isFilled = '1';
            if(++filledFolders === foldersToFill.length) {
              refreshDocTree();
              hideSpinner();
            }
          });
        });
      }
    }
    else if(node.id == 'ROOT' || node.li_attr.type &&
        node.li_attr.type == 'Folder') {
      let foldersToFill = [];
      node.children.forEach(childId => {
        let child = data.instance.get_node(childId);
        if(child.li_attr.type == 'Folder' &&
            child.li_attr.isFilled === '0') {
          foldersToFill.push(child);
        }
      });
      if(foldersToFill.length > 0) {
        showSpinner();
        hasFileCollectionChanged = false;
        let filledFolders = 0;
        foldersToFill.forEach(folderNode => {
          fetchCrooglooFiles(folderNode.li_attr.id, folderNode.id, 1)
          .then(() => {
            crooglooFolderMap[folderNode.id].li_attr.isFilled = '1';
            if(++filledFolders === foldersToFill.length) {
              refreshDocTree();
              hideSpinner();
            }
          });
        });
      }
    }
    else if(node.id == 'BOX_ROOT' || node.li_attr.type &&
        node.li_attr.type == 'box-folder') {
      let foldersToFill = [];
      node.children.forEach(childId => {
        let child = data.instance.get_node(childId);
        if(child.li_attr.type == 'box-folder' &&
            child.li_attr.isFilled === '0') {
          foldersToFill.push(child);
        }
      });
      if(foldersToFill.length > 0) {
        showSpinner();
        hasFileCollectionChanged = false;
        let filledFolders = 0;
        foldersToFill.forEach(folderNode => {
          fetchAllBoxFiles(folderNode.li_attr.path, folderNode.id, 1)
          .then(() => {
            boxFolderMap[folderNode.id].li_attr.isFilled = '1';
            if(++filledFolders === foldersToFill.length) {
              refreshDocTree();
              hideSpinner();
            }
          });
        });
      }
    }
  })
  .on('loaded.jstree', done);
}

function compareNodeIndex(n1, n2, data) {
  try {
    let p1, p2;
    let minParentLength = Math.min(n1.parents.length, n2.parents.length);
    for(let i = 2; i <= minParentLength; i++) {
      p1 = data.instance.get_node(n1.parents[n1.parents.length-i]);
      p2 = data.instance.get_node(n2.parents[n2.parents.length-i]);
    }
    if(p1.li_attr.treeIndex == p2.li_attr.treeIndex && n1.parents.length != n2.parents.length) {
      if(n1.parents.length < n2.parents.length) {
        return n1.li_attr.treeIndex - data.instance.get_node(n2.parents[n2.parents.length-n1.parents.length-1]).li_attr.treeIndex;
      } else {
        return data.instance.get_node(n1.parents[n1.parents.length-n2.parents.length-1]).li_attr.treeIndex - n2.li_attr.treeIndex;
      }
    }
    return (p1.li_attr.treeIndex - p2.li_attr.treeIndex) || (n1.li_attr.treeIndex - n2.li_attr.treeIndex);
  } catch(e) {
    console.error(e);
    return 0;
  }
}

function getFileFromNode(node) {
  return {
    id: node.li_attr.id,
    fileName: node.text,
    url: node.li_attr.url,
    isWatermarked :node.li_attr.isWatermarked,
    size: parseInt(node.li_attr.size),
    subcategory: node.li_attr.subcategory,
    isPublishedToStudio: node.li_attr.isPublishedToStudio,
    cloudProvider: node.li_attr.cloudProvider
  }
}

function toggleFileViewer(){
  console.log('toggle file viewer called');
 
  if(!$('#js-tree').is(':visible')) {
    $('#fileTree').jstree('deselect_all');
  }

  if (!$('#js-tree').is(':visible')) {   

    $('#fileTree').jstree('deselect_all')   
    let template = document.getElementById('wrapper');   
    $('#js-tree').show();
    
    swal({
      allowOutsideClick: true,
      showCloseButton: true,
      html: template.innerHTML,
      showCancelButton: false,
      showConfirmButton: false, 
      animation: false,     
      
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
        $('#js-tree').hide();
      }
    }); 

  }else{
    $('#js-tree').hide();
    $('.swal2-popup').removeClass('swal2-noanimation');
    $('.swal2-popup').addClass('swal2-hide');
    swal.close();    
  }
  
}

function changeParentFolder() {
  let folderIds = [], folderNames = [];
  if(!selectedFolders.length) {
    parentFolderId = 'ROOT';//selectedFolder.id;
    parentFolderName = 'Documents';//selectedFolder.name;
  } else {
    selectedFolders.forEach(folder => {
      folderIds.push(folder.id);
      folderNames.push(folder.name);
    });
    parentFolderId = folderIds.join(',');
    parentFolderName = folderNames.join(', ');
  }
}

// TODO parentFolderId is not explicitely linked to individual files, which could potentially
// induce misplacement of files. E.g. selected upload destination ROOT for file 1, while
// file 1 is being uploaded to storage, selected upload destination PHOTO_ROOT for file 2,
// file 1 is done uploading to storage and addEntity2 will now be called with the folder
// selected for file 2 -- especially at risk if slow connection speed. Problem might also
// be found on Upload.js. Should deprecated the upload page and fix the problem on both
// compose.js and Upload.js.

// TODO when multiple files uploaded at once, and multiple files' names already exist,
// the user will only be asked to rename the first one + if hitting cancel on the first one,
// all uploads cancel but might get auto added next time attachments are uploaded

//HERE
function showTemplateOptions(templateName) {
  let selectedInclusionOptions = [];
	swal.mixin({      
    animation: false,
    title: i18n.t("template.opts"),
    confirmButtonText: i18n.t("next")+' &rarr;',
    showCancelButton: true,
    cancelButtonText: i18n.t("button.cancel"),
    progressSteps: ['1', '2'],
    useRejections: false,
    expectRejections: true,
    showCloseButton: true,    
	}).queue([
		{
      html: i18n.t("js.compose.template.inclusion")+'<br>' + generateInclusionOptions(),
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      onOpen: function () {
        [].forEach.call(document.querySelectorAll('#templateOptionContainer input[should_preselect=true]'), optionDivInput => {
          optionDivInput.checked = true;
        });
      },
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
      },      
      showLoaderOnConfirm: true,
      preConfirm: function () {
        return new Promise((resolve, reject) => {
          // must be at least one selection
          selectedInclusionOptions = [];
          for(let optionDiv of document.querySelectorAll('#templateOptionContainer > div')) {
            if(optionDiv.querySelector('input').checked) {
              selectedInclusionOptions.push(optionDiv.getAttribute('section_id'));
            }
          }
          if(!selectedInclusionOptions.length) {
            reject(i18n.t("js.compose.template.reject.selection"));
            return;
          }

          // if all selected fields are empty... (if not at least one selected field that's not empty)
          if(!(selectedInclusionOptions.includes('recipients') && !(findAllAffectedDistGroups().length === 0 && getPrimaryExtraEmails().length === 0)
              || selectedInclusionOptions.includes('subject') && document.getElementById('subject').value.trim().length !== 0
              || selectedInclusionOptions.includes('message') && getContent('message').trim().length !== 0
              || selectedInclusionOptions.includes('smsmessage') && document.getElementById('smsmessage').value.trim().length !== 0)) {
            reject(i18n.t("js.compose.template.reject.empty"));
            return;
          }

          resolve();
        });
      }
    },
		{
      html: i18n.t("js.compose.template.auto-attach")
        + '&nbsp;<span style="font-size: 0.8rem; font-weight: bold; line-height: 1rem; vertical-align: middle">'
        + i18n.t("js.compose.template.optional")
        +'</span><br>'
        + generateAttachmentOptions(),
      showCancelButton: true,
      cancelButtonText: i18n.t("button.cancel"),
      customClass: 'limited-height-swal',
      onOpen: function () {
        console.log('step 2 on open');
        for(let optionDiv of document.querySelectorAll('div.template-attachment-option')) {
          let checkbox = optionDiv.querySelector('input[type=checkbox]');
          let nbInput = optionDiv.querySelector('input[type=number]');
          nbInput.value = 1; // does not persist from outerHTML
          let select = optionDiv.querySelector('select');
          select.onchange = nbInput.onchange = function() {
            this.classList.remove('invalid-template-config');
          };
          checkbox.onchange = nbInput.onfocus = select.onfocus = function(event) {
            let innerOptionDiv = this.closest('div.template-attachment-option');
            let innerCheckbox = innerOptionDiv.querySelector('input[type=checkbox]');
            if(event.target != innerCheckbox) {
              innerCheckbox.checked = true;
            } else if(!innerCheckbox.checked) {
              if(document.querySelectorAll('div.template-attachment-option.disabled-attachment-option').length) {
                innerOptionDiv.remove();
              } else {
                innerOptionDiv.classList.add('disabled-attachment-option');
              }
              return;
            }
            console.log('should enable option box')
            innerOptionDiv.classList.remove('disabled-attachment-option');
            const MAX_NB_OF_ROWS = 15;
            if(document.querySelectorAll('div.template-attachment-option.disabled-attachment-option').length == 0
                && document.querySelectorAll('div.template-attachment-option').length < MAX_NB_OF_ROWS) {
              let newOptionDiv = createAttachmentOptionDiv();
              innerOptionDiv.parentElement.appendChild(newOptionDiv);
              let newCheckbox = newOptionDiv.querySelector('input[type=checkbox]');
              let newNbInput = newOptionDiv.querySelector('input[type=number]');
              let newSelect = newOptionDiv.querySelector('select');
              newCheckbox.onchange = newNbInput.onfocus = newSelect.onfocus = innerCheckbox.onchange;
              newSelect.onchange = newNbInput.onchange = select.onchange;
            }
          };
        }
      },
      onClose: () =>{
        $('.swal2-popup').removeClass('swal2-noanimation');
        $('.swal2-popup').addClass('swal2-hide');
      },
      showLoaderOnConfirm: true,
      preConfirm: function () {
        return new Promise((resolve, reject) => {
          let attachmentConfig = [];
          let atLeastOneError = false;
          for(let attachmentOption of document.querySelectorAll('div.template-attachment-option:not(.disabled-attachment-option)')) {
            let nbInput = attachmentOption.querySelector('input[type=number]');
            let subcategorySelect = attachmentOption.querySelector('select');
            let attachmentConfigItem = {
              quantity: parseInt(nbInput.value),
              subcategory: subcategorySelect.value
            };
            if(isNaN(attachmentConfigItem.quantity)
              || attachmentConfigItem.quantity < parseInt(nbInput.getAttribute('min'))
              || attachmentConfigItem.quantity > parseInt(nbInput.getAttribute('max'))) {
              nbInput.classList.add('invalid-template-config');
              atLeastOneError = true;
            } else {
              // in case added on previous verification
              nbInput.classList.remove('invalid-template-config');
            }
            if (!attachmentConfigItem.subcategory
                || attachmentConfig.map(item => item.subcategory).includes(attachmentConfigItem.subcategory)) {
              subcategorySelect.classList.add('invalid-template-config');
              atLeastOneError = true;
            } else {
              // in case added on previous verification
              subcategorySelect.classList.remove('invalid-template-config');
            }
            attachmentConfig.push(attachmentConfigItem);
          }
          let template = {
            templateName: templateName,
            isSubjectIncluded: selectedInclusionOptions.includes('subject'),
            isEmailBodyIncluded: selectedInclusionOptions.includes('message'),
            isSMSIncluded: selectedInclusionOptions.includes('smsmessage'),
            isRecipientsIncluded: selectedInclusionOptions.includes('recipients'),
            attachmentConfigJSON: JSON.stringify(attachmentConfig)
          };

          if(template.isSubjectIncluded) {
            template.subject = document.getElementById('subject').value;
          }

          if(template.isEmailBodyIncluded) {
            template.emailBody = getContent('message');
          }

          if(template.isSMSIncluded) {
            template.smsMessage = document.getElementById('smsmessage').value;
          }

          if(template.isRecipientsIncluded) {
            template.distributionListId = fetchCompleteGroups('li.distr-group-list-item');
            template.departmentId = fetchCompleteGroups('li.dept-list-item');
            template.extraEmailsForSelect = getPrimaryExtraEmails().join(',');
            template.extraListMembersInputVal = getPartialDistroListInputs();
            // template.extraEmailsJSON = getExtraEmailsJSON(); // REVIEW is this prop needed ?
          }

          saveSystemActivity({
            action: 'save',
            params: findSystemActivityParams({ templateName: templateName }),
            message: 'User saved an email template.'
          });

          if (atLeastOneError) {
            reject(i18n.t("js.compose.remplate.rejection"));
            return; // just for good measure in case more code added later, past this if-else statement
          } else {
            apicall('distributionapi', 'saveDistributionEmailTemplate', {}, template)
              .then(function (resp) {
                // TODO preload attachments if config provided? COMEBACK
                let templatesSelect = document.getElementById('distributionTemplates');
                if (document.querySelector(`#distributionTemplates > option[value="${template.templateName}"]`) == null) {
                  let option = document.createElement("option");
                  option.className = 'groupOptions';
                  option.value = template.templateName;
                  option.textContent = template.templateName;
                  templatesSelect.appendChild(option);
                }
                for (let i = 0; i < templatesSelect.children.length; i++) {
                  let tempateOption = templatesSelect.children[i];
                  tempateOption.selected = template.templateName === tempateOption.value;
                }
                $(templatesSelect).trigger('chosen:updated');
                document.getElementById('saveTemplateBtn').style.display = 'none';
                document.getElementById('templateOptionsBtn').style.display = 'block';
                resolve();
              }).catch(() => {
                console.error('server error saving template');
                reject(i18n.t("js.utils.server.error"));
              });
          }
        });
      }
    },
  ]).then(result => {
    // TODO should consider setting a variable in the second step preconfirm to make sure we've gathered all required data in case
    // swal dismissed an unexpected way...
    console.debug('result', result);
    if(typeof result == 'object' && result.hasOwnProperty('dismiss')) {
      console.log('opration cancelled - will not save the template')
      return;
    }
  
    swal({      
      title: i18n.t("js.compose.template.saved.title"),
      type: 'success',
      allowOutsideClick: true,
    }).catch(swal.noop);
  }).catch(swal.noop);

  function generateInclusionOptions() {
    let optionContainer = document.createElement('div');
    optionContainer.id = 'templateOptionContainer';
    optionContainer.style.marginTop = '0.5rem';
    optionContainer.appendChild(createOptionDiv('subject', i18n.t("js.compose.subject"), true));
    optionContainer.appendChild(createOptionDiv('message', i18n.t("js.compose.email-body"), true));
    optionContainer.appendChild(createOptionDiv('smsmessage', i18n.t("js.compose.sms"), false));
    optionContainer.appendChild(createOptionDiv('recipients', i18n.t("js.compose.recipients"), false));
    return optionContainer.outerHTML;

    function createOptionDiv(sectionId, sectionTitle, isSelected) {
      let div = document.createElement('div');
      div.setAttribute('section_id', sectionId);
      div.style.display = 'inline-block';

      let input = document.createElement('input');
      input.type = 'checkbox';
      input.id = Utility.uniqueID();
      input.style.verticalAlign = 'middle';
      input.setAttribute('should_preselect', isSelected.toString());

      let label = document.createElement('label');
      label.textContent = sectionTitle;
      label.style.textTransform = 'capitalize';
      label.style.verticalAlign = 'middle';
      label.setAttribute('for', input.id);

      div.appendChild(input);
      div.appendChild(label);

      return div;
    }
  }

  function generateAttachmentOptions() {
    let optionContainer = document.createElement('div');
    optionContainer.id = 'templateAttachmentOptionContainer';
    optionContainer.style.marginTop = '0.5rem';
    optionContainer.appendChild(createAttachmentOptionDiv());
    return optionContainer.outerHTML;
  }

  function createAttachmentOptionDiv() {
    let div = document.createElement('div');
    div.className = 'template-attachment-option disabled-attachment-option';
    div.id = Utility.uniqueID();

    let checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.style.marginRight = '1rem';

    let firstPartSpan = document.createElement('span');
    firstPartSpan.innerHTML = i18n.t("js.compose.attach-the")+'&nbsp;&nbsp;';

    let input = document.createElement('input');
    input.type = 'number';
    input.setAttribute('min', 1);
    input.setAttribute('max', 30);
    input.id = Utility.uniqueID();
    input.style.verticalAlign = 'middle';
    input.value = 1;

    let secondPartSpan = document.createElement('span');
    secondPartSpan.innerHTML = '&nbsp;&nbsp;'+i18n.t("js.compose.latest")+'&nbsp;&nbsp;';

    let select = document.createElement('select');
    for(let category of DocUtility.getFileCategories()) {
      let option = document.createElement('option');
      option.value = category.key;
      option.textContent = category.value;
      select.appendChild(option);
    }

    div.appendChild(checkbox);
    div.appendChild(firstPartSpan);
    div.appendChild(input);
    div.appendChild(secondPartSpan);
    div.appendChild(select);

    return div;
  }
}